import service from "../services/iot-devices-service";
const state = {
  isLoadingIotDevices: false,
  listIotDevices: [],
  iotDevice: {},
  meta: {},
  editedDevice: {},
  isVisibleEditDialog: false,
  pluginsList: [],
  streamsList: [],
};

const mutations = {
  SET_LOADING_LIST(state, value) {
    state.isLoadingIotDevices = value;
  },
  SET_IOTDEVICES(state, listIotDevices) {
    state.listIotDevices = listIotDevices;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  ADD_IOTDEVICE(state, iotDevice) {
    state.listIotDevices.push(iotDevice);
  },
  IS_VISIBLE_EDIT_DIALOG(state, value) {
    state.isVisibleEditDialog = value;
  },
  EDITED_IOTDEVICE(state, iotDevice) {
    state.editedIotDevices = iotDevice;
  },
  UPDATE_IOTDEVICE(state, iotDevice) {
    const item = state.listIotDevices.find((item) => item.id === iotDevice.id);
    Object.assign(item, iotDevice);
  },
  DELETE_IOTDEVICE(state, iotDevice) {
    state.listIotDevices.splice(
      state.listIotDevices.map((o) => o.id).indexOf(iotDevice.id),
      1
    );
  },

  SET_PLUGINS(state, pluginsList) {
    state.pluginsList = pluginsList;
  },
  SET_STREAMS(state, streamsList) {
    state.streamsList = streamsList;
  },
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
  list: (store, params) => {
    store.state.isLoadingIotDevices = true;
    service.list(params).then((data) => {
      store.commit("SET_IOTDEVICES", data.body.data);
      store.commit("SET_META", data.body);
      store.state.isLoadingIotDevices = false;
    });
  },

  add: (store, { iotDevice, filter }) => {
    return service.add(iotDevice).then((data) => {
      store.commit("ADD_IOTDEVICE", data.body);
      store.dispatch("list", filter);
    });
  },

  update: (store, { iotDevice, filter }) => {
    return service.update(iotDevice).then((iotDevice) => {
      store.commit("UPDATE_IOTDEVICE", iotDevice.body);
      store.dispatch("list", filter);
    });
  },

  destroy: (store, iotDevice) => {
    return service.destroy(iotDevice).then(() => {
      store.commit("DELETE_IOTDEVICE", iotDevice);
    });
  },

  getFlespiPlugins: (store) => {
    return service.getFlespiPlugins().then((response) => {
      store.commit("SET_PLUGINS", response.body);
    });
  },

  getFlespiStreams: (store) => {
    return service.getFlespiStreams().then((response) => {
      store.commit("SET_STREAMS", response.body);
    });
  },
};

const getters = {
  getIotDevicesList(state) {
    return state.listIotDevices;
  },
  getIotDevicesMeta(state) {
    return state.meta;
  },
  getIsLoadingIotDevices(state) {
    return state.isLoadingIotDevices;
  },
  getFlespiPluginsLists(state) {
    return state.pluginsList;
  },
  getFlespiStreamsList(state) {
    return state.streamsList;
  },
};

const iotDevices = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default iotDevices;
