<template>
  <div class="col-lg-12 padCustom">
    <div class="sectionFourBoxWrap">
      <div class="insideItemBoxWrap">
        <div
          v-if="loading"
          class="d-flex"
          style="flex: 1"
        >
          <v-skeleton-loader
            type="card"
            class="ma-2"
            width="100%"
            height="150px"
          />
          <v-skeleton-loader
            type="card"
            class="ma-2"
            width="100%"
            height="150px"
          />
        </div>
        <draggable
          v-else
          v-model="dashboardSort.section_4"
          class="section-three row"
          group="table"
          :class="isDisable ? 'section-three-in-active' : ''"
          :disabled="isDisable"
          @change="updateOrder"
        >
          <div
            v-for="(row, i) in dashboardSort?.section_4"
            :key="i"
            class="col-lg-4"
            :loading="loading"
          >
            <div
              v-if="row.card_name != 'Map'"
              class="largeItemBoxWrap"
            >
              <div class="largeItemBoxHeading">
                {{ row.card_name }}
              </div>
              <div class="dashboardListingWrap">
                <ul
                  v-if="
                    dashboadData[row.keys[0]] &&
                      dashboadData[row.keys[0]].length
                  "
                >
                  <li
                    v-for="(list, index) in dashboadData[row.keys[0]]"
                    :key="index"
                  >
                    <div class="prodImg">
                      <!-- <img src="../../../../assets/prod-img.png"> -->
                      <img :src="list.vehicle_model_image">
                    </div>
                    <div class="listingContentBox">
                      <div class="insidelistingContentBox">
                        <span class="labelField">Driver Name</span>
                        <p>{{ list.customer_name }}</p>
                      </div>
                      <div
                        v-if="row.keys[0] == 'mostIdleVehicles'"
                        class="insidelistingContentBox"
                      >
                        <span class="labelField">Idle Time </span>
                        <p>{{ list.total_idle_time_in_seconds }}</p>
                      </div>
                      <div
                        v-else
                        class="insidelistingContentBox"
                      >
                        <span
                          class="labelField"
                        >Speed<span>(KM/Hr)</span></span>
                        <p>{{ list.total_max_speed }}</p>
                      </div>
                      <div class="insidelistingContentBox">
                        <span class="labelField">Reg No</span>
                        <p class="hightLightCol">
                          {{ list.chassis_no }}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul
                  v-else
                  class="noDataMainWrap"
                >
                  <span class="noDataWrap">No Data Available</span>
                </ul>
              </div>
            </div>
            <div
              v-if="
                row.card_name == 'Map' &&
                  showMap &&
                  (options.tenant_id || options.fleetId) &&
                  !isVehicleLoad
              "
              :key="i"
              :loading="loading"
              class="largeItemBoxWrap"
            >
              <div class="largeItemBoxHeading">
                {{ row.card_name }}
              </div>
              <div
                v-if="row.card_name == 'Map'"
                class="dashboardListingWrap"
              >
                <VehicleLiveTracking
                  v-if="(options.tenant_id || options.fleetId) && showMap"
                  :channel-name="dashboadData.tenant_channel_name"
                  :product="options.product"
                  :vehicles="vehiclesForMap"
                />
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  components: {
    draggable,
    // DashboardMap: () => import("../utils/DashboardMap.vue"),
    VehicleLiveTracking: () =>
      import("@/components/sections/VueLeafLetMap/VehicleLiveTracking.vue"),
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    vehicleTech: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      showMap: true,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "dashboard/getIsLoading",
      dashboadData: "dashboard/getDashboadSectionFour",
      dashboardSort: "dashboard/getDashboadSortData",
      selectedCards: "dashboard/getSelectedCards",
      vehicleList: "vehicles/getVehiclesList",
      isVehicleLoad: "vehicles/getIsLoadingVehicles",
    }),
    sectionFour() {
      return this.dashboardSort?.section_4;
    },
    vehiclesForMap() {
      return this.vehicleList.filter((r) => r.lt && r.ln);
    },
    isDisable() {
      return this.selectedCards.length == 0 ? true : false;
    },
    checkTenant() {
      return this.options.is_independent_fleet
        ? this.options.fleetId
        : this.options.tenant_id;
    },
  },
  watch: {
    vehicleList: {
      handler: function () {
        this.showMap = false;
        setTimeout(() => {
          this.showMap = true;
        }, 100);
      },
    },
  },
  methods: {
    updateOrder() {
      const params = {
        section: "section_4",
        sort: true,
        item: this.dashboardSort.section_4,
        vehicle_tech: this.vehicleTech,
        tenant_id: this.options.tenant_id,
        // from: val.moved.oldIndex,
        // to: val.moved.newIndex,
      };

      this.$store.dispatch("dashboard/updateCard", params);
    },
  },
};
</script>
<style lang="sass" scoped>

.section-two-in-active
  .v-card
    cursor: auto !important

.section-two
  user-select: none
  .v-card
    cursor: all-scroll
    transition: 0.7s all ease
    background: #d5f1d5 !important
    &:hover
      box-shadow: 2px 1px 8px #20a390 !important
      transform : scale3d(1.2, 1.3, 1.9)

    label
      font-size: 12px
    hr
      background: green
    span
      font-size: 16px
      font-weight: 600

.noDataMainWrap
  height: 100%
</style>
