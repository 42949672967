<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="filtersName"
      @filterOption="onFiltersChange"
    >
      <template v-slot:master_switch="{ item }">
        <div class="d-flex justify-center align-center">
          <v-switch
            v-model="item.master_switch"
            :label="item.master_switch ? 'On' : 'Off'"
            :ripple="false"
            hide-details
            style="margin: 0"
            @change="onMasterChange(item)"
          />
        </div>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <off-master-key
            :is-visible-delete-dialog="masterSwitchModal"
            :toggle-modal="toggleModal"
            :item="selectedCustomer"
            @close="closeModal"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    OffMasterKey: () => import("./utils/OffMasterSwitch.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      masterSwitchModal: false,
      selectedCustomer: {},
      //
      filters: {},
      subFilters: {},
      vehicleType: [
        { id: "is_assigned", name: "Assigned" },
        { id: "is_unassigned", name: "Un-Assigned" },
      ],
      assignmentType: [
        { id: "individual", name: "Individual" },
        { id: "business", name: "Business" },
        { id: "internal", name: "Internal" },
      ],
      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },

        {
          text: this.$t("oem_name"),
          align: "left",
          sortable: false,
          value: "tenant_name",
          width: "10%",
          showAdmin: true,
        },
        {
          text: this.$t("ev_make"),
          align: "left",
          sortable: false,
          value: "md_make_name",
          width: "15%",
        },
        {
          text: this.$t("ev_model"),
          align: "left",
          sortable: false,
          value: "md_model_name",
          width: "10%",
        },

        {
          text: this.$t("chassis_no"),
          align: "left",
          sortable: false,
          value: "chassis_no",
          width: "10%",
        },

        {
          text: this.$t("imei"),
          align: "left",
          sortable: false,
          value: "iot_device_imei",
          width: "10%",
        },
        {
          text: this.$t("allotted"),
          align: "left",
          sortable: false,
          value: "assigned",
          width: "10%",
        },
        {
          text: this.$t("business_name"),
          align: "left",
          sortable: false,
          value: "oem_b2b_business_name",
          width: "10%",
        },
        {
          text: this.$t("master_switch"),
          align: "left",
          sortable: false,
          value: "master_switch",
          width: "10%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "vehicles/getIsLoadingVehicles",
      meta: "vehicles/getVehiclesMeta",
      list: "vehicles/getVehiclesList",

      oemB2bList: "oemBusiness/getOEMB2BList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
    filtersName() {
      return ["is-assigned_only", "customer"];
    },
  },
  watch: {
    "subFilters.assignment_type": {
      handler: function (v) {
        if (v == "business") {
          this.$store.dispatch("oemBusiness/list", {
            tenant_id: this.options.tenant_id,
          });
        }
      },
    },
    options() {
      this.fetchData();
    },
  },
  mounted() {
    this.$store.dispatch('vehicles/setLoadingValue', true)
  },
  beforeDestroy() {
    const params = {
      name: "remote_on_off",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("vehicles/list", this.options).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    closeModal(item) {
      item.master_switch = !item.master_switch;
      this.masterSwitchModal = false;
    },
    toggleModal() {
      return (this.masterSwitchModal = !this.masterSwitchModal);
    },
    onMasterChange(item) {
      if (item.master_switch) {
        this.updateItem(item);
      } else {
        this.selectedCustomer = item;
        this.masterSwitchModal = true;
      }
    },
    async updateItem(editVehicle) {
      editVehicle.master_switch = editVehicle.master_switch ? 1 : 0;
      editVehicle.battery_serial_numbers = editVehicle.battery.map(
        (battery) => battery.serial_no
      );
      await this.$store
        .dispatch("vehicles/update", editVehicle)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.isLoadingSave = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
  },
};
</script>