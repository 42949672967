<template>
  <div>
    <section class="dashboardFilterHeader">
      <header-filters
        is-date-show
        :product-options="productOptions"
        :date-validation="false"
        @onChange="onFiltersChange"
      >
        <div class="div-class">
          <v-card class="allVehicleSelect">
            <v-select
              v-model="location"
              filled
              dense
              clearable
              :items="locationsList"
              item-value="id"
              item-text="name"
              :label="$t('location')"
              @change="fetchData"
            />
          </v-card>
        </div>
      </header-filters>
    </section>

    <v-container
      fluid
      class="chargingDashboardContent"
    >
      <v-row
        v-if="isLoading"
        dense
      >
        <v-col
          v-for="n in 9"
          :key="n"
          cols="12"
          md="4"
        >
          <v-skeleton-loader
            type="card"
            class="pa-4"
            elevation="2"
          >
            <template #default>
              <v-skeleton-loader
                type="heading"
                class="mb-4"
              />
              <v-skeleton-loader
                type="text"
                class="mb-2"
              />
              <v-skeleton-loader type="text" />
              <v-skeleton-loader
                type="button"
                class="mt-4"
              />
            </template>
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row
        v-else
        dense
      >
        <!-- Card 8 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <div class="iconBox">
                <Icons
                  name="charging"
                  class="cardIcon"
                />
              </div>
              <span class="cardTitle">Charge Points</span>
              <!-- <div class="MapLinkCard">
                View Map
              </div> -->
            </div>
            <v-card-text class="cardListWrap fullWidth">
              <ul v-if="list.charge_points_count_by_type && list.charge_points_count_by_type.length > 0">
                <li
                  v-for="(item, index) in list.charge_points_count_by_type"
                  :key="index"
                >
                  {{ item.charge_station_type }} ({{
                    item.charge_point_count }})
                </li>
              </ul>
              <ul
                v-else
              >
                <li>N/A</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Card 2 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <div class="iconBox">
                <Icons
                  name="lightning-icon"
                  class="cardIcon"
                />
              </div>
              <span class="cardTitle">Active Charging Sessions</span>
            </div>
            <v-card-text>
              <div class="iconWithData">
                <p class="card-value hightlightCol">
                  {{ list.active_charge_sessions }}
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Card 1 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <div class="iconBox">
                <Icons
                  name="fault-and-connectivity"
                  class="cardIcon"
                />
              </div>
              <span class="cardTitle">Faults & Connectivity Issues</span>
              <div class="linkIconWrap">
                <div>
                  <!-- <v-icon class="mr-2" small>
                    mdi-eye
                  </v-icon> -->
                </div>
              </div>
            </div>
            <v-card-text>
              <div class="iconWithData">
                <p class="card-value">
                  {{ list.fault_connectivity_issues }}
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>



        <!-- Card 3 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <div class="iconBox">
                <Icons
                  name="average-time-to-charge"
                  class="cardIcon"
                />
              </div>
              <span class="cardTitle">Total Charging Sessions</span>
              <div class="dateFilterCard">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  offset-x
                  left
                  :nudge-right="126"
                  max-width="290px"
                  min-width="auto"
                />
              </div>
            </div>
            <v-card-text>
              <div class="iconWithData">
                <p class="card-value">
                  {{ list.total_charge_sessions }}
                </p>
              </div>
              <!-- <div class="card-details">
                <Icons name="chart-icon" class="cardIcon increase" />
                <span class="percentText">5% Increase</span>
              </div> -->
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Card 4 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <div class="iconBox">
                <Icons
                  name="total-charge"
                  class="cardIcon"
                />
              </div>
              <span class="cardTitle">Total Energy</span>
              <div class="dateFilterCard">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  offset-x
                  left
                  :nudge-right="126"
                  max-width="290px"
                  min-width="auto"
                />
              </div>
            </div>
            <v-card-text>
              <div class="iconWithData">
                <p class="card-value">
                  {{ list.total_energy_consumed }} <span class="smallValueText"> {{ ((list.total_energy_consumed != "N/A")
                    ?
                      'kWh' :
                      "")
                  }}</span>
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Card 5 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <div class="iconBox">
                <Icons
                  name="total-revenue"
                  class="cardIcon"
                />
              </div>
              <span class="cardTitle">{{ $t("revenue") | currencyFormat(authUser) }}</span>
            </div>
            <v-card-text>
              <div class="iconWithData">
                <p class="card-value hightlightCol">
                  {{ list.total_revenue }}
                </p>
              </div>
              
              <!-- <div class="card-details">
                <Icons name="chart-icon" class="cardIcon decrease" />
                <span class="percentText">5% Decrease</span>
              </div> -->
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Card 6 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <div class="iconBox">
                <Icons
                  name="new-users"
                  class="cardIcon"
                />
              </div>
              <span class="cardTitle">New Users</span>
              <div class="dateFilterCard">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  offset-x
                  left
                  :nudge-right="126"
                  max-width="290px"
                  min-width="auto"
                />
              </div>
            </div>
            <v-card-text>
              <div class="iconWithData">
                <p class="card-value">
                  {{ list.new_users }}
                </p>
              </div>
              
              <!-- <div class="card-details">
                <Icons name="chart-icon" class="cardIcon increase" />
                <span class="percentText">5% Increase</span>
              </div> -->
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Card 7 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <div class="iconBox">
                <Icons
                  name="charge-point"
                  class="cardIcon"
                />
              </div>
              <span class="cardTitle">New Charge Point</span>
            </div>
            <v-card-text>
              <div class="iconWithData">
                <p class="card-value">
                  {{ list.new_charge_points }}
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>



        <!-- Card 9 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <div class="iconBox">
                <Icons
                  name="locations"
                  class="cardIcon"
                />
              </div>
              <span class="cardTitle">Locations By City</span>
              <!-- <div class="MapLinkCard">
                View Map
              </div> -->
            </div>

            <v-card-text class="cardListWrap">
              <ul v-if="list.charge_connector_count_by_city && list.charge_connector_count_by_city.length > 0">
                <li
                  v-for="(item, index) in list.charge_connector_count_by_city"
                  :key="index"
                >
                  {{ item.city_name }} ({{
                    item.connector_count }})
                </li>
              </ul>
              <ul
                v-else
              >
                <li> N/A </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";

export default {
  components: {
    HeaderFilters: () => import("@/layouts/section/MainFilters.vue"),
    Icons: () => import("@/components/base/icons.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      isMounted: false,
      location: "",
      productOptions: [
        { id: "oem", value: "OEM" },
        { id: "cso", value: "CSO" },
      ],
      menu: false,
      date: new Date().toISOString().substr(0, 10), // Initialize with today's date
      menu1: false, // Add this
      menu2: false, // Add this
      value: "",
    };


  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsDashboard/getLoading",
      list: "csmsDashboard/getList",
      locationsList: "csmsChargeStations/getLocation",
    }),
  },
  watch: {
    "options.tenant_id": {
      handler: function (val) {
        if (this.isMounted) {
          // this.customFilters = {};
          this.location = "";
          this.connector_state = "";
        }
        this.isMounted = true;
        if (val) {
          const params = { tenant_id: val };
          this.$store.dispatch("csmsChargeStations/LocationsList", params);
        } else {
          this.$store.commit("csmsChargeStations/SET_LOCATION", []);
        }
      },
    },
    options() {
      this.fetchData();
    },
  },
  async mounted() {

    if (!this.authUser.isAdmin) {
      const params = { tenant_id: this.authUser.tenant_id };
      await this.$store.dispatch("csmsChargeStations/LocationsList", params);
    }
    this.$store.dispatch("csmsDashboard/setLoadingValue", true);

    if (this.$route?.query?.location_id) {
      this.location = parseInt(this.$route.query.location_id);
    }
  },
  beforeDestroy() {
    const paramsset = { ...this.options };
    paramsset.location_id = this.location;
    const params = {
      name: "charging_dashboard",
      filters: paramsset,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      const params = { ...this.options };
      params.location_id = this.location;
      await this.$store
        .dispatch("csmsDashboard/list", params)
        .then(() => {
          if (this.options.page > (this.meta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
    },
    // onFiltersChange(v) {
    //   if (this.options && this.options.tenant_id) {
    //     const params = { tenant_id: this.options.tenant_id };
    //     this.$store.dispatch("csmsChargeStations/LocationsList", params);
    //   } else {
    //     this.$store.commit("csmsChargeStations/SET_LOCATION", []);
    //   }
    //   this.options = v;
    //   this.fetchData();
    // },

    // fetchData: debounce(async function () {

    //   const params = { ...this.options };
    //   params.location_id = this.location
    //   await this.$store.dispatch("csmsDashboard/list", params);
    // }, 100),


    // goToDetail() {
    //   this.$router.push('/operations-troubleshooting/detail');
    // },
  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

$primaryColor: #23BDAA

.chargingDashboardContent
  padding-left:0
  padding-right:0

.chargingDashboardContent .row--dense > [class*=col-]
  padding: 12px !important

.dashboard-card
  position: relative
  min-height: 170px
  margin: 0
  border-radius: 7px

  &::before
    content:''
    box-sizing: border-box
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 9
    z-index: 1
    background: #FFFFFF
    border: 1px solid #F3F3F3
    box-shadow: 0px 9px 4px rgba(0, 0, 0, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 17.9868px 35.9736px rgba(190, 185, 185, 0.01)
    border-radius: 7px

  &::after
    content:''
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 3px
    background: #F9F9F9
    box-shadow: 0px 9px 4px rgba(0, 0, 0, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 17.9868px 35.9736px rgba(190, 185, 185, 0.01)
    border-radius: 7px

  .cardTopBar
    width: 100%
    border-bottom: 1px solid #f3f3f3
    padding: 1.3rem 1.6rem 0.7rem
    margin-bottom: 1rem
    z-index: 2
    position: relative
    display: flex
    align-items: center
    flex-wrap: wrap
    min-height: 62px

  .cardTitle
    font-size: 1.45rem
    color: #000
    font-family: $poppinSemi
    display: flex

  .card-value
    font-size: 1.6rem
    font-family: $poppinSemi
    z-index: 2
    margin-bottom: 0 !important
    position: relative
    color: $primaryColor

    &.hightlightCol
      color: #23BDAA

    .smallValueText
      font-size: 0.875rem
      font-family: &poppinReg

  .v-card__text
    display: flex
    flex-wrap: wrap
    padding: 2rem 1.6rem 2rem
    align-items: center

  .card-details
    z-index: 2
    position: relative
    display: flex
    flex-direction: column
    margin-left: auto

    .cardIcon
      &.increase
        :deep span
          svg
            width: 1.45rem
            height: 1.45rem
            path
              fill: #10b981

      &.decrease
        :deep span
          svg
            width: 1.45rem
            height: 1.45rem
            path
              fill: #ef4444

    span.percentText
      display: flex
      margin-top: 0.3rem
      font-size: 0.75rem
      font-family: $poppinMed

  .dateFilterCard
    display: flex
    margin-left: auto
    width:35%
    .v-input
      padding-top:0 !important
      margin-bottom: 0 !important
      margin-top:0 !important
      :deep .v-input__control
        .v-input__slot
          background: #F6F6F6
          border-radius: 6px
          padding: 0 0.8rem
          &:before
            display: none
          &:after
            display: none
          .v-text-field__slot
            input
              font-size: 0.875rem
              color: #22272E
          .v-input__append-inner
            .v-input__icon
              justify-content: flex-end
              i
                color: $primaryColor
                font-size: 1rem
  .MapLinkCard
    display: flex
    margin-left: auto
    color: #C1C7C6
    text-decoration: none
    font-family: $poppinReg
    font-size: 0.875rem
  .cardListWrap
    display: flex
    position: relative
    z-index: 2
    ul
      display: flex
      flex-wrap: wrap
      padding: 0
      margin-bottom: 1rem
      width: 100%
      overflow: hidden
      max-height: 25px
      overflow-y: auto
      li
        font-size: 1.15rem
        color: $primaryColor
        display: flex
        flex-wrap: wrap
        width: 47%
        margin-right: 3%
        margin-bottom: 0.4rem
        font-family: $poppinSemi
    &.fullWidth
      ul
        li
          width: 100%
.dashboardFilterHeader
  .headerBarWrap
    align-items: center
    padding: 6px 12px 0

  :deep .filterBtn
    display: none !important


.drawerOpen .dashboard-card .cardTitle
  font-size: 1.3rem

.drawerOpen .chargingDashboardContent .row--dense > [class*=col-]
    padding: 12px 6px !important

.drawerOpen .dashboard-card .cardTopBar
  padding: 1rem 1rem 0.7rem

.drawerOpen .dashboard-card .dateFilterCard
  width: 40%

.linkIconWrap
  margin-left: auto
  display: flex
  color: #000
  cursor: pointer
  i
    color: #000

.linkTextWrap
    display: flex
    color: #000
    cursor: pointer
    text-decoration: underline
    &:hover
      text-decoration: none

.iconWithData
  display: flex
  flex-direction: column
.iconBox
  background: #F4F4F4
  width: 45px
  height: 34px
  z-index: 1
  border-radius: 6px
  display: flex
  align-items: center
  justify-content: center
  margin: 0 0.75rem 0 0
  :deep .lg span svg
    fill: $primaryColor !important
    width: 28px
    height: 28px
  :deep .charging span svg
    fill: $primaryColor !important
    width: 45px
    height: 45px
  :deep .total-revenue span svg
    fill: $primaryColor !important
    width: 24px
    height: 24px
  :deep .new-users span svg
    fill: $primaryColor !important
    width: 24px
    height: 24px
  :deep .charge-point span svg
    fill: $primaryColor !important
    width: 25px
    height: 25px
    stroke: $primaryColor
  :deep .lightning-icon span svg
    fill: $primaryColor !important
    width: 54px
    height: 54px
    margin-left: -6px
  :deep .fault-and-connectivity span svg path
    fill: $primaryColor !important
    width: 54px
    height: 54px
    stroke: $primaryColor
  :deep .locations span svg
    fill: $primaryColor !important
    width: 23px
    height: 23px
    stroke: $primaryColor
  :deep .average-time-to-charge span svg
    fill: $primaryColor !important
    width: 52px
    height: 52px
    stroke: $primaryColor
    

@media (min-width: 1080px) and (max-width: 1366px)
  .dashboard-card .cardTitle
    font-size: 1.15rem
  .dashboard-card .cardListWrap ul li
    font-size: 1rem
  .dashboard-card .card-value
    font-size: 1.4rem
  .drawerOpen .dashboard-card .cardTitle
    font-size: 1.15rem
  .drawerClose .dashboard-card .cardListWrap ul li
    font-size: 0.9375rem
  .drawerClose .dashboard-card .card-value
    font-size: 1.15rem
</style>