<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="600px"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title class="pb-4">
          <span class="text-h3">{{ $t("Update Customer & Vehicle Information") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- Customer Details -->
            <span class="text-h3 custom-h3">{{ $t("Customer Detail") }}:</span>
            <v-row dense>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="customer_details.name"
                  dense
                  :label="$t('customer_name') + ' *'"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="customer_details.email"
                  dense
                  :label="$t('email_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="customer_details.country_code"
                  dense
                  :items="countryCodeList"
                  item-value="id"
                  item-text="name"
                  :label="$t('country_code_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="customer_details.phone_number"
                  dense
                  :label="$t('phone_number_*')"
                  :rules="[
                    rules.required,
                    rules.min_length(10),
                    rules.max_length(10),
                  ]"
                />
              </v-col>
            </v-row>

            <!-- Vehicle Details -->
            <span class="text-h3 custom-h3 bodTop">{{ $t("Vehicle Detail") }}</span>
            <v-row dense>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="vehicle_details.md_make_id"
                  dense
                  :items="mdMakesList"
                  :loading="isLoadingMdMakes"
                  item-value="id"
                  item-text="name"
                  :label="$t('ev_make_*')"
                  :rules="[rules.required]"
                  @change="onMakeChange"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="vehicle_details.md_model_id"
                  dense
                  :items="vehicle_details.md_make_id ? mdModelsList : []"
                  :loading="isLoadingMdModels"
                  item-value="id"
                  item-text="name"
                  :label="$t('ev_model_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="vehicle_details.year"
                  dense
                  type="number"
                  :min="getStartingYear()"
                  :max="getCurrentYear()"
                  :label="$t('year_*')"
                  :rules="[rules.required, rules.min_year(), rules.max_year()]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="vehicle_details.registration_no"
                  dense
                  :label="$t('registration_no') + '*'"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <small>{{ $t("indicates_required_field") }}</small>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="toggleEdit()"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="isLoadingSave"
            @click="updateItem(customerData)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  props: {
    isVisibleDialog: Boolean,
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    customerData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isLoadingSave: false,
      customer_details: {},
      vehicle_details: {}
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      mdMakesList: "mdMakes/activelistMdMakes",
      isLoadingMdMakes: "mdMakes/isLoadingMdMakes",
      mdModelsList: "mdModels/activelistMdModels",
      isLoadingMdModels: "mdModels/isLoadingMdModels",
      countryCodeList: "getCountryCode",
    }),
  },

  async mounted() {
    if (this.customerData && this.customerData.tenant_id) {
      await this.$store.dispatch("mdMakes/list", {
        tenant_id: this.customerData.tenant_id,
      });
    }
  },
  methods: {
    async updateItem(data) {
      let params = {}
      params.csms_charging_sessions_id = data.id
      params.customer_details = this.customer_details
      params.vehicle_details = this.vehicle_details
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;

        await this.$store
          .dispatch("csmsChargingSession/updateCustomerList", params)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
            this.isLoadingSave = false;
            this.toggleEdit();
            this.$emit('change')
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
    async onMakeChange(v) {
      delete this.vehicle_details.md_model_id;
      if (v) {
        await this.$store.dispatch("mdModels/list", {
          md_make_id: v ?? false,
        });
      }
    },
  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif
.v-application
  .custom-h3
    font-size: 1.25rem !important
    color: #333
    fomt-familu: $poppinMed
    margin-bottom: 0.75rem !important
    display: inline-block
  .bodTop
    margin-top: 1rem !important
    display: block
    padding-top: 1rem
</style>