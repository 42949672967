<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :product-options="productOptions"
      :filter-names="filterNames"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:charge_point_id="{ item }">
        <div class="d-flex align-center">
          <StatusBadge :status="item.connectivity_status" />
          {{ item.charge_point_id }}
        </div>
      </template>
      <template v-slot:manufacturer="{ item }">
        <div>
          {{ item.manufacturer + " - " + item.model }}
        </div>
      </template>
      <template v-slot:updated_at="{ item }">
        <div>
          {{ item.updated_at | formatDate }}
        </div>
      </template>
      <template v-slot:is_private="{ item }">
        <div>
          {{ item.is_private == 0 ? "Public" : "Private" }}
        </div>
      </template>

      <template v-slot:type="{ item }">
        <div>
          {{ item.type ? item.type : "N/A" }}
        </div>
      </template>
      <template v-slot:actions="{ item }">
        <v-icon
          class="mr-2"
          small
          @click="openOcppModal(item)"
        >
          mdi-information
        </v-icon>
        <v-icon
          v-if="isView"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          v-if="isEdit"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="isDelete"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">

        </v-card>
      </template> -->
      <!-- add Filters -->
      <template #moreFilters>
        <div>
          <refresh-button
            :is-loading="isLoading"
            @reload="fetchData"
          />
        </div>
      </template>
      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-charge-stations
            v-if="isCreate"
            :filter-options="options"
          />

          <edit-charge-stations
            v-if="(isEdit || isView) && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :item="selectedRow"
          />
          <confirmation-modal
            v-if="isDelete"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
    <Modal
      v-if="ocppIdModal"
      title=""
      :is-visible="ocppIdModal"
      is-view
      width="500px"
      :toggle="() => (ocppIdModal = false)"
    >
      <div>
        <h4>OCPP Configuration:</h4>
        <div v-if="!integrationLoading">
          <div class="row-box-wrap">
            <copy-content
              :content="copyText"
              class="copy-button"
            />
          </div>
          <div class="row-box-wrap">
            <span class="text-muted">Charge Station OCPP ID:</span>
            <span class="highlighted">{{
              integrationInfo.charge_point_id
            }}</span>
          </div>
          <div class="row-box-wrap">
            <span class="text-muted">OCPP Connection URL:</span>
            <span class="highlighted">{{
              integrationInfo.ocpp_connection_url
            }}</span>
          </div>
        </div>
        <div v-else>
          <v-skeleton-loader
            type="list-item-two-line"
            style="flex: 1"
          />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import moment from "moment";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    Modal: () => import("@/components/Modals/Modal.vue"),
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateChargeStations: () => import("./dialogs/CreateChargeStations.vue"),
    EditChargeStations: () => import("./dialogs/EditChargeStations.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
    CopyContent: () => import("@/components/base/CopyContent.vue"),
    StatusBadge: () => import("@/components/base/Status-Badge.vue"),
    refreshButton: () =>
      import("../../../../components/base/RefreshButton.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      ocppIdModal: false,
      productOptions: [
        { id: "oem", value: "OEM" },
        { id: "cso", value: "CSO" },
      ],
      permissions: {
        view: "charge-stations.view",
        create: "charge-stations.create",
        edit: "charge-stations.edit",
        delete: "charge-stations.delete",
      },
      filters: {},
      subFilters: {},
      interval: null,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsChargeStations/getLoading",
      meta: "csmsChargeStations/getMeta",
      list: "csmsChargeStations/getList",
      integrationInfo: "csmsChargeStations/getIntegrationInfo",
      integrationLoading: "csmsChargeStations/getIntegrationLoading",
    }),
    _headers() {
      const headers = [
        // {
        //   text: this.$t("id"),
        //   align: "left",
        //   sortable: true,
        //   value: "id",
        // },
        {
          text: this.$t("charge_point_id"),
          align: "left",
          sortable: false,
          value: "charge_point_id",
        },
        {
          text: this.$t("tenant_name"),
          align: "left",
          sortable: false,
          value: "tenant_name",
          showAdmin: true,
        },
        {
          text: this.$t("manufacturer"),
          align: "left",
          sortable: false,
          value: "manufacturer",
        },
        ...(this.authUser.user_nature == "fleet"
          ? [
            {
              text: this.$t("manufacturer"),
              align: "left",
              sortable: false,
              value: "tenant_name",
            },
          ]
          : []),
        {
          text: this.$t("location"),
          align: "left",
          sortable: false,
          value: "location_name",
        },
        // {
        //   text: this.$t("station_type"),
        //   align: "left",
        //   sortable: false,
        //   value: "station_type",
        // },
        {
          text: this.$t("connectors"),
          align: "center",
          sortable: false,
          value: "charger_connectors_count",
        },
        {
          text: this.$t("power_type"),
          align: "center",
          sortable: false,
          value: "type",
        },
        {
          text: this.$t("access_level"),
          align: "left",
          sortable: false,
          value: "is_private",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "charge_station_status",
        },
        {
          text: this.$t("last_heartbeat_received"),
          align: "left",
          sortable: false,
          value: "last_heartbeat_received",
        },
        ...(this.isView || this.isEdit || this.isDelete
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ];

      // computed headers for showing columns to role based
      return headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      return ["search", "status"];
    },
    copyText() {
      return (
        "Charge Station OCPP ID: " +
        this.integrationInfo.charge_point_id +
        "  OCPP Connection URL:  " +
        this.integrationInfo.ocpp_connection_url
      );
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  mounted() {
    this.$store.dispatch("csmsChargeStations/setLoadingValue", true);
  },
  beforeDestroy() {
    clearTimeout(this.interval);
    const params = {
      name: "charge_stations",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    isNotEmpty(obj) {
      return obj && Object.keys(obj).length > 0;
    },
    async fetchData() {
      clearTimeout(this.interval);
      const params = { ...this.options };
      params.status = params.status
        ? params.status == "active"
          ? "1"
          : "0"
        : null;

      await this.$store.dispatch("csmsChargeStations/list", params).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });

      this.interval = setTimeout(() => {
        this.fetchData();
      }, 30000);
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("csmsChargeStations/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        if (this.list.length === 0 && this.meta.lastPage == this.options.page) {
          this.options.page -= 1; // Move to the previous page
        }
        this.toggleDeleteModal("close");
        this.fetchData();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    async openOcppModal(item) {
      const params = { charge_station_id: item.id };
      await this.$store
        .dispatch("csmsChargeStations/IntegrationInfo", params)
        .then(() => {
          this.ocppIdModal = true;
        });
    },

    fetchStatus(item) {
      if (item.last_heartbeat_received) {
        const dT = moment(); // Current date and time
        const rT = moment(item.last_heartbeat_received); // Parse item.updated_at as a moment
        const diffInMinutes = dT.diff(rT, "minutes"); // Difference in minutes
        //  (
        //     rT.format("YYYY-MM-DD HH:mm:ss"),
        //     dT.format("YYYY-MM-DD HH:mm:ss"),
        //     diffInMinutes
        //   );

        return diffInMinutes <= 3 ? "active" : "offline";
      } else {
        return "offline";
      }
    },
  },
};
</script>
<style scoped>
.row-box-wrap {
  display: flex;
  align-items: center;
  margin: 8px 0;
  gap: 10px;
  /* Adds space between items */
}

.text-muted {
  color: #6c757d;
}

.highlighted {
  font-weight: bold;
}

.copy-button {
  margin-left: auto;
  /* Pushes the button to the far right */
}
</style>