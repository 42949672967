<template>
  <div>
    <ListingLayout
      :is-loading="intervalLoading ? false : isLoading"
      show-date
      :meta="meta"
      :headers="_headers"
      :items="list"
      :product-options="productOptions"
      search-label="search"
      vertical-scroll
      @filterOption="onFiltersChange"
    >
      <template v-slot:charge_point_id="{ item }">
        <div>
          <span>{{ item.charge_point_id }}/{{ item.connector_id }}</span>
        </div>
      </template>
      <template v-slot:date="{ item }">
        <div>{{ item.started_at | formatDateOnly }}</div>
      </template>
      <template v-slot:started_at="{ item }">
        <div>{{ item.started_at | formatTime }}</div>
      </template>
      <template v-slot:completed_at="{ item }">
        <div>{{ item.completed_at | formatTime }}</div>
      </template>
      <template v-slot:customer_name="{ item }">
        <div
          v-if="item.walk_in_customer_type == 1"
          class="open-link"
          @click="editItem(item)"
        >
          {{ item.customer_name }}
        </div>
        <div v-else>
          {{ item.customer_name }}
        </div>
      </template>
      <template v-slot:duration="{ item }">
        <div>{{ item.duration }} {{ item.duration > 0 ? " min" : "" }}</div>
      </template>
      <template v-slot:energy_consumed="{ item }">
        <div>
          {{ item.energy_consumed }}
          {{ item.energy_consumed > 0 ? " kWh" : "" }}
        </div>
      </template>
      <template v-slot:total_cost="{ item }">
        <div>
          {{
            item.total_cost !== "N/A" && item.total_cost > 0
              ? item.total_cost + " (" + currencySign + ")"
              : item.total_cost
          }}
        </div>
      </template>

      <template #addFilters>
        <v-card>
          <v-select
            v-model="customFilters.location_id"
            filled
            dense
            clearable
            :items="locationsList"
            item-value="id"
            item-text="name"
            :label="$t('location')"
            @change="fetchData"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.charge_point_id"
            filled
            dense
            clearable
            :items="chargersList"
            item-value="charge_point_id"
            item-text="charge_point_id"
            :label="$t('charge_point_id')"
            @change="fetchData"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.status"
            filled
            dense
            clearable
            :items="statusList"
            item-value="id"
            item-text="name"
            :label="$t('status')"
            @change="fetchData"
          />
        </v-card>
      </template>
      <template #moreFilters>
        <div>
          <refresh-button
            :is-loading="isLoading"
            @reload="fetchData"
          />
        </div>
      </template>
      <template #actionModals>
        <!-- <edit-md-make v-if="editModal" :is-visible-dialog="editModal" :toggle-edit="toggleEditModal"
          :md-make="selectedRow" /> -->
        <edit-customer
          v-if="editModal"
          :is-visible-dialog="editModal"
          :toggle-edit="toggleEditModal"
          :customer-data="selectedRow"
          @change="fetchData"
        />
      </template>
    </ListingLayout>
    <!-- <Modal v-if="showModal" :is-visible="showModal" is-view :toggle="() => (showModal = false)">
      <edit-customer :customer-data="customerData" />

    </Modal> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";

// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    refreshButton: () =>
      import("../../../../components/base/RefreshButton.vue"),
    EditCustomer: () => import("./dialogs/EditCustomer.vue"),
    // EditMdMake: () => import("../../md-makes/dialogs/EditMdMake.vue"),
  },
  mixins: [ListingLayoutMixins],
  data() {
    return {
      intervalLoading: false,

      productOptions: [
        { id: "oem", value: "OEM" },
        { id: "cso", value: "CSO" },
      ],
      filters: {},
      customFilters: {},
      statusList: [
        {
          id: "in-progress",
          name: "In-Progress",
        },
        {
          id: "completed",
          name: "Completed",
        },
      ],
      showModal: false,
      isMounted: false,
      customerData: {},
      interval: null,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsChargingSession/getLoading",
      meta: "csmsChargingSession/getMeta",
      list: "csmsChargingSession/getList",
      locationsList: "csmsChargeStations/getLocation",
      chargersList: "csmsChargeStations/getChargers",
    }),
    _headers() {
      const headers = [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("charge_point_id"),
          align: "left",
          sortable: true,
          value: "charge_point_id",
        },
        // {
        //   text: this.$t("charger_connector"),
        //   align: "left",
        //   sortable: false,
        //   value: "charger_connector",
        // },
        {
          text: this.$t("charging_location"),
          align: "left",
          sortable: false,
          value: "location_name",
        },
        {
          text: this.$t("customer_name"),
          align: "left",
          sortable: false,
          value: "customer_name",
        },
        {
          text: this.$t("vehicle"),
          align: "left",
          sortable: false,
          value: "make_and_model",
        },
        {
          text: this.$t("date"),
          align: "left",
          sortable: false,
          value: "date",
        },
        {
          text: this.$t("start_time"),
          align: "left",
          sortable: false,
          value: "started_at",
        },
        {
          text: this.$t("end_time"),
          align: "left",
          sortable: false,
          value: "completed_at",
        },
        {
          text: this.$t("duration"), //min
          align: "left",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("energy_dispensed"), //kWh
          align: "center",
          sortable: false,
          value: "energy_consumed",
        },
        {
          text: this.$t("charge_total_cost"), //currency
          align: "center",
          sortable: false,
          value: "total_cost",
        },

        // {
        //   text: this.$t("meter_start"),
        //   align: "left",
        //   sortable: false,
        //   value: "meter_start",
        // },
        // {
        //   text: this.$t("meter_end"),
        //   align: "left",
        //   sortable: false,
        //   value: "meter_end",
        // },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "status",
        },
      ];
      // computed headers for showing columns to role based
      return headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
    currencySign() {
      return localStorage.getItem("selectedCurrency") || "";
    },
  },
  watch: {
    "options.tenant_id": {
      handler: function (val) {
        if (this.isMounted) {
          this.customFilters = {};
        }
        this.isMounted = true;
        if (val) {
          const params = { tenant_id: val };
          this.$store.dispatch("csmsChargeStations/LocationsList", params);
          this.$store.dispatch("csmsChargeStations/chargerList", params);
        } else {
          // this.customFilters = {};
          this.$store.commit("csmsChargeStations/SET_LOCATION", []);
          this.$store.commit("csmsChargeStations/SET_CHARGERS", []);
        }
      },
    },
    options() {
      this.fetchData();
    },
  },
  async mounted() {
    if (!this.authUser.isAdmin) {
      const params = { tenant_id: this.authUser.tenant_id };
      await this.$store.dispatch("csmsChargeStations/LocationsList", params);
    }
    this.$store.dispatch("csmsChargingSession/setLoadingValue", true);
    if (this.$route?.query?.location_id) {
      this.customFilters.location_id = parseInt(this.$route.query.location_id);
    }
    if (this.$route?.query?.status) {
      this.customFilters.status = this.$route.query.status;
    }
    if (this.$route?.query?.charge_point_id) {
      this.customFilters.charge_point_id = this.$route.query.charge_point_id;
    }
  },
  beforeDestroy() {
    clearTimeout(this.interval);
    const paramsSet = { ...this.options, ...this.customFilters };
    const params = {
      name: "charging_sessions",
      filters: paramsSet,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      clearTimeout(this.interval);
      const params = { ...this.options, ...this.customFilters };
      await this.$store.dispatch("csmsChargingSession/list", params);

      this.interval = setTimeout(() => {
        this.fetchByInterval();
      }, 30000);
    },
    async fetchByInterval() {
      this.intervalLoading = true;
      await this.fetchData();
      this.intervalLoading = false;
    },
  },
};
</script>
<style lang="sass" scoped>
.map-button
  height: 41px !important
  margin-right: 5px

.btn-disabled
  pointer-events: none !important
  background-color: #8f8f8f !important

:deep .v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line):not(.v-text-field--outlined).v-input--dense .v-select__slot > input
  font-size: 0.875rem !important
</style>
