import service from "../services/flespi-plugins-service";

const state = {
  isLoading: false,
  pluginsList: [],
  meta: {},
};

const mutations = {
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_PLUGINS(state, pluginsList) {
    state.pluginsList = pluginsList;
  },
  SET_LOADING_LIST(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
  list: (store, params) => {
    store.state.isLoading = true;
    return service
      .list(params)
      .then((data) => {
        store.commit("SET_PLUGINS", data.body.data);
        store.commit("SET_META", data.body);
      })
      .finally(() => (store.state.isLoading = false));
  },

  pluginSync: (store, params) => {
    store.state.isLoading = true;
    return service
      .pluginSync(params)
      .then(() => {
        store.dispatch("list");
      })
      .finally(() => (store.state.isLoading = false));
  },
  bulkAssign: (store, params) => {
    store.state.isLoading = true;
    return service
      .bulkAssign(params)
      .then((data) => {
        return data.body;
      })
      .finally(() => (store.state.isLoading = false));
  },
};

const getters = {
  getMeta(state) {
    return state.meta;
  },
  getPluginsList(state) {
    return state.pluginsList;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
};

const motorTypes = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default motorTypes;
