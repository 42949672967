<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-card-title class="toptitle">
        <v-btn
          v-if="customerAdd"
          id="backBtn"
          text
          @click="resetCustomerView"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="text-h3">
          {{ customerAdd ? 'Add New' : 'Remote Transaction' }}
        </span>
      </v-card-title>
      <v-card-text v-if="!customerAdd">
        <div class="searchBoxwithBtn">
          <v-text-field
            v-model="searchQuery"
            label="Search Customer"
            placeholder="Booking ID / Customer Phone "
            clearable
            @keydown.enter="fetchCustomerData"
          />
          <v-btn
            color="primary"
            @click="addCustomer()"
          >
            Add New
          </v-btn>
        </div>
        <v-data-table
          v-if="tableView"
          :loading="getCustomerListLoading"
          :headers="transactionHeader"
          :items="searchCustomerData"
          item-value="id"
          class="elevation-1"
          hide-default-footer
          @update:select-all="() => (selectedCustomer = null)"
          @update:items-per-page="(selected) =>
            (selectedCustomer = selected[0])
          "
        >
          <template v-slot:[`footer`]>
            <data-table-scroll :data-table-key="1" />
          </template>
          <template v-slot:no-data>
            <span>
              {{ $t("no_data_available") }}
            </span>
          </template>
          <template v-slot:item.id="{ item }">
            <input
              v-model="selectedItem"
              type="radio"
              :value="item"
              @change="onItemSelect(item)"
            >
          </template>
          <template v-slot:item.vehicle="{ item }">
            <div>
              <span>{{ item.vehicle.make_and_model }} ({{ item.vehicle.registration_no }})</span>
            </div>
          </template>
          <template v-slot:item.booking="{ item }">
            <div>
              <span>{{ item.booking && item.booking.booking_date ?
                item.booking.booking_date + (item.booking.start_time) : 'N/A'
              }}</span>
            </div>
          </template>
        </v-data-table>
      </v-card-text>

      <div v-if="customerAdd">
        <v-form
          ref="form"
          lazy-validation
        >
          <v-card class="addNewCard">
            <v-card-text>
              <v-container>
                <!-- Customer Details -->
                <span class="text-h3 custom-h3">{{ $t("Customer Detail") }}:</span>
                <v-row dense>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="customer.name"
                      dense
                      :label="$t('customer_name') + ' *'"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="customer.email"
                      dense
                      :label="$t('email_*')"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="customer.country_code"
                      dense
                      :items="countryCodeList"
                      item-value="id"
                      item-text="name"
                      :label="$t('country_code_*')"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="customer.phone_number"
                      dense
                      :label="$t('phone_number_*')"
                      :rules="[
                        rules.required,
                        rules.min_length(10),
                        rules.max_length(10),
                      ]"
                    />
                  </v-col>
                </v-row>

                <!-- Vehicle Details -->
                <span class="text-h3 custom-h3 bodTop">{{ $t("Vehicle Detail") }}</span>
                <v-row dense>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-autocomplete
                      v-model="vehicle.md_make_id"
                      dense
                      :items="mdMakesList"
                      :loading="isLoadingMdMakes"
                      item-value="id"
                      item-text="name"
                      :label="$t('ev_make_*')"
                      :rules="[rules.required]"
                      @change="onMakeChange"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="vehicle.md_model"
                      dense
                      :items="vehicle.md_make_id ? mdModelsList : []"
                      :loading="isLoadingMdModels"
                      item-value="id"
                      item-text="name"
                      :label="$t('ev_model_*')"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="vehicle.year"
                      dense
                      type="number"
                      :min="getStartingYear()"
                      :max="getCurrentYear()"
                      :label="$t('year_*')"
                      :rules="[rules.required, rules.min_year(), rules.max_year()]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="vehicle.registration_no"
                      dense
                      :label="$t('registration_no') + '*'"
                      :rules="[rules.required]"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-form>
      </div>


      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="toggleEdit()"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="isLoadingSave"
          @click="startAction"
        >
          Start
        </v-btn>
      </v-card-actions>
      <!-- <v-card-actions v-if="customerAdd">
                <v-spacer />
                <v-btn color="red darken-1" text @click="toggleEdit()">
                    Close
                </v-btn>
                <v-btn color="primary" text :loading="isLoadingSave" @click="submitCustomer">
                    Submit
                </v-btn>
            </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
    components: {
        dataTableScroll: () => import("@/components/base/dataTableScroll.vue"),
    },
    mixins: [validationRules],
    props: {
        isVisibleDialog: Boolean,
        toggleEdit: {
            type: Function,
            default() {
                return void 0;
            },
        },
        customerData: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            isLoadingSave: false,
            customer: {},
            vehicle: {},
            customerAdd: false,
            tableView: false,
            dialog: false,
            searchQuery: "",
            selectedCustomer: null,
            selectedItem: null,
            bookingId: null,
            customerSelectedData: {}
        };
    },
    computed: {
        ...mapGetters({
            authUser: "getUser",
            mdMakesList: "mdMakes/activelistMdMakes",
            isLoadingMdMakes: "mdMakes/isLoadingMdMakes",
            isLoadingMdModels: "mdModels/isLoadingMdModels",
            mdModelsList: "mdModels/activelistMdModels",
            countryCodeList: "getCountryCode",
            searchCustomerData: "csmsChargeStations/getBookingCustomer",
            getCustomerListLoading: "csmsChargeStations/getCustomerListLoading"
        }),
        transactionHeader() {
            const headers = [
                { text: 'Select', value: 'id', align: 'start' },
                { text: 'Customer Name', value: 'name' },
                { text: 'Phone', value: 'phone_number' },
                { text: 'Email', value: 'email' },
                { text: 'Vehicle', value: 'vehicle' },
                { text: 'Booking Time', value: 'booking' },
            ];
            return headers;
        },
    },

    async mounted() {
        if (this.customerData && this.customerData.tenant_id) {
            await this.$store.dispatch("mdMakes/list", {
                tenant_id: this.customerData.tenant_id,
            });
        }
    },
    methods: {
        onItemSelect(item) {
            if (item.booking && Object.keys(item.booking).length > 0) {
                this.bookingId = item.booking.booking_code;
            } else {
                this.customerSelectedData = {
                    customer: {
                        id: item.id,
                        name: item.name,
                        email: item.email,
                        phone_number: item.phone_number
                    },
                    vehicle: item.vehicle,
                    charge_point_id: this.customerData.charge_point_id,
                    connector_id: this.customerData.connector_id
                };
            }
        },
        addCustomer() {
            this.resetTableView()
            this.customerAdd = true;
            this.tableView = false;
            this.searchQuery = "";
        },
        resetTableView() {
            this.selectedItem = null;
            this.customerSelectedData = null;
            this.bookingId = null;
        },
        resetCustomerView() {
            this.customerAdd = false;
            this.customer = {};
            this.vehicle = {};
        },
        async fetchCustomerData() {
            this.resetCustomerView();
            this.resetTableView();
            await this.$store.dispatch("csmsChargeStations/bookingCustomerList", {
                tenant_id: this.customerData.tenant_id,
                search: this.searchQuery
            }).then(() => { this.tableView = true });
        },
        async startAction() {
            if (this.customerAdd) {
                const isValid = this.$refs.form?.validate();
                if (!isValid) {
                    return;
                }
            } else if (this.tableView) {
                if (!this.selectedItem) {
                    this.$store.dispatch(
                        "alerts/error",
                        this.$t("Select Customer")
                    );
                    return;
                }
            }

            let params = {}
            params.action = "remote-transaction-start"
            let paramDetail = {};
            if (this.customerAdd) {
                paramDetail = {};
                paramDetail.charge_point_id = this.customerData.charge_point_id
                paramDetail.connector_id = this.customerData.connector_id
                paramDetail.customer = this.customer
                paramDetail.vehicle = this.vehicle
            }
            else if (this.tableView && this.bookingId) {
                paramDetail = {};
                paramDetail.booking_id = this.bookingId
            }
            else if (this.tableView) {
                paramDetail = {};
                paramDetail = this.customerSelectedData
            }
            params.data = paramDetail;
            await this.$store
                .dispatch("csmsChargeStations/ocppRemoteAction", params)
                .then(() => {
                    this.$store.dispatch(
                        "alerts/success",
                        this.$t("updated_successfully")
                    );
                    this.toggleEdit();
                    this.$emit('change');
                }).catch((error) => {
                    this.$store.dispatch("alerts/error", error.response?.data?.message);
                });
        },
        async onMakeChange(v) {
            delete this.vehicle.md_model_id;
            if (v) {
                await this.$store.dispatch("mdModels/list", {
                    md_make_id: v ?? false,
                });
            }
        },


        submitCustomer() {
            this.resetView();
        },
    },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

.searchBoxwithBtn
  display: flex
  flex-wrap: wrap
  align-items: center
  :deep .v-input
    width: 70%
    margin-right: 1.75rem
  :deep button
    margin: 0 !important
.toptitle
  #backBtn
    padding: 0 !important
    position: absolute
    left: 27px
    background: #eee
    display: flex
    align-items: center
    justify-content: center
    align-items: center
    margin: 0 !important
    :deep .v-btn__content
      .v-icon
        position: relative
        top: unset
        left: unset
        right: unset
        opacity: 1
        font-size: 21px
        color: #333
        height: 29px !important



.v-application
  .custom-h3
    font-size: 1.25rem !important
    color: #333
    fomt-familu: $poppinMed
    margin-bottom: 0.75rem !important
    display: inline-block
  .bodTop
    margin-top: 1rem !important
    display: block
    padding-top: 1rem


.addNewCard
  box-shadow: 0 0 0 !important
  border: 0 !important
</style>