<template>
  <div id="map" />
</template>

<script>
import L from "leaflet";
import "leaflet.fullscreen"; // Import the fullscreen plugin
import "leaflet.fullscreen/Control.FullScreen.css";

// For Dynamic Funtions
import { LeafLetMixins } from "./LeafLetMixins.js";

export default {
  name: "DynamicSnappingMap",
  mixins: [LeafLetMixins],
  props: {
    tripsData: {
      type: Array,
      default() {
        return [];
      },
    },
    directionArrowColor: {
      type: Boolean,
      default() {
        return false;
      },
    },
    popupOptions: {
      type: Array,
      default() {
        return ["latitude", "longitude", "date_time"];
      },
    },
    //
    vehicleStatus: {
      type: String,
      default() {
        return "";
      },
    },
    vehicleBodyType: {
      type: String,
      default() {
        return "";
      },
    },
    //
    eventData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      map: null,
      startMarker: null,
      endMarker: null,
      snappedRoute: [],
      currentIndex: 0,
      pathLine: null,
      //
      isPlaying: false,
      playPauseControl: null,

      // For event Data
      parked: null,
      idle: null,

      //
      onPage: true,
    };
  },
  computed: {
    path() {
      return this.tripsData.map((r) => [r.latitude, r.longitude]);
    },
  },
  mounted() {},
  beforeDestroy() {
    this.onPage = false;
  },
  methods: {
    VueLeafLetMapLoaded() {
      if (this.path.length > 0) {
        // // this.createStartMarker();
        this.pathLine = L.polyline([], {
          color: "#20a390",
          weight: 5,
          opacity: 0.8,
        }).addTo(this.map);
        this.fetchNextSnappedPoint(0); // Start snapping points progressively
        // // setTimeout(() => this.animateMarker(1000), 500); // Start moving after a small delay
        this.mapSetBound();
        if (this.path.length > 1) {
          this.addPlayPauseControl(); // Add custom map control
        }
      }
    },

    createStartMarker() {
      const balloonIcon = L.divIcon({
        className: "",
        html: `
        <div class="pin-icon-container">
          <div class="pin-circle ${this.vehicleStatus}">
          ${this.vMarker}
          </div>
            <div class="pin-arrow"></div>
          </div>
        `,
        iconAnchor: [20, 60],
      });
      this.startMarker = L.marker(this.snappedRoute[0], {
        icon: balloonIcon,
      }).addTo(this.map);
    },
    createEndMarker() {
      // <i class="mdi mdi-flag"></i>
      const balloonEndIcon = L.divIcon({
        className: "",
        html: `
          <div class="pin-icon-container">
            <div class="pin-circle">🏁</div>
            <div class="pin-arrow"></div>
          </div>
        `,
        iconAnchor: [20, 60],
      });
      const len = this.snappedRoute.length;
      this.endMarker = L.marker(this.snappedRoute[len - 1], {
        icon: balloonEndIcon,
      }).addTo(this.map);
    },

    mapSetBound() {
      if (this.path.length > 1) {
        const bounds = L.latLngBounds(this.path);
        this.map.fitBounds(bounds);
      } else if (this.path.length > 0) {
        this.map.setView(this.path[0]);
      }
    },
    addPlayPauseControl() {
      if (this.playPauseControl) {
        this.map.removeControl(this.playPauseControl); // Remove the control
        this.playPauseControl = null; // Clear the reference
      }

      this.playPauseControl = L.control({ position: "topright" });

      this.playPauseControl.onAdd = () => {
        const container = L.DomUtil.create("div", "leaflet-bar custom-control");
        container.innerHTML = `<v-btn class="play-pause-button">${
          this.isPlaying ? "⏸" : "▶"
        }</v-btn>`;

        L.DomEvent.on(container, "click", () => {
          this.toggleAnimation();
          container.innerHTML = `<button class="play-pause-button">${
            this.isPlaying ? "⏸" : "▶"
          }</button>`;
        });

        return container;
      };

      this.playPauseControl.addTo(this.map);
    },

    async fetchNextSnappedPoint(index) {
      if (index < this.path.length && this.onPage) {
        const snappedPoint = await this.snapPointToRoad(this.path[index]);
        this.snappedRoute.push(snappedPoint);

        // for marekr
        if (index == 0) {
          this.createStartMarker();
        }

        if (this.snappedRoute.length >= 1) {
          this.addDirectionArrow(
            this.snappedRoute[this.snappedRoute.length - 1],
            snappedPoint
          );
          this.pathLine.addLatLng(snappedPoint);
        }

        this.fetchNextSnappedPoint(index + 1);
      } else {
        if (this.path.length > 1) this.createEndMarker();
        this.plotEventData();
      }
    },
    toggleAnimation() {
      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) {
        // Start or resume the animation
        this.animateMarker(1000);
      } else {
        // Stop the animation
        // this.currentIndex = 0;
        // this.startMarker.setLatLng(this.snappedRoute[0]);
      }
    },
    animateMarker(duration) {
      const move = () => {
        if (
          this.currentIndex < this.snappedRoute.length - 1 &&
          this.isPlaying
        ) {
          const from = this.snappedRoute[this.currentIndex];
          const to = this.snappedRoute[this.currentIndex + 1];
          const start = performance.now();

          const step = (timestamp) => {
            const progress = Math.min((timestamp - start) / duration, 1);
            const lat = from[0] + (to[0] - from[0]) * progress;
            const lng = from[1] + (to[1] - from[1]) * progress;
            this.startMarker.setLatLng([lat, lng]);

            this.map.setView([lat, lng], this.map.getZoom());

            if (progress < 1) {
              requestAnimationFrame(step);
            } else {
              this.currentIndex++;
              move();
            }
          };

          requestAnimationFrame(step);
        } else if (
          this.snappedRoute.length <= this.currentIndex + 1 &&
          this.currentIndex <= this.path.length - 1
        ) {
          this.isPlaying = false;
          this.currentIndex = 0;
          this.startMarker.setLatLng(this.snappedRoute[0]);
          this.addPlayPauseControl();
          this.mapSetBound();

          // setTimeout(move, 100); //For auto replay
        }
      };

      move();
    },
    addDirectionArrow(from, to) {
      let ovrSpd = "";
      const r = this.pointDetail(from);

      if (this.directionArrowColor) {
        ovrSpd = r.over_speed ? "over-speed" : "";
      }

      if (["Parked", "Idle"].includes(r.state)) {
        const balloonIcon = L.divIcon({
          className: "",
          html: `
          <div class="pin-icon-container">
            <div class="pin-circle">${r.state == "Parked" ? "🅿️" : "🚫"}</div>
            <div class="pin-arrow"></div>
          </div>
        `,
          iconAnchor: [20, 60],
        });
        const mrk = L.marker(from, {
          icon: balloonIcon,
        }).addTo(this.map);
        mrk.bindPopup(this.eventPopupDetail(r));
      } else {
        const bearing =
          (Math.atan2(to[1] - from[1], to[0] - from[0]) * 180) / Math.PI;
        const arrowIcon = L.divIcon({
          className: "",
          html: `<div class="direction-arrow ${ovrSpd}" style="transform: rotate(${bearing}deg);"></div>`,
          iconSize: [10, 10],
          iconAnchor: [5, 5],
        });
        const mrk = L.marker(from, { icon: arrowIcon }).addTo(this.map);
        mrk.bindPopup(this.popupDetail(r));
      }
    },

    pointDetail(pnt) {
      const index = this.snappedRoute.findIndex(
        (r) => r[0] == pnt[0] && r[1] == pnt[1]
      );
      return this.tripsData[index];
    },
    popupDetail(row) {
      const x = this.popupOptions.map(
        (r) =>
          `<div>${r.replace(/_/g, " ")}: ${row[r]}  ${
            r == "speed" ? "KM/Hr" : ""
          }</div>`
      );

      return `
      <div class="popup-box">
      ${x.join("")}
      </div>`;
    },
    //
    plotEventData() {
      if (this.eventData.length > 0) {
        this.parked = this.eventData.filter((r) =>
          ["Parked"].includes(r.state)
        );
        this.idle = this.eventData.filter((r) => ["Idle"].includes(r.state));

        if (this.parked.length > 0) {
          this.createParkedEvent();
        }
        if (this.idle.length > 0) {
          this.createIdleEvent();
        }
      }
    },
    createParkedEvent() {
      this.parked.forEach(async (el) => {
        // const point = await this.snapPointToRoad([el.latitude, el.longitude]);
        const balloonIcon = L.divIcon({
          className: "",
          html: `
          <div class="pin-icon-container">
            <div class="pin-circle">🅿️</div>
            <div class="pin-arrow"></div>
          </div>
        `,
          iconAnchor: [20, 60],
        });
        const mrk = L.marker([el.latitude, el.longitude], {
          icon: balloonIcon,
        }).addTo(this.map);
        mrk.bindPopup(this.eventPopupDetail(el));
      });
    },
    createIdleEvent() {
      this.idle.forEach(async (el) => {
        const point = await this.snapPointToRoad([el.latitude, el.longitude]);
        const balloonIcon = L.divIcon({
          className: "",
          html: `
          <div class="pin-icon-container">
            <div class="pin-circle">🚫</div>
            <div class="pin-arrow"></div>
          </div>
        `,
          iconAnchor: [20, 60],
        });
        const mrk = L.marker(point, {
          icon: balloonIcon,
        }).addTo(this.map);
        mrk.bindPopup(this.eventPopupDetail(el));
      });
    },

    eventPopupDetail(row) {
      return `
      <div class='popup-box'>
        <div class="title">${row.state}</div>
        <div> Latitude: ${row.latitude}</div>
        <div> Longitude: ${row.longitude}</div>
        <div> Start Time: ${row.start_time}</div>
        <div> End Time: ${row.end_time}</div>
        <div> Duration: ${row.formatted_duration}</div>
      </div>
      `;
    },
  },
};
</script>

<style scoped>
#map {
  height: 100vh;
  width: 100%;
}

:deep .pin-icon-container {
  position: relative;
  width: 40px;
  height: 60px;
}
:deep .pin-circle {
  padding: 5px;
  width: 40px;
  height: 40px;
  /* background-color: #20a390; */
  background-color: #ffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
:deep .pin-arrow {
  position: absolute;
  top: 40px;
  left: 15px;
  width: 10px;
  height: 20px;
  background-color: #ffff;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
:deep .direction-arrow {
  width: 10px;
  height: 10px;
  background-color: #155148;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  transform-origin: center;
}

:deep .active svg path {
  fill: #23bdaa !important;
}

:deep .in_active svg path,
:deep .inactive svg path {
  fill: #ffae20 !important;
}

:deep .offline svg path {
  fill: #fa896b;
}

:deep .over-speed {
  background-color: #f75a30 !important;
}

:deep .popup-box {
  text-transform: capitalize !important;
}

:deep .play-pause-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 30px;
  height: 30px;
  font-size: 16px;
}
</style>
