<template>
  <div>
    <ListingLayout
      show-date
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :product-options="productOptions"
      search-label="search"
      vertical-scroll
      @filterOption="onFiltersChange"
    >
      <template v-slot:JsonPayload="{ item }">
        <div>
          {{ item.JsonPayload.JsonPayload }}
          <!-- <typography :text="item.JsonPayload.JsonPayload" text-clip :copyContent=true /> -->
        </div>
      </template>
      <template v-slot:Timestamp="{ item }">
        <div>{{ item.Timestamp | formatDate }}</div>
      </template>


      <template #addFilters>
        <v-card>
          <v-select
            v-model="customFilters.location_id"
            filled
            dense
            clearable
            :items="locationsList"
            item-value="id"
            item-text="name"
            :label="$t('location')"
            @change="fetchData"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.charge_point_id"
            filled
            dense
            clearable
            :items="chargersList"
            item-value="charge_point_id"
            item-text="charge_point_id"
            :label="$t('charge_point_id')"
            @change="fetchData"
          />
        </v-card>
      </template>
      <template #moreFilters>
        <div>
          <refresh-button
            :is-loading="isLoading"
            @reload="fetchData"
          />
        </div>
      </template>
    </ListingLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";

// import { debounce } from "lodash";
export default {
  components: {
    // Typography: () => import("@/components/base/Typography.vue"),
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    refreshButton: () =>
      import("../../../../components/base/RefreshButton.vue"),
  },
  mixins: [ListingLayoutMixins],
  data() {
    return {
      productOptions: [
        { id: "oem", value: "OEM" },
        { id: "cso", value: "CSO" },
      ],
      filters: {},
      customFilters: {},
      isMounted: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsOcppLogs/getLoading",
      meta: "csmsOcppLogs/getMeta",
      list: "csmsOcppLogs/getList",
      locationsList: "csmsChargeStations/getLocation",
      chargersList: "csmsChargeStations/getChargers",
    }),
    _headers() {
      const headers = [
        {
          text: this.$t("dateTime"),
          align: "left",
          sortable: true,
          value: "Timestamp",
        },
        {
          text: this.$t("Request Type"),
          align: "left",
          sortable: false,
          value: "RequestType",
        },
        {
          text: this.$t("Event Type"),
          align: "left",
          sortable: false,
          value: "EventType",
        },
        {
          text: this.$t("JSON"),
          align: "left",
          sortable: false,
          value: "JsonPayload",
        }
      ];
      // computed headers for showing columns to role based
      return headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
  },
  watch: {
    "options.tenant_id": {
      handler: function (val) {
        if (this.isMounted) {
          this.customFilters = {};
        }
        this.isMounted = true;
        if (val) {
          const params = { tenant_id: val };
          this.$store.dispatch("csmsChargeStations/LocationsList", params);
          this.$store.dispatch("csmsChargeStations/chargerList", params);
        } else {
          // this.customFilters = {};
          this.$store.commit("csmsChargeStations/SET_LOCATION", []);
          this.$store.commit("csmsChargeStations/SET_CHARGERS", []);
        }
      },
    },
    options() {
      this.fetchData();
    },
  },
  async mounted() {
    if (!this.authUser.isAdmin) {
      const params = { tenant_id: this.authUser.tenant_id };
      await this.$store.dispatch("csmsChargeStations/LocationsList", params);
    }
    this.$store.dispatch("csmsOcppLogs/setLoadingValue", true);
    if (this.$route?.query?.location_id) {
      this.customFilters.location_id = parseInt(this.$route.query.location_id);
    }
    if (this.$route?.query?.charge_point_id) {
      this.customFilters.charge_point_id = this.$route.query.charge_point_id;
    }
  },
  beforeDestroy() {
    const paramsSet = { ...this.options, ...this.customFilters };
    const params = {
      name: "ocpp_logs",
      filters: paramsSet,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    fetchData() {
      const params = { ...this.options, ...this.customFilters };
      this.options.page = 1;
      this.$store.dispatch("csmsOcppLogs/list", params);
    },
  },
};
</script>
<style lang="sass" scoped>
  .map-button
    height: 41px !important
    margin-right: 5px

  .btn-disabled
    pointer-events: none !important
    background-color: #8f8f8f !important

  :deep .v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line):not(.v-text-field--outlined).v-input--dense .v-select__slot > input
    font-size: 0.875rem !important
  </style>