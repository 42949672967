import service from "@/store/services/csms-charge-stations-service";

import serviceAdx from "@/helpers/service";

const state = {
  loading: false,
  meta: {},
  list: [],
  connectorMeta: {},
  connectorList: [],
  bookingCustomer: [],
  locations: [],
  chargers: [],
  integrationInfo: [],
  integrationLoader: false,
  customerListLoading: false,
};

const mutations = {
  SET_LOADING_LIST(state, value) {
    state.loading = value;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_LOCATION(state, payload) {
    state.locations = payload;
  },
  SET_CHARGERS(state, payload) {
    state.chargers = payload;
  },
  SET_BOOKING_CUSTOMER(state, payload) {
    state.bookingCustomer = payload;
  },
  SET_INTEGRATION_INFO(state, payload) {
    state.integrationInfo = payload;
  },
  SET_CONNECTOR_META(state, data) {
    if (data.meta) {
      state.connectorMeta.page = data.meta.current_page;
      state.connectorMeta.itemsPerPage = data.meta.per_page;
      state.connectorMeta.lastPage = data.meta.last_page;
      state.connectorMeta.totalItems = data.meta.total;
    }
  },
  SET_CONNECTOR_LIST(state, payload) {
    state.connectorList = payload;
  },

  UPDATE_LIST(state, payload) {
    const item = state.list.find((item) => item.id === payload.id);
    Object.assign(item, payload);
  },

  DELETE_LIST(state, payload) {
    state.list.splice(state.list.map((o) => o.id).indexOf(payload.id), 1);
  },
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
  list: (store, params) => {
    store.state.loading = true;
    service.list(params).then((data) => {
      store.commit("SET_META", data.body);
      store.commit("SET_LIST", data.body.data);
      store.state.loading = false;
    });
  },
  LocationsList: (store, params) => {
    service.locationList(params).then((data) => {
      store.commit("SET_LOCATION", data.body);
    });
  },
  chargerList: (store, params) => {
    serviceAdx
      .get(`charging-station/get-dropdown-ocpp-id`, params)
      .then((res) => {
        store.commit("SET_CHARGERS", res.body);
      });
  },
  bookingCustomerList: (store, params) => {
    store.state.customerListLoading = true;
    serviceAdx
      .get(`core/get-customer`, params)
      .then((res) => {
        store.commit("SET_BOOKING_CUSTOMER", res.body);
        store.state.customerListLoading = false;
      });
  },
  IntegrationInfo: (store, params) => {
    store.state.integrationLoader = true;
    service.integrationInfo(params).then((data) => {
      store.commit("SET_INTEGRATION_INFO", data.body);
      store.state.integrationLoader = false;
    });
  },
  connectorlist: (store, params) => {
    store.state.loading = true;
    service.connectorlist(params).then((data) => {
      store.commit("SET_CONNECTOR_META", data.body);
      store.commit("SET_CONNECTOR_LIST", data.body.data);
      store.state.loading = false;
    });
  },
  ocppRemoteAction: (store, params) => {
    return serviceAdx
      .post("bookings/ocpp-remote-action", params)
      .then((data) => {
        return data.body.data;
      })
      .finally(() => (store.state.loading = false));
  },

  add: (store, { payload, filter }) => {
    return service.add(payload).then(() => {
      // store.commit("ADD_LIST", data.body);
      store.dispatch("list", filter);
    });
  },

  update: (store, params) => {
    return service.update(params).then((data) => {
      store.commit("UPDATE_LIST", data);
    });
  },

  destroy: (store, params) => {
    return service.destroy(params).then(() => {
      store.commit("DELETE_LIST", params);
    });
  },
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  getIntegrationLoading(state) {
    return state.integrationLoader;
  },
  getCustomerListLoading(state) {
    return state.customerListLoading;
  },
  getMeta(state) {
    return state.meta;
  },
  getIntegrationInfo(state) {
    return state.integrationInfo;
  },
  getList(state) {
    return state.list;
  },
  getConnectorMeta(state) {
    return state.connectorMeta;
  },
  getConnectorlist(state) {
    return state.connectorList;
  },
  getLocation(state) {
    return state.locations;
  },
  getChargers(state) {
    return state.chargers;
  },
  getBookingCustomer(state) {
    return state.bookingCustomer;
  },
};

const csmsRates = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default csmsRates;
