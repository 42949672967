<template>
  <!-- v-if="bound.length > 0" -->
  <l-map
    :style="{ height: height }"
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    :bounds="bounds"
  >
    <!-- :class="isFullscreen ? 'map-aaaa' : ''" -->
    <l-tile-layer
      :url="url"
      :attribution="attribution"
    />
    <!-- <l-control class="fullscreen-control">
      <v-btn
        small
        @click="toggleFullscreen"
      >
        <v-icon>
          {{ isFullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen" }}
        </v-icon>
      </v-btn>
    </l-control> -->
    <!-- <l-control-fullscreen
      position="topright"
      :options="{ title: { false: 'Full Screen', true: 'Dashboard View' } }"
    /> -->

    <!--  -->

    <slot name="markers">
      <!-- <l-marker :lat-lng="markerLatLng" /> -->
    </slot>

    <slot name="polyline">
      <!-- <l-polyline
        :lat-lngs="polyline.latlngs"
        :color="polyline.color"
      /> -->
    </slot>
  </l-map>
</template>

<script>
import "leaflet.fullscreen"; // Import the fullscreen plugin
import "leaflet.fullscreen/Control.FullScreen.css";
import { latLngBounds } from "leaflet";
import { LMap, LTileLayer } from "vue2-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    // LMarker,
    // LPolyline,
    // LControl,
    //
  },
  props: {
    height: {
      type: String,
      default() {
        return "300px";
      },
    },
    bound: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      isFullscreen: false,
      zoom: 15,
      mapOptions: {
        zoomSnap: 0.5,
        fullscreenControl: true, // Enable fullscreen control
        fullscreenControlOptions: {
          position: "bottomright", // Customize position (optional)
        },
      },
      center: [47.31322, -1.319482],
      markerLatLng: [],
      polyline: {
        latlngs: [
          [47.334852, -1.509485],
          [47.342596, -1.328731],
          [47.241487, -1.190568],
          [47.234787, -1.358337],
        ],
        color: "green",
      },
    };
  },
  computed: {
    bounds() {
      if (this.bound.length > 0) {
        return latLngBounds(this.bound);
      } else {
        return latLngBounds([this.center]);
      }
    },
  },
  methods: {},
};
</script>
<style lang="sass" scoped>
.fullscreen-control
    button
        background: #FFF !important
        i
            color: #20a390 !important
</style>
