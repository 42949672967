import store from "@/store";
import admin from "../plugins/admin";

export default function guest({ next, router }) {
  store.dispatch("checkAuth");
  const userRole = store.getters.getUser;
  if (store.getters.isAuthenticated) {
    // check redirect URL
    const key = "redirectURL";
    const URL = localStorage.getItem(key);
    if (URL) {
      localStorage.removeItem("redirectURL");
      window.location.href = URL;
    } else if (admin.hasAccessTo("dashboard.list") && (userRole.user_nature && userRole.user_nature !== 'cso')) {
      return router.push({ name: "dashboard" });
    } else if (admin.hasAccessTo("order-management.list")) {
      return router.push({ name: "order_management" });
    } else if (admin.hasAccessTo("charging-dashboard.list")) {
      return router.push({ name: "charging_dashboard" });
    }
    else {
      return router.push({ name: "vehicle_telematics" });
    }
  }

  return next();
}
