<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :is-filters-show="false"
      no-filters
      :filter-names="filterNames"
      @filterOption="onFiltersChange"
    >
      <template v-slot:configuration="{ item }">
        <div
          v-for="(row, i) in item.configuration"
          :key="i"
          class="text-capitalize"
        >
          {{ `${i.replace(/_/g, " ")} : ${row} KM` }}
        </div>
      </template>
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('tenant-configurations.edit')"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('tenant-configurations.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">

        </v-card>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-tenant-config
            v-if="$admin.can('tenant-configurations.create')"
            :filter-options="options"
          />
          <edit-tenant-config
            v-if="$admin.can('tenant-configurations.edit') && editModal"
            :is-visible-dialog="editModal"
            :filter-options="options"
            :toggle-edit="toggleEditModal"
            :item="selectedRow"
          />
          <confirmation-modal
            v-if="$admin.can('tenant-configurations.delete')"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    createTenantConfig: () => import("./dialogs/CreateTenantConfig.vue"),
    EditTenantConfig: () => import("./dialogs/EditTenantConfig.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},

      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "tenant_id",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "tenant_name",
        },
        {
          text: this.$t("created_by"),
          align: "left",
          sortable: false,
          value: "created_by",
          showAdmin: true,
        },
        {
          text: this.$t("configuration"),
          align: "left",
          sortable: false,
          value: "configuration",
        },
        ...(this.$admin.hasAccessTo("tenant-configurations.delete") ||
          this.$admin.hasAccessTo("tenant-configurations.edit")
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "tenantsConfigurations/getIsLoading",
      meta: "tenantsConfigurations/getMeta",
      list: "tenantsConfigurations/getList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      if (this.authUser.isAdmin) {
        return [];
      } else {
        return [];
      }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  mounted() {
    this.$store.dispatch('tenantsConfigurations/setLoadingValue', true)
  },
  beforeDestroy() {
    if (this.authUser.user_nature == "fleet") {
      this.options.tenant_id = this.authUser.tenant_id;
    }
    const params = {
      name: "tenant_configurations",
      filters: {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
      },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      if (this.authUser.user_nature == "fleet") {
        this.options.tenant_id = this.authUser.tenant_id;
      }
      await this.$store
        .dispatch("tenantsConfigurations/list", this.options)
        .then(() => {
          if (this.options.page > (this.meta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("tenantsConfigurations/destroy", {
          id: row.tenant_id,
        });
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        if (this.list.length === 0 && this.meta.lastPage == this.options.page) {
          this.options.page -= 1; // Move to the previous page
        }
        this.toggleDeleteModal("close");
        this.fetchData();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>