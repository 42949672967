<template>
  <v-card
    elevation="4"
    class="mt-0"
  >
    <v-container
      id="regular-tables"
      fluid
      tag="section"
    >
      saasd
      <v-data-table
        :headers="headers"
        :items="listOems"
        :loading="isLoadingOems"
        :items-per-page="20"
        :server-items-length="OemsMeta.totalItems"
        :footer-props="{ 'items-per-page-options': [20, 50, 100] }"
        :options.sync="options"
        class="elevation-0"
      >
        <!-- actions -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            class="mr-2"
            small
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            class="mr-2"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <!-- actions -->

        <template v-slot:no-data>
          {{ $t("no_data_available") }}
        </template>
        <template v-slot:top>
          <div class="listing-top-header">
            <v-row class="mx-2 mx-0">
              <v-col cols="12">
                <v-text-field
                  v-model="options.search"
                  clearable
                  :label="$t('search')"
                  class=""
                />
              </v-col>
              <!-- <v-col
                  cols="6"
                  sm="2"
                  class="pl-0"
                >
                  <v-autocomplete
                    clearable
                    v-model="options.role_name"
                    :items="listRoles"
                    item-text="name"
                    item-value="name"
                    :label="$t('role')"
                    no-filter
                  ></v-autocomplete>
                </v-col> -->
            </v-row>
            <!-- content for top e.g add new user btn and other goes here -->
            <v-toolbar flat>
              <create-oem v-if="$admin.can('oem-clients.create')" />
              <edit-oem
                v-if="$admin.can('oem-clients.edit') && editOemModal"
                :is-visible-dialog="editOemModal"
                :toggle-edit="toggleEditModal"
                :oem="editOem"
              />
              <delete-oem
                v-if="$admin.can('oem-clients.delete')"
                :is-visible-delete-dialog="deleteOemModal"
                :toggle-delete="toggleDeleteModal"
                :oem="editOem"
              />
            </v-toolbar>
            <!-- content for top e.g add new user btn and other goes here -->
          </div>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";

export default {
  components: {
    CreateOem: () => import("./dialogs/CreateOEM.vue"),
    EditOem: () => import("./dialogs/EditOEM.vue"),
    DeleteOem: () => import("./dialogs/DeleteOEM.vue"),
  },

  data() {
    return {
      editOemModal: false,
      deleteOemModal: false,
      editOem: {},
      options: {},
      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name",
          width: "15%",
        },
        {
          text: this.$t("email"),
          align: "left",
          sortable: false,
          value: "email",
        },
        {
          text: this.$t("contact"),
          align: "left",
          sortable: false,
          value: "contact",
          width: "10%",
        },
        {
          text: this.$t("address"),
          align: "left",
          sortable: false,
          value: "address",
        },
        {
          text: this.$t("no_of_users_count"),
          align: "left",
          sortable: false,
          value: "no_of_users_account",
        },

        ...(this.$admin.hasAccessTo("oem-clients.delete") ||
          this.$admin.hasAccessTo("oem-clients.edit")
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ],
    };
  },
  computed: {
    ...mapGetters({
      listOems: "oemclients/getOemsList",
      OemsMeta: "oemclients/getOemsMeta",
      isLoadingOems: "oemclients/getIsLoadingOems",
    }),
  },

  watch: {
    options: {
      handler: debounce(async function () { }, 1000),
      deep: true,
    },
  },
  async mounted() { },
  methods: {
    editItem(item) {
      this.editOem = { ...item };
      this.toggleEditModal();
    },
    toggleEditModal() {
      return (this.editOemModal = !this.editOemModal);
    },
    deleteItem(item) {
      this.editOem = { ...item };
      this.toggleDeleteModal();
    },
    toggleDeleteModal() {
      return (this.deleteOemModal = !this.deleteOemModal);
    },
  },
};
</script>