<template>
  <div>
    <v-menu
      v-model="menu"
      :disabled="disabled"
      :close-on-content-click="false"
      :nudge-right="0"
      transition="scale-transition"
      offset-y
      content-class="custom-menu-offset"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          :filled="filled"
          dense
          :disabled="disabled"
          :label="label"
          readonly
          :clearable="clearable"
          v-bind="attrs"
          :rules="rules"
          v-on="on"
          @click:clear="clearDate"
        />
      </template>
      <v-date-picker
        v-model="date"
        no-title
        :min="min"
        :max="max"
        :disabled="disabled"
        @input="menu = false"
      />
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default() {
        return "select_date";
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    filled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: String,
      default() {
        return "";
      },
    },
    min: {
      type: String,
      default() {
        return "";
      },
    },
    max: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      menu: false,
      date: this.value || "",
    };
  },
  watch: {
    date(newValue) {
      this.$emit("input", newValue);
      this.$emit("change", newValue);
    },
    value(newValue) {
      this.date = newValue;
    },
  },
  mounted() {
    this.date = this.value;
  },
  methods: {
    clearDate() {
      this.$emit("input", null);
      this.$emit("change", null);
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .v-text-field--filled:not(.v-text-field--single-line) input
  font-size: 0.8125rem !important
  margin-top: 14px !important

.custom-menu-offset 
  transform: translateY(-25px) !important

</style>
