import service from "@/store/services/csms-locations-service";

const state = {
  loading: false,
  meta: {},
  list: [],
  //
  subLoading: false,
  loc_meta: {},
  locationImgList: [],

  //
  amenities: [],
  amenitiesMeta: {},
  amenitiesList: [],
};

const mutations = {
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  UPDATE_LOCATION(state, row) {
    const item = state.list.find((item) => item.id === row.id);
    Object.assign(item, row);
  },

  DELETE_LOCATION(state, params) {
    state.list.splice(state.list.map((o) => o.id).indexOf(params.id), 1);
  },

  // for Location Images
  SET_LOCATION_IMG_LIST(state, payload) {
    state.locationImgList = payload;
  },
  SET_LOC_META(state, data) {
    if (data.meta) {
      state.loc_meta.page = data.meta.current_page;
      state.loc_meta.itemsPerPage = data.meta.per_page;
      state.loc_meta.lastPage = data.meta.last_page;
      state.loc_meta.totalItems = data.meta.total;
    }
  },
  DELETE_LOCATION_IMG(state, params) {
    state.locationImgList.splice(
      state.locationImgList.map((o) => o.id).indexOf(params.id),
      1
    );
  },
  // for Location Images

  // For Location Amenities
  SET_AMENITIES(state, payload) {
    state.amenities = payload;
  },
  SET_AMENITIES_META(state, data) {
    if (data.meta) {
      state.amenitiesMeta.page = data.meta.current_page;
      state.amenitiesMeta.itemsPerPage = data.meta.per_page;
      state.amenitiesMeta.lastPage = data.meta.last_page;
      state.amenitiesMeta.totalItems = data.meta.total;
    }
  },
  SET_AMENITIES_LIST(state, payload) {
    state.amenitiesList = payload;
  },
  DELETE_AMENITIES(state, params) {
    state.amenitiesList.splice(
      state.amenitiesList.map((o) => o.id).indexOf(params.id),
      1
    );
  },
  SET_LOADING_LIST(state, value) {
    state.loading = value;
  },
  // For Location Amenities
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
  list: (store, params) => {
    store.state.loading = true;
    return service.list(params).then((data) => {
      store.commit("SET_LIST", data.body.data);
      store.commit("SET_META", data.body);
      store.state.loading = false;
    });
  },

  add: (store, { payload, filter }) => {
    return service.add(payload).then(() => {
      // store.commit("ADD_LOCATION", data.body);
      store.dispatch("list", filter);
    });
  },
  update: (store, params) => {
    return service.update(params).then((data) => {
      store.commit("UPDATE_LOCATION", data);
    });
  },
  destroy: (store, params) => {
    return service.destroy(params).then(() => {
      store.commit("DELETE_LOCATION", params);
    });
  },
  //
  // For Location Images
  locationImgList: (store, payload) => {
    store.state.subLoading = true;
    return service.locationImgList(payload).then((data) => {
      store.state.subLoading = true;
      store.commit("SET_LOCATION_IMG_LIST", data.body.data);
      store.commit("SET_LOC_META", data.body);
      store.state.subLoading = false;
    });
  },
  addLocationImage: (store, payload) => {
    return service.addLocationImage(payload).then(() => {
      // store.commit("ADD_LOCATION", data.body);
      store.dispatch("locationImgList", {
        page: 1,
        itemsPerPage: 20,
        ...payload,
      });
    });
  },
  destroyLocationImage: (store, params) => {
    return service.destroyLocationImage(params).then(() => {
      store.commit("DELETE_LOCATION_IMG", params);
    });
  },
  // For Location Images
  //
  // For Location Amenities
  amenities: (store, payload) => {
    store.state.subLoading = true;
    return service.amenities(payload).then((data) => {
      store.commit("SET_AMENITIES", data.ammenities);
      store.state.subLoading = false;
    });
  },
  amenitiesList: (store, payload) => {
    store.state.subLoading = true;
    return service.amenitiesList(payload).then((data) => {
      store.commit("SET_AMENITIES_LIST", data.body.data);
      store.commit("SET_AMENITIES_META", data.body);
      store.state.subLoading = false;
    });
  },
  addAmenities: (store, payload) => {
    return service.addAmenities(payload).then(() => {
      // store.commit("ADD_LOCATION", data.body);
      store.dispatch("amenitiesList", {
        page: 1,
        itemsPerPage: 20,
        ...payload,
      });
    });
  },
  destroyAmenities: (store, params) => {
    return service.destroyAmenities(params).then(() => {
      store.commit("DELETE_AMENITIES", params);
    });
  },
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  getMeta(state) {
    return state.meta;
  },
  getList(state) {
    return state.list;
  },

  // For Location Images
  getSubLoading(state) {
    return state.subLoading;
  },
  getLocMeta(state) {
    return state.loc_meta;
  },
  getLocImgList(state) {
    return state.locationImgList;
  },
  // For Location Images

  // For Location Amenities
  // // For Loading  use getSubloading
  getAmenities(state) {
    return state.amenities;
  },
  getAmenitiesMeta(state) {
    return state.amenitiesMeta;
  },
  getAmenitiesList(state) {
    return state.amenitiesList;
  },
};

const csmsRates = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default csmsRates;
