import service from "../services/route-listing-service";

const state = {
  isLoading: false,
  meta: {},
  list: [],
  //
  routePlan: {},
  compareRouteData: {},
};

const mutations = {
  SET_META(state, payload) {
    if (payload) {
      state.meta.page = payload.current_page;
      state.meta.itemsPerPage = payload.per_page;
      state.meta.lastPage = payload.last_page;
      state.meta.totalItems = payload.total;
    }
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_ROUTE_PLAN(state, payload) {
    state.routePlan = payload;
  },
  DELETE_ROUTE(state, params) {
    state.list.splice(state.list.map((o) => o.id).indexOf(params.id), 1);
  },
  SET_COMPARE_ROUTE(state, payload) {
    state.compareRouteData = payload;
  },
  SET_LOADING_LIST(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
  list: (store, params) => {
    store.state.isLoading = true;
    return service
      .list(params)
      .then((data) => {
        store.commit("SET_META", data.body.meta);
        store.commit("SET_LIST", data.body.data);
      })
      .finally(() => (store.state.isLoading = false));
  },
  add: (store, params) => {
    store.state.isLoading = true;
    return service
      .add(params)
      .then((data) => {
        store.commit("SET_ROUTE_PLAN", data.body);
      })
      .finally(() => (store.state.isLoading = false));
  },
  getOne: (store, params) => {
    store.state.isLoading = true;
    return service
      .getOne(params)
      .then((data) => {
        store.commit("SET_ROUTE_PLAN", data.body);
        return data.body;
      })
      .finally(() => (store.state.isLoading = false));
  },
  update: (store, params) => {
    store.state.isLoading = true;
    return service
      .update(params)
      .then((data) => {
        store.commit("SET_ROUTE_PLAN", data.body);
      })
      .finally(() => (store.state.isLoading = false));
  },
  destroy: (store, params) => {
    return service.destroy(params).then(() => {
      store.commit("DELETE_ROUTE", params);
    });
  },
  //
  compareRoute: (store, params) => {
    store.state.isLoading = true;
    return service
      .compareRoute(params)
      .then((data) => {
        store.commit("SET_COMPARE_ROUTE", data);
      })
      .finally(() => (store.state.isLoading = false));
  },
};

const getters = {
  getIsLoading(state) {
    return state.isLoading;
  },
  getMeta(state) {
    return state.meta;
  },
  getList(state) {
    return state.list;
  },
  //
  getRoutePlan(state) {
    return state.routePlan;
  },
  getCompareRoute(state) {
    return state.compareRouteData;
  },
};

const routeListing = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default routeListing;
