<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <!-- Filter , Action Buttons and Modal -->
    <section>
      <div class="filters">
        <div class="filterDropDown">
          <!-- :cols="filterCols" -->
          <header-filters
            :pre-select-filter="preSelectFilter"
            :bread-crumbs="breadCrumbs"
            :no-filters="noFilters"
            :is-filters-show="isFiltersShow"
            :filter-names="filterNames"
            :is-date-show="showDate"
            :date-option-type="dateOptionType"
            :is-single-date-show="isSingleDateShow"
            :date-selected="dateSelected"
            :date-custom-options="dateCustomOptions"
            :date-validation="dateValidation"
            :search-label="searchLabel"
            :product-options="productOptions"
            :selected-product="selectedProduct"
            :product-disable="productDisable"
            :status-options="statusOptions"
            @onChange="onFiltersChange"
            @closemodal="() => $emit('closemodal')"
          >
            <slot name="addFilters" />
          </header-filters>
        </div>
        <div class="button-modals">
          <slot name="moreFilters" />
          <slot name="actionModals" />
        </div>
      </div>
    </section>
    <!-- Filter , Action Buttons and Modal -->
    <div class="Desc mb-3">
      <slot name="description" />
    </div>
    <div class="tableContainer">
      <!-- Data Table -->
      <section>
        <!--  -->
        <div :class="className">
          <span
            v-if="showHeading"
            class="text-h3"
          >{{ tableHeading }}</span>
          <download-file-buttons
            v-if="items.length > 0 && exportOptions.length > 0"
            :loading="isDownloading"
            :export-options="exportOptions"
            @click="downloadFile"
          />
        </div>
        <slot name="dataTable">
          <v-data-table
            v-model="selectedRows"
            :headers="headers"
            :items="items"
            :loading="isLoading"
            :items-per-page="perPage || meta.itemsPerPage"
            :show-select="multiSelectRows"
            class="elevation-0"
            hide-default-footer
            fixed-header
          >
            <template
              v-if="isLoading"
              v-slot:body
            />
            <!-- :item-class="itemRowBackground" -->
            <!-- @item-selected="whenRowSelect" -->
            <!-- Data Table Scroll Horizontal -->
            <template v-slot:[`footer`]>
              <data-table-scroll :data-table-key="dataTableKey" />
            </template>
            <!-- Data Table Scroll Horizontal -->
            <!-- When No Record  -->
            <template v-slot:no-data>
              <!-- <span
                v-if="
                  (authUser.isAdmin || authUser.user_nature == 'fleet') &&
                  !options.tenant_id
                "
              >
                {{ $t("please_select_a_tenant") }}
              </span>
              <span v-else>
                {{ $t("no_data_available") }}
              </span> -->
              <span>
                {{ $t("no_data_available") }}
              </span>
            </template>
            <!-- When No Record  -->

            <!-- Dynamic Column Slots -->
            <template
              v-for="col in headers"
              v-slot:[`item.${col.value}`]="{ item }"
            >
              <slot
                :name="col.value"
                :item="item"
              >
                {{ item[col.value] }}
              </slot>
            </template>
            <!-- Dynamic Column Slots -->

            <!-- actions -->
            <!-- <template v-slot:[`item.actions`]="{ item }">
              <slot
                name="action"
                :item="item"
              />
            </template> -->
            <!-- actions -->

            <!-- <template v-slot:[`item.assigned`]="{ item }">
              <slot
                name="assigned"
                :item="item"
              >
                {{ item.assigned }}
              </slot>
            </template>

            <template v-slot:[`item.master_switch`]="{ item }">
              <slot
                name="master_switch"
                :item="item"
              >
                {{ item.master_switch }}
              </slot>
            </template> -->
          </v-data-table>
        </slot>
      </section>
      <!-- Pagination -->
      <section
        v-if="items.length > 0"
        class="paginationFooterWrap"
      >
        <pagination
          v-if="meta.page"
          :sub-screen="subScreen"
          :active-page="meta.page"
          :per-page="meta.itemsPerPage"
          :per-page-options="perPageOptions"
          :total-items="meta.totalItems"
          :last-page="meta.lastPage"
          @change="onPaginationChange"
        />
      </section>
      <!-- Pagination -->
    </div>
    <!-- Data Table -->

    <slot />
  </v-container>
</template>
<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";

export default {
  components: {
    HeaderFilters: () => import("./MainFilters.vue"),
    Pagination: () => import("./Pagination.vue"),
    dataTableScroll: () => import("@/components/base/dataTableScroll.vue"),
    downloadFileButtons: () =>
      import("@/components/base/DownloadFileButtons.vue"),
  },
  props: {
    className: {
      type: String,
      default() {
        return "d-flex justify-end";
      },
    },
    showHeading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    tableHeading: {
      type: String,
      default() {
        return "";
      },
    },
    subScreen: {
      type: Number,
      default() {
        return 0;
      },
    },
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    breadCrumbs: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    isDownloading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    noFilters: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isFiltersShow: {
      type: Boolean,
      default() {
        return true;
      },
    },
    // filterCols: {
    //   type: String,
    //   default() {
    //     return "2";
    //   },
    // },
    meta: {
      type: Object,
      default() {
        return {};
      },
    },
    perPage: {
      type: Number,
      default() {
        return 0;
      },
    },
    perPageOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    headers: {
      type: Array,
      default() {
        return {};
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    showDate: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dateOptionType: {
      type: String,
      default() {
        return "date";
      },
    },
    dateType: {
      type: String,
      default() {
        return "";
      },
    },
    isSingleDateShow: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dateSelected: {
      type: String,
      default: () => {
        return "today";
      },
    },
    dateValidation: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dateCustomOptions: {
      type: Boolean,
      default() {
        return true;
      },
    },
    verticalScroll: {
      type: Boolean,
      default() {
        return true;
      },
    },
    preSelectFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
    telematicRows: {
      type: Boolean,
      default() {
        return false;
      },
    },
    telematicMassageRows: {
      type: Boolean,
      default() {
        return false;
      },
    },

    filterNames: {
      type: Array,
      default() {
        return [];
      },
    },
    multiSelectRows: {
      type: Boolean,
      default() {
        return false;
      },
    },
    searchLabel: {
      type: String,
      default() {
        return "chassis_no";
      },
    },
    productOptions: {
      type: Array,
      default() {
        return [
          { id: "oem", value: "OEM" },
          { id: "fleet", value: "FLEET" },
        ];
      },
    },
    selectedProduct: {
      type: String,
      default() {
        return "oem";
      },
    },

    productDisable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    statusOptions: {
      type: Array,
      default() {
        return [
          { id: "active", name: "Active" },
          { id: "inactive", name: "Inactive" },
        ];
      },
    },
    dataTableKey: {
      type: Number,
      default() {
        return 0;
      },
    },
    exportOptions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      firstDrop: false,
      pagination: {},
      selectedRows: [],
      options: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
    }),
  },
  watch: {
    options: {
      handler: debounce(async function () {
        // if (this.isShowFilters) {
        // }
        this.$emit("filterOption", { ...this.options, ...this.pagination });
      }, 500),
      deep: true,
    },
    selectedRows(val) {
      this.$emit("input", val);
    },
    "options.tenant_id": {
      handler: function () {
        this.selectedRows = [];
      },
    },
  },
  mounted() {
    // for Paginations History Maintain
    if (this.subScreen == 0) {
      this.options.page = this.$route?.query?.page ?? 1;
      this.options.itemsPerPage =
        this.$route?.query?.perPage ?? this.perPageOptions[0] ?? 20;
    } else if (this.subScreen == 1) {
      this.options.page = this.$route?.query?.p1 ?? 1;
      this.options.itemsPerPage =
        this.$route?.query?.pp1 ?? this.perPageOptions[0] ?? 20;
    } else {
      this.options.page = 1;
      this.options.itemsPerPage = this.perPageOptions[0] ?? 20;
    }
  },

  methods: {
    onFiltersChange(v) {
      delete this.options.product;
      delete this.options.fleetId;
      delete this.options.is_independent_fleet;
      delete this.options.oem_b2b_ids;
      delete this.options.tenant_id;
      if (this.firstDrop) {
        //for pageination this func invoke as mounted
        this.options.page = 1;
        // this.options.itemsPerPage = 20;
      }
      this.options = { ...this.options, ...v };
      this.firstDrop = true;
    },
    onPaginationChange(val) {
      this.options = { ...this.options, ...val };
    },
    // whenRowSelect() {
    //   setTimeout(() => {
    //     this.$emit("input", this.selectedRows);
    //   }, 100);
    // },
    //
    itemRowBackground(item) {
      let rowBg = "";
      if (this.telematicRows && this.authUser.isAdmin) {
        if (!item.is_synced) {
          rowBg = "invalid-sync-telematics";
        }

        if (item.vehicle_meta !== null) {
          let row = item.vehicle_meta.checks_passed;
          if (row !== undefined) {
            if (
              row.is_bms_check_passed === false ||
              row.is_ecu_check_passed === false ||
              row.is_iot_check_passed === false
            ) {
              rowBg = "invalid-telematics";
            } else if (
              row.is_bms_check_passed === null &&
              row.is_ecu_check_passed === null &&
              row.is_iot_check_passed === null
            ) {
              rowBg = "null-telematics";
            }
          }
        }
        if (item.is_open) {
          rowBg = "modal-active";
        }
      }
      if (this.telematicMassageRows) {
        rowBg = "valid-telematics";

        if (!item.bms_pass || !item.ecu_pass || !item.iot_pass) {
          rowBg = "invalid-telematics";
        }
      }
      return rowBg;
    },
    downloadFile(val) {
      this.$emit("isExport", val);
    },
  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

.listing-top-header
  display: flex
  justify-content: center
  // align-items: baseline
.gap
  gap: 1.5rem

.filters
  display: flex
  margin-bottom: 1rem
  // gap: 1rem
  .filterDropDown
    flex: 1
  :deep .button-modals
    .v-btn
      margin-left: 10px
    // display: flex
    // gap: 1rem
    // margin-bottom: 1rem



@media only screen and (max-width: 640px)
  .filters
    // flex-direction: column
    justify-content: space-between
    .filterDropDown
      flex: 0

    .button-modals
      display: flex
      justify-content: flex-end

.tableContainer
  max-height: calc(100vh - 15px)
  margin-top: 0

  .v-data-table
    max-height: calc(100vh - 177px)
    min-height: calc(100vh - 177px)
    :deep td
      font-size: 0.875rem !important
      font-family: $poppinReg !important
      .v-icon.v-icon
        font-size:18px !important
      .imei-column
        font-family: $poppinReg !important
        a
          font-family: $poppinReg !important
          font-size: 0.875rem !important
      .open-link
        font-family: $poppinReg !important
        a
          font-family: $poppinReg !important
          font-size: 0.875rem !important
      .imei-column, .messageDetail, .open-link, .activeCustomer
        font-family: $poppinReg !important
        font-size: 0.875rem !important
        a
          font-family: $poppinReg !important
          font-size: 0.875rem !important

  .paginationFooterWrap
    display: flex
    flex-wrap: wrap
    align-items: center
    font-size: 0.75rem
    padding: 0 8px
    margin-right: 0 !important
    position: sticky
    z-index: 9
    width: 100%
    top: calc(100vh - 6vh)
    background: #EAECF0
    height: 40px
    .v-data-footer__select
      height: 40px
    .pagination
      width: 100%
      margin-bottom: 0 !important
      :deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot
        min-height: 29px !important
      :deep .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner
        margin-top: 0 !important
        height: 100%
        display: flex
        align-items: center
        width: 18px
      :deep .page-btn div span
        padding: 0.45rem 0.75rem
        font-size: 0.7rem

  :deep .v-data-table__wrapper
    position: relative

  :deep .v-data-table-header tr th
    background: #EAECF0 !important


  :deep .v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense > .v-input__control > .v-input__slot
    min-height: 46px


  :deep .v-data-table-header tr th
    height: 40px !important
</style>