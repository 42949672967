<template>
  <span>
    <Icons
      :name="getIcons"
      :class="status"
    />
  </span>
</template>
<script>
export default {
  components: {
    Icons: () => import("@/components/base/icons.vue"),
  },
  props: {
    name: {
      type: String,
      default() {
        return "bike";
      },
    },
    status: {
      type: String,
      default() {
        return "in_active";
      },
    },
  },
  computed: {
    getIcons() {
      if (this.name == "bike") return "marker-2w";
      else if (this.name == "loader") return "marker-3w";
      else if (["sedan", "hatchback", "suv"].includes(this.name))
        return "marker-4w";
      else if (this.name == "bus") return "marker-bus";
      else if (this.name == "truck") return "marker-truck";
      else return this.name;
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .active svg path
  fill : #23BDAA !important

:deep .in_active, :deep .inactive
    svg path
      fill : #FFAE20 !important

:deep .offline svg path
  fill : #FA896B
</style>
