// import service from "@/store/services/csms-charge-stations-service";
import service from "@/helpers/service";
const state = {
  loading: false,
  meta: {},
  list: [],
};

const mutations = {
  SET_LOADING_LIST(state, value) {
    state.loading = value;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },

  // ADD_LIST(state, payload) {
  //   state.list.push(payload);
  // },

  UPDATE_LIST(state, payload) {
    const item = state.list.find((item) => item.id === payload.id);
    Object.assign(item, payload);
  },

  DELETE_LIST(state, payload) {
    state.list.splice(state.list.map((o) => o.id).indexOf(payload.id), 1);
  },
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
  list: (store, params) => {
    store.state.loading = true;
    service
      .get("staff-management/staff-member", params)
      .then((data) => {
        if (data.body?.length) {
          store.commit("SET_LIST", data.body);
        } else {
          store.commit("SET_META", data.body);
          store.commit("SET_LIST", data.body.data);
        }
        return data.body.data;
      })
      .finally(() => (store.state.loading = false));
  },

  add: (store, { payload, filter }) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return service
      .post("staff-management/staff-member", payload, config)
      .then(() => {
        // store.commit("ADD_LIST", data.body);
        store.dispatch("list", filter);
      });
  },

  update: (store, payload) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return service
      .update("staff-management/staff-member", payload, config)
      .then((data) => {
        store.commit("UPDATE_LIST", data.body);
      });
  },

  destroy: (store, params) => {
    return service.destroy("staff-management/staff-member", params).then(() => {
      store.commit("DELETE_LIST", params);
    });
  },
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  getMeta(state) {
    return state.meta;
  },
  getList(state) {
    return state.list;
  },
};

const drivingStaff = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default drivingStaff;
