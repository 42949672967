<template>
  <div>
    <v-row
      v-if="isRedirect"
      class="py-1"
    >
      <v-col>
        <v-btn
          color="dark"
          text
          small
          @click="redirect()"
        >
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card
      elevation="4"
      :loading="isLoading"
      class=""
    >
      <div class="Customer-Details">
        <div
          v-if="isLoading"
          class="d-flex"
        >
          <v-skeleton-loader
            v-for="i in 3"
            :key="i"
            type="list-item-two-line"
            style="flex: 1"
          />
        </div>
        <div
          v-else
          class="Customer-Details-Info"
          :class="isShow ? 'half-w' : 'full-w'"
        >
          <span class="info-box">
            <v-card-text>
              <table>
                <tr>
                  <td>Name:</td>
                  <td
                    class="link"
                    @click="showUserModal(items)"
                  >
                    <v-chip>
                      {{ items.customer_name }}
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <td>IoT IMEI:</td>
                  <td>
                    <div class="d-flex align-center">
                      <div
                        :class="authUser.isAdmin ? 'link' : ''"
                        @click="authUser.isAdmin ? showIOTModal(items) : ''"
                      >
                        <v-chip>
                          {{ items.iot_device_imei }}
                        </v-chip>
                      </div>
                      <div class="ml-3">
                        <copy-content :content="items.iot_device_imei" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>IoT SIM:</td>
                  <td>
                    <div class="d-flex align-center">
                      <div>
                        <v-chip>
                          {{ items.iot_device_contact }}
                        </v-chip>
                      </div>
                      <div class="ml-3">
                        <copy-content :content="items.iot_device_contact" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Active Since:</td>
                  <td>
                    <v-chip>
                      {{ items.iot_active_since }}
                    </v-chip>
                  </td>
                </tr>
              </table>
            </v-card-text>
          </span>
          <span class="info-box">
            <v-card-text>
              <table>
                <tr>
                  <td>Make & Model :</td>
                  <td
                    :class="items.vehicle_tech !== 'ice' ? 'link' : ''"
                    @click="
                      items.vehicle_tech !== 'ice'
                        ? showMakeModelModal(items)
                        : ''
                    "
                  >
                    <v-chip>
                      {{ items.md_make_name }} /
                      {{ items.md_model_name }}
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <td>Chassis No:</td>
                  <td>
                    <div class="d-flex align-center">
                      <div
                        :class="authUser.isAdmin ? 'link' : ''"
                        @click="authUser.isAdmin ? showVehicleModal(items) : ''"
                      >
                        <v-chip>
                          {{ items.chassis_no }}
                        </v-chip>
                      </div>
                      <div class="ml-3">
                        <copy-content :content="items.chassis_no" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Motor No:</td>
                  <td>
                    <div class="d-flex align-center">
                      <div>
                        <v-chip>
                          {{ items.motor_no }}
                        </v-chip>
                      </div>
                      <div class="ml-3">
                        <copy-content :content="items.motor_no" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="items.vehicle_tech !== 'ice'">
                  <td>Battery Type:</td>
                  <td>
                    <v-chip>
                      {{ items.battery_slot_type }}
                    </v-chip>
                  </td>
                </tr>
              </table>
            </v-card-text>
          </span>
          <span class="info-box">
            <v-card-text>
              <table>
                <tr>
                  <td>Daily Distance Travelled:</td>
                  <td>
                    <v-chip> {{ items.daily_distance_travelled }} KM </v-chip>
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ (items.vehicle_tech == 'ev') ? $t('daily_avg_milage_ev') :
                      $t('daily_avg_milage_ice') }}:</td>
                  <td>
                    <v-chip>
                      {{ items.daily_avg_mileage }} &nbsp;
                      <span v-if="items.vehicle_tech == 'ev'"> KM/KwH</span>
                      <span v-if="items.vehicle_tech == 'ice'"> KM/L</span>
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <td>Total Distance Travelled:</td>
                  <td>
                    <v-chip> {{ items.total_distance_travelled }} KM </v-chip>
                  </td>
                </tr>
                <tr>
                  <td>{{ (items.vehicle_tech == 'ev') ? $t('total_avg_milage_ev') :
                    $t('total_avg_milage_ice') }}:</td>
                  <td>
                    <v-chip>
                      {{ items.total_avg_mileage }} &nbsp;
                      <span v-if="items.vehicle_tech == 'ev'"> KM/KwH</span>
                      <span v-if="items.vehicle_tech == 'ice'"> KM/L</span>
                    </v-chip>
                  </td>
                </tr>

                <tr v-if="tenantID == 1">
                  <td>CO2 Saving:</td>
                  <td>
                    <v-chip> {{ items.carbon_emission_saving }} KGs </v-chip>
                  </td>
                </tr>
              </table>
            </v-card-text>
          </span>
        </div>
        <div
          v-if="!isLoading"
          class="Toggle-btn"
        >
          <v-btn
            color="primary"
            fab
            small
            @click="isShow = !isShow"
          >
            <v-icon v-if="isShow">
              mdi-chevron-up
            </v-icon>
            <v-icon v-else>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>

    <!-- View Modals -->
    <section>
      <!-- v-if="$admin.can('users.edit')" -->
      <edit-user
        v-if="editUserModal"
        :is-visible-dialog="editUserModal"
        :read-only="true"
        :toggle-edit="toggleUserModal"
        :oem-customer="selectedUser"
      />
      <!-- v-if="$admin.can('iot-devices.edit')" -->
      <EditDevice
        v-if="editDeviceModal"
        :is-visible-dialog="editDeviceModal"
        :read-only="true"
        :toggle-edit="toggleIOTModal"
        :iot-device="selectedUser"
      />
      <!-- v-if="$admin.can('vehicles.edit')" -->
      <edit-vehicle
        :is-visible-dialog="editVehicleModal"
        :read-only="true"
        :toggle-edit="toggleVehicleModal"
        :vehicle="selectedUser"
      />
      <!-- Make / Model -->
      <view-make-model
        v-if="isShowMakeAndModel"
        :is-visible-dialog="isShowMakeAndModel"
        :toggle-modal="toggleMakeModelModal"
        :vehicle="selectedUser"
      />

      <!-- Make / Model -->
    </section>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {
    EditUser: () =>
      import("@/views/modules/customer/dialogs/EditOemCustomer.vue"),
    EditDevice: () =>
      import("@/views/modules/iot-devices/dialogs/EditIotDevice.vue"),
    EditVehicle: () =>
      import("@/views/modules/vehicles/dialogs/EditVehicleTabs.vue"),
    ViewMakeModel: () => import("../base/ViewMakeAndModel.vue"),
    CopyContent: () => import("@/components/base/CopyContent.vue"),
  },
  props: {
    isRedirect: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      isShow: false,
      selectedUser: {},
      editUserModal: false,
      editDeviceModal: false,
      editVehicleModal: false,
      isShowMakeAndModel: false,
      tab: 0,
      tabOptions: [
        { tab: this.$t("view_make"), content: "make" },
        { tab: this.$t("view_model"), content: "model" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      items: "vehicles/getVehicle",
      isLoading: "vehicles/isLoadingVehicle",
    }),
    tenantID() {
      return this.$route.query.tenant_id;
    },
  },
  async beforeMount() {
    const imei = this.$route.params.imei;
    await this.$store.dispatch("vehicles/show", imei);
  },
  methods: {
    showUserModal(item) {
      this.selectedUser = { ...item.customer };
      this.toggleUserModal();
    },
    toggleUserModal() {
      return (this.editUserModal = !this.editUserModal);
    },
    showIOTModal(item) {
      this.selectedUser = { ...item.iotDevice };
      this.toggleIOTModal();
    },
    toggleIOTModal() {
      return (this.editDeviceModal = !this.editDeviceModal);
    },
    showVehicleModal(item) {
      this.selectedUser = { ...item };
      this.toggleVehicleModal();
    },
    toggleVehicleModal() {
      return (this.editVehicleModal = !this.editVehicleModal);
    },

    showMakeModelModal(item) {
      this.selectedUser = { ...item };
      this.toggleMakeModelModal();
    },
    toggleMakeModelModal() {
      this.isShowMakeAndModel = !this.isShowMakeAndModel;
    },
    redirect() {
      this.$emit("redirect");
    },
  },
};
</script>
<style lang="sass" scoped>
.Customer-Details
  position:  relative
  .Toggle-btn
    position: absolute
    width: 100%
    display: flex
    justify-content: center
    bottom: -1.5rem
    z-index: 8
.Customer-Details-Info
  display: flex
  justify-content: space-between
  gap: 1rem
  padding: 1rem 2rem
  flex-wrap: wrap
  -webkit-flex-wrap: wrap
  // max-height: fit-content
  // overflow: hidden
  // transition: all 1s linear
  .info-box
    flex: 1
    max-height: 70px
    overflow: hidden
    transition: all 1s linear
    margin: 0.5rem 0
    text-wrap: nowrap
    .v-card__text
      padding: 0
      table
        width:100%
        font-size: 12px
      tr td:first-child
        width: 30px
        color: gray
      tr td:last-child
        font-weight: 600
  li
    list-style: none
    padding: 5px
    width: 155px
    label
      font-size: 11px
      color: gray
    p
      font-weight: bold

.half-w
  .info-box
    max-height: 50vh

:deep .v-chip__content
  font-size: 11px !important
  font-weight: 600

.link
  .v-chip
    cursor: pointer
  &:hover
    .v-chip
      background: #23BDAA !important
      color: white !important

@media only screen and (max-width: 960px)
  .Customer-Details-Info
    flex-direction: column
    .info-box
      tr td:last-child
        display: flex
        justify-content: flex-end
</style>