<template>
  <div class="DateDropDown">
    <v-card>
      <v-select
        v-model="selectedDate"
        filled
        :dense="dense"
        :items="dateOptions"
        item-text="name"
        item-value="id"
        :clearable="!defaultSelected"
        :label="$t('select_date')"
        @change="onDateChange"
      />
    </v-card>
    <v-card v-if="selectedDate == 'custom'">
      <date-time
        v-if="dateOptionType == 'datetime'"
        v-model="dateFrom"
        :label="$t('from')"
        :min="validationOneMonth()"
        :max="dateTo ? dateTo : currentDate()"
        @change="isCheckRange"
      />
      <v-menu
        v-else-if="dateOptionType == 'date'"
        v-model="isDateFrom"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        content-class="custom-menu-offset"
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- v-model="dateFrom" -->
          <v-text-field
            :value="formatedDateFrom"
            :label="$t('from')"
            readonly
            :dense="dense"
            v-bind="attrs"
            clearable
            filled
            @click:clear="() => (dateFrom = null)"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="dateFrom"
          :min="validationOneMonth()"
          :max="dateTo ? dateTo : currentDate()"
          no-title
          @change="isCheckRange"
        />
      </v-menu>
    </v-card>
    <v-card v-if="selectedDate == 'custom'">
      <date-time
        v-if="dateOptionType == 'datetime'"
        v-model="dateTo"
        :label="$t('to')"
        :min="dateFrom ? dateFrom : validationOneMonth()"
        :max="currentDate()"
        @change="isCheckRange"
      />
      <v-menu
        v-else-if="dateOptionType == 'date'"
        v-model="isDateTo"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        content-class="custom-menu-offset"
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- v-model="dateTo" -->
          <v-text-field
            :value="formatedDateTo"
            :label="$t('to')"
            :dense="dense"
            readonly
            v-bind="attrs"
            clearable
            filled
            @click:clear="() => (dateTo = null)"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="dateTo"
          :min="dateFrom ? dateFrom : validationOneMonth()"
          :max="validationForToDate()"
          no-title
          @change="isCheckRange"
        />
      </v-menu>
    </v-card>
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {
    DateTime: () => import("@/components/base/DateTime.vue"),
  },
  props: {
    dateRange: {
      type: [Array, null],
      default: () => {
        return [];
      },
    },

    dense: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    dateOptionType: {
      type: String,
      default() {
        return "date";
      },
    },
    dateType: {
      type: String,
      default() {
        return "";
      },
    },
    custom: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    defaultSelected: {
      type: String,
      default: () => {
        return "today";
      },
    },
    validateDateLimit: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      selectedDate: "",
      dateFrom: "",
      dateTo: "",
      isDateFrom: false,
      isDateTo: false,
      setting: true,
      // dateOptions: [
      //   {
      //     id: "today",
      //     name: "Today",
      //   },
      //   {
      //     id: "yesterday",
      //     name: "Yesterday",
      //   },
      //   {
      //     id: "last_3_days",
      //     name: "Last 3 days",
      //   },
      //   {
      //     id: "last_7_days",
      //     name: "Last 7 days",
      //   },
      //   {
      //     id: "last_14_days",
      //     name: "Last 14 days",
      //   },
      //   this.validateDateLimit
      //     ? {
      //         id: "last_30_days",
      //         name: "Last 30 days",
      //       }
      //     : null,
      //   // this.custom
      //   //   ? {
      //   //       id: "last_14_days",
      //   //       name: "Last 14 days",
      //   //     }
      //   //   : null,
      //   // this.custom
      //   //   ? {
      //   //       id: "last_30_days",
      //   //       name: "Last 30 days",
      //   //     }
      //   //   : null,
      //   this.custom
      //     ? {
      //         id: "custom",
      //         name: "Custom",
      //       }
      //     : null,
      // ],
    };
  },
  computed: {
    formatedDateFrom() {
      return this.dateFrom && moment(this.dateFrom).format("DD-MMM-YYYY");
    },
    formatedDateTo() {
      return this.dateTo && moment(this.dateTo).format("DD-MMM-YYYY");
    },
    dateOptions() {
      return [
        {
          id: "today",
          name: "Today",
        },
        {
          id: "yesterday",
          name: "Yesterday",
        },
        {
          id: "last_3_days",
          name: "Last 3 days",
        },
        !this.validateDateLimit
          ? {
              id: "last_7_days",
              name: "Last 7 days",
            }
          : null,
        !this.validateDateLimit
          ? {
              id: "last_14_days",
              name: "Last 14 days",
            }
          : null,
        !this.validateDateLimit
          ? {
              id: "last_30_days",
              name: "Last 30 days",
            }
          : null,
        // this.custom
        //   ? {
        //       id: "last_14_days",
        //       name: "Last 14 days",
        //     }
        //   : null,
        // this.custom
        //   ? {
        //       id: "last_30_days",
        //       name: "Last 30 days",
        //     }
        //   : null,
        this.custom
          ? {
              id: "custom",
              name: "Custom",
            }
          : null,
      ];
    },
  },
  async mounted() {
    const dd = this.$route.query?.dateRange;
    const dt = this.$route.query?.dateType;
    // this.selectedDate = dt ? dt : this.defaultSelected ? "today" : "";
    this.selectedDate = dt ? dt : this.defaultSelected || "";
    this.dateFrom = dd ? dd[0] : null;
    this.dateTo = dd ? dd[1] : null;
    await this.onDateChange(this.selectedDate);
  },
  beforeDestroy() {
    this.setting = false;
  },
  methods: {
    currentDate() {
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;

      day = day < 10 ? "0" + day : day;
      month = month < 10 ? `0${month}` : month;
      let year = date.getFullYear();
      // This arrangement can be altered based on how we want the date's format to appear.
      let cDate = `${year}-${month}-${day}`;
      return cDate;
    },
    validationOneMonth() {
      if (this.validateDateLimit && this.dateTo) {
        const fromDate = new Date(this.dateTo);
        const toDateLimit = new Date(fromDate);
        toDateLimit.setDate(fromDate.getDate() - 2); // Add 4 days to 'From' date
        return this.formatDate(toDateLimit);
      } else return;
    },

    validationForToDate() {
      if (this.validateDateLimit && this.dateFrom) {
        const fromDate = new Date(this.dateFrom);
        const toDateLimit = new Date(fromDate);
        toDateLimit.setDate(fromDate.getDate() + 2); // Add 4 days to 'From' date
        return this.formatDate(toDateLimit);
      } else {
        return this.currentDate(); // Default to current date
      }
    },

    onDateChange(value) {
      if (value == null) {
        this.setting = false;
      }
      this.$emit("key", value);
      if (value == "custom") {
        this.isCheckRange();
      } else {
        this.dateFrom = null;
        this.dateTo = null;

        const filterDate = this.customizedDate(value);
        this.$emit("input", filterDate);
        this.$emit("change", filterDate);
      }
    },
    isCheckRange() {
      if (this.dateFrom && this.dateTo) {
        this.$emit("input", [this.dateFrom, this.dateTo]);
        this.$emit("change", [this.dateFrom, this.dateTo]);
      }
    },
    customizedDate(filter) {
      const today = new Date();
      const currentDate = new Date(today);
      const tdate = this.formatDate(currentDate);
      if (filter === "today") {
        return [tdate, tdate];
      } else if (filter === "yesterday") {
        currentDate.setDate(today.getDate() - 1);
        const ydate = this.formatDate(currentDate);
        return [ydate, ydate];
      } else if (filter === "last_3_days") {
        currentDate.setDate(today.getDate() - 2); // Go back 2 more days for a total of 3 days
        return [this.formatDate(currentDate), tdate];
      } else if (filter === "last_7_days") {
        currentDate.setDate(today.getDate() - 6); // Go back 6 more days for a total of 7 days
        return [this.formatDate(currentDate), tdate];
      } else if (filter === "last_14_days") {
        currentDate.setDate(today.getDate() - 13); // Go back 13 more days for a total of 14 days
        return [this.formatDate(currentDate), tdate];
      } else if (filter === "last_30_days") {
        currentDate.setDate(today.getDate() - 29); // Go back 29 more days for a total of 30 days
        return [this.formatDate(currentDate), tdate];
      } else {
        return null; // Invalid filter
      }
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      // return `${year}-${month}-${day}`;
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style lang="sass" scoped>
.DateDropDown
  display: flex
  gap: 1rem
  flex-wrap: wrap

.top-filters
  :deep .v-text-field--filled:not(.v-text-field--single-line) input
    margin-top: 14px

.custom-menu-offset
  transform: translateY(-25px) !important
</style>
