import Swal from "sweetalert2";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  timer: 3000,
  timerProgressBar: false,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const state = {};

const mutations = {};

const actions = {
  success(store, message) {
    Toast.fire({
      title: message,
      icon: "success",
    });
  },

  error(store, message) {
    Toast.fire({
      title: message,
      icon: "error",
    });
  },

  info(store, message) {
    Toast.fire({
      title: message,
      icon: "info",
    });
  },
};

const getters = {};

const alerts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default alerts;
