<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <span class="text-h3">{{ $t("summary") }}</span>
      <download-file-button
        v-if="items.length > 0"
        :loading="isDownloading"
        @click="downloadFile"
      />
    </div>

    <div class="tableContainer">
      <!-- Data Table -->
      <section>
        <slot name="dataTableSummary">
          <v-data-table
            v-model="selectedRows"
            :headers="headers"
            :items="items"
            :loading="isLoading"
            :items-per-page="pageCount"
            class="elevation-0"
            hide-default-footer
            fixed-header
          >
            <template v-slot:no-data>
              <span>
                {{ $t("no_data_available") }}
              </span>
            </template>
            <!-- <template v-slot:[`footer`] name="summary">
              <data-table-scroll v-if="items.length > 0" :data-table-key="1" />
            </template> -->
          </v-data-table>
        </slot>
      </section>
      <!-- Pagination -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {
    downloadFileButton: () =>
      import("@/components/base/DownloadFileButtons.vue"),
    // dataTableScroll: () => import("@/components/base/dataTableScroll.vue"),
  },
  props: {
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    headers: {
      type: Array,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectedRows: [],
    }
  },
  computed: {
    ...mapGetters({
      isDownloading: "journeyReports/getIsSummaryExport",
    }),
    pageCount() {
      return (this.items.length) ? this.items.length : 1000
    }
  },
  methods: {
    downloadFile(val) {
      this.$emit("exportSummary", val);
    },
    scrollLeft() {
      document
        .getElementsByClassName("content-wrapper")[0]
        .scrollTo({ left: 0, behavior: "smooth" });
    },
    scrollRight() {
      const t = document.getElementsByClassName("content-wrapper")[0];
      // const w = t.offsetWidth;
      const sl = t.scrollLeft + 250;
      t.scrollTo({ left: sl, behavior: "smooth" });
    },
  },
};
</script>

<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

.listing-top-header
  display: flex
  justify-content: center
  // align-items: baseline
.gap
  gap: 1.5rem

.filters
  display: flex
  margin-bottom: 1rem
  // gap: 1rem
  .filterDropDown
    flex: 1
  :deep .button-modals
    .v-btn
      margin-left: 10px
    // display: flex
    // gap: 1rem
    // margin-bottom: 1rem



@media only screen and (max-width: 640px)
  .filters
    // flex-direction: column
    justify-content: space-between
    .filterDropDown
      flex: 0

    .button-modals
      display: flex
      justify-content: flex-end

.tableContainer
  max-height: 70vh
  margin-top: 0
  overflow-y: auto

  .v-data-table
    max-height: calc(70vh - 50px)
    min-height: auto
    overflow-y: auto
    :deep td
      font-size: 0.875rem !important
      font-family: $poppinReg !important
      .v-icon.v-icon
        font-size:18px !important
      .imei-column
        font-family: $poppinReg !important
        a
          font-family: $poppinReg !important
          font-size: 0.875rem !important
      .open-link
        font-family: $poppinReg !important
        a
          font-family: $poppinReg !important
          font-size: 0.875rem !important
      .imei-column, .messageDetail, .open-link, .activeCustomer
        font-family: $poppinReg !important
        font-size: 0.875rem !important
        a
          font-family: $poppinReg !important
          font-size: 0.875rem !important

  .paginationFooterWrap
    display: flex
    flex-wrap: wrap
    align-items: center
    font-size: 0.75rem
    padding: 0 8px
    margin-right: 0 !important
    position: sticky
    z-index: 9
    width: 100%
    top: calc(100vh - 6vh)
    background: #EAECF0
    height: 40px
    .v-data-footer__select
      height: 40px
    .pagination
      width: 100%
      margin-bottom: 0 !important
      :deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot
        min-height: 29px !important
      :deep .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner
        margin-top: 0 !important
        height: 100%
        display: flex
        align-items: center
        width: 18px
      :deep .page-btn div span
        padding: 0.45rem 0.75rem
        font-size: 0.7rem

  :deep .v-data-table__wrapper
    position: relative

  :deep .v-data-table-header tr th
    background: #EAECF0 !important


  :deep .v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense > .v-input__control > .v-input__slot
    min-height: 46px


  :deep .v-data-table-header tr th
    height: 40px !important
</style>


<style scoped>
.content-wrapper::-webkit-scrollbar {
  display: none !important;
  /* width: 12px; */
}

/* .content-wrapper::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

    .content-wrapper::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    } */
</style>