import service from "@/helpers/service";
const state = {
  loading: false,
  meta: {},
  list: [],
};

const mutations = {
  SET_META(state, data = { meta: null }) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    } else {
      state.meta = {};
    }
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING", value);
  },
  list: (store, params) => {
    store.state.loading = true;
    service.get("vehicle/customer-vehicles", params).then((data) => {
      store.commit("SET_META", data.body);
      store.commit("SET_LIST", data.body.data);
      store.state.loading = false;
    });
  },
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  getMeta(state) {
    return state.meta;
  },
  getList(state) {
    return state.list;
  },
};

const vehicleTelematics = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default vehicleTelematics;
