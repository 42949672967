import service from "@/store/services/batterries-service";

const state = {
  isLoadingBatterries: false,
  batterriesList: [],
  batterriesItem: {},
  meta: {},
};

const mutations = {
  SET_LOADING_LIST(state, value) {
    state.isLoadingBatterries = value;
  },
  SET_BATTERIES(state, batteries) {
    state.batterriesList = batteries;
  },
  SET_BATTERIES_ITEM(state, batteries) {
    state.batterriesItem = batteries;
  },

  ADD_BATTERY(state, battery) {
    state.batterriesList.push(battery);
  },

  UPDATE_BATTERY(state, battery) {
    const item = state.batterriesList.find((item) => item.id === battery.id);
    Object.assign(item, battery);
  },

  DELETE_BATTERY(state, battery) {
    state.batterriesList.splice(
      state.batterriesList.map((o) => o.id).indexOf(battery.id),
      1
    );
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
  list: (store, params) => {
    store.state.isLoadingBatterries = true;
    service
      .list(params)
      .then((data) => {
        store.commit("SET_BATTERIES", data.body.data);
        store.commit("SET_META", data.body);
      })
      .finally(() => (store.state.isLoadingBatterries = false));
  },
  show: (store, params) => {
    store.state.isLoadingBatterries = true;
    service
      .show(params)
      .then((data) => {
        store.commit("SET_BATTERIES_ITEM", data.body);
      })
      .finally(() => (store.state.isLoadingBatterries = false));
  },

  add: (store, { battery, filter }) => {
    store.state.isLoadingBatterries = true;
    return service
      .add(battery)
      .then((battery) => {
        store.commit("ADD_BATTERY", battery.body);
        store.dispatch("list", filter);
      })
      .finally(() => (store.state.isLoadingBatterries = false));
  },

  update: (store, battery) => {
    store.state.isLoadingBatterries = true;
    return service
      .update(battery)
      .then((battery) => {
        store.commit("UPDATE_BATTERY", battery);
      })
      .finally(() => (store.state.isLoadingBatterries = false));
  },

  destroy: (store, battery) => {
    store.state.isLoadingBatterries = true;
    return service
      .destroy(battery)
      .then(() => {
        store.commit("DELETE_BATTERY", battery);
      })
      .finally(() => (store.state.isLoadingBatterries = false));
  },
};

const getters = {
  isLoadingBatterries(state) {
    return state.isLoadingBatterries;
  },

  batterriesList(state) {
    return state.batterriesList;
  },
  batterriesItem(state) {
    return state.batterriesItem;
  },

  meta(state) {
    return state.meta;
  },
};

const batteries = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default batteries;
