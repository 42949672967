import service from "@/helpers/service";

const state = {
  isLoading: false,
  meta: {},
  list: [],
  //

  //
};

const mutations = {
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_LOADING_LIST(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
  list: (store, params) => {
    store.state.isLoading = true;
    return service
      .get("alerts-and-notification/notifications", params)
      .then((data) => {
        store.commit("SET_META", data.body);
        store.commit("SET_LIST", data.body.data);
        return data.body;
      })
      .finally(() => (store.state.isLoading = false));
  },
  // notifyList: (store, params) => {
  //   store.state.isLoading = true;
  //   return service
  //     .get("alerts-and-notification/notifications", params)
  //     .then((data) => {
  //       store.commit("SET_NOTIFY_LIST", data.body);
  //       return data.body;
  //     })
  //     .finally(() => (store.state.isLoading = false));
  // },
  // add: (store, params) => {
  //   store.state.isLoading = true;
  //   return service
  //     .post("alerts-and-notification/alerts", params)
  //     .then((data) => {
  //       return data.body;
  //     })
  //     .finally(() => (store.state.isLoading = false));
  // },
};

const getters = {
  getLoading(state) {
    return state.isLoading;
  },
  getMeta(state) {
    return state.meta;
  },
  getList(state) {
    return state.list;
  },
};

const notifications = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default notifications;
