<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      no-filters
      @filterOption="onFiltersChange"
    >
      <template v-slot:pickup_started_at="{ item }">
        <div>{{ item.pickup_started_at | formatDate }} </div>
      </template>
      <template v-slot:pickup_completed_at="{ item }">
        <div>{{ item.pickup_completed_at | formatDate }} </div>
      </template>
      <template v-slot:dropoff_started_at="{ item }">
        <div>{{ item.dropoff_started_at | formatDate }}</div>
      </template>
      <template v-slot:dropoff_completed_at="{ item }">
        <div>{{ item.dropoff_completed_at | formatDate }} </div>
      </template>
      <template v-slot:destination_address="{ item }">
        <div
          class="text-truncated"
          :title="item.destination_address"
        >
          {{ item.destination_address }}
        </div>
      </template>
      <template v-slot:origin_address="{ item }">
        <div
          class="text-truncated"
          :title="item.origin_address"
        >
          {{ item.origin_address }}
        </div>
      </template>
      <!-- add Filters -->
      <template #addFilters>
        <v-card>
          <v-select
            v-model="status"
            filled
            dense
            clearable
            :items="statusList"
            item-value="id"
            item-text="name"
            :label="$t('order_status')"
            @change="fetchData"
          />
        </v-card>
        <v-card v-if="authUser.isAdmin">
          <v-select
            v-model="merchant_name"
            filled
            dense
            clearable
            :items="merchantList"
            item-value="id"
            item-text="name"
            :label="$t('merchant_name')"
            @change="fetchData"
          />
        </v-card>
        <div>
          <refresh-button
            :is-loading="isLoading"
            @reload="fetchData"
          />
        </div>
      </template>
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    refreshButton: () => import("../../../components/base/RefreshButton.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      intervalId: null,
      subFilters: {},
      merchant_name: "",
      status: "",
      headers: [
        {
          text: this.$t("order_id"),
          align: "left",
          sortable: false,
          value: "order_id",
        },
        {
          text: this.$t("code"),
          align: "left",
          sortable: false,
          value: "merchant_code",
        },
        {
          text: this.$t("merchant_name"),
          align: "left",
          sortable: false,
          value: "merchant_name",
          showAdmin: true,
        },

        {
          text: this.$t("order_status"),
          align: "left",
          sortable: false,
          value: "order_status",
        },
        {
          text: this.$t("customer_name"),
          align: "left",
          sortable: false,
          value: "consignee_name",
        },
        {
          text: this.$t("customer_phone"),
          align: "left",
          sortable: false,
          value: "consignee_phoneno",
        },
        {
          text: this.$t("origin_address"),
          align: "left",
          sortable: false,
          value: "origin_address",
        },
        {
          text: this.$t("destination_address"),
          align: "left",
          sortable: false,
          value: "destination_address",
        },
        {
          text: this.$t("service_type"),
          align: "left",
          sortable: false,
          value: "service_type",
        },
        {
          text: this.$t("pickup_started_at"),
          align: "left",
          sortable: false,
          value: "pickup_started_at",
        },
        {
          text: this.$t("pickup_completed_at"),
          align: "left",
          sortable: false,
          value: "pickup_completed_at",
        },
        {
          text: this.$t("dropoff_started_at"),
          align: "left",
          sortable: false,
          value: "dropoff_started_at",
        },
        {
          text: this.$t("dropoff_completed_at"),
          align: "left",
          sortable: false,
          value: "dropoff_completed_at",
        },
        {
          text: this.$t("payment_billing"),
          align: "left",
          sortable: false,
          value: "payment_type",
        },
        {
          text: this.$t("driver_name"),
          align: "left",
          sortable: false,
          value: "driver_name",
        },
        {
          text: this.$t("driver_phone"),
          align: "left",
          sortable: false,
          value: "driver_phoneno",
        },
      ],
      statusList: [
        {
          id: "created",
          name: "Created",
        },
        {
          id: "pickup_started",
          name: "Pickup Started",
        },
        {
          id: "pickup_done",
          name: "Pickup Done",
        },
        {
          id: "dropoff_started",
          name: "Dropoff Started",
        },
        {
          id: "dropoff_done",
          name: "Dropoff Done",
        },
      ],
      merchantList: [
        {
          id: "BEMCC0030",
          name: "Al-Khaleej Pharmacy Safoora",
        },
        {
          id: "AAAA1",
          name: "Bin-Hashim Jauha",
        },
        {
          id: "BBBB1",
          name: "Dvago Bahdurabad",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "order/getIsLoading",
      meta: "order/meta",
      list: "order/getOrderList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      if (this.authUser.isAdmin) {
        return [];
      } else {
        return [];
      }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  mounted() {
    // 5 * 60 * 1000
    this.$store.dispatch('order/setLoadingValue', true)
    this.fetchData();
    this.intervalId = setInterval(this.fetchData, 5 * 60 * 1000); // 5 minutes
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    async fetchData() {
      this.objOptions = { ...this.options }
      this.objOptions.order_status = this.status;
      this.objOptions.merchant_name = this.merchant_name;
      delete this.objOptions.product
      delete this.objOptions.currency
      await this.$store.dispatch("order/list", this.objOptions);
    },
  },
};
</script>
<style scoped>
.text-truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  /* Adjust the width as per your design */
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  /* Optional, to indicate interactivity on hover */
}
</style>