<template>
  <div class="relative copy-content">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            class="copy-content-icon"
            small
            @click="clickToCopy"
          >
            mdi-content-copy
          </v-icon>
        </span>
      </template>
      <span>{{ content }}</span>
    </v-tooltip>

    <textarea
      ref="myText"
      :value="content"
      readonly
      style="position: absolute; left: -9999px;"
    />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  methods: {
    clickToCopy() {
      const textarea = this.$refs.myText; // Access the textarea element
      textarea.select(); // Select the text
      document.execCommand("copy"); // Copy the text
      textarea.setSelectionRange(0, 0); // Deselect the text
    },
  },
};
</script>
<style lang="sass" scoped>
.copy-content-icon
  cursor: pointer
  position: absolute
  display: flex
  justify-content: center
  width: 100%
  align-items: center
  height: 100%
  &:hover
    color: #20a390 !important
</style>