<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :product-options="productOptions"
      :filter-names="filterNames"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:id="{ item }">
        <div class="d-flex align-center">
          <status-badge :status="item.connectivity_status" />
          <span>
            {{ item.charge_point_id }}
            {{ item.connector_id ? `/${item.connector_id}` : "" }}
          </span>
        </div>
      </template>
      <template v-slot:power_type="{ item }">
        <span class="text-uppercase">{{ item.power_type }}</span>
      </template>
      <template v-slot:connector_state="{ item }">
        <span class="text-capitalize">
          {{
            item.connectivity_status == "offline"
              ? "offline"
              : item.connector_state
          }}
        </span>
      </template>
      <template v-slot:remote_operations="{ item }">
        <!-- item.connectivity_status == 'online' -->
        <span v-if="item.connectivity_status == 'online'">
          <v-btn
            v-if="['Available', 'Preparing'].includes(item.connector_state)"
            color="primary"
            small
            @click="editItem(item)"
          >
            {{ $t("remote_start_transaction ") }}
          </v-btn>
          <v-btn
            v-else-if="['Charging'].includes(item.connector_state)"
            color="danger"
            small
          >
            {{ $t("remote_stop_transaction ") }}
          </v-btn>
        </span>
      </template>

      <template #addFilters>
        <v-card>
          <v-select
            v-model="location"
            filled
            dense
            clearable
            :items="locationsList"
            item-value="id"
            item-text="name"
            :label="$t('location')"
            @change="fetchData"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="connector_state"
            filled
            dense
            clearable
            :items="stateList"
            item-value="id"
            item-text="name"
            :label="$t('connector_state')"
            @change="fetchData"
          />
        </v-card>
      </template>
      <template #moreFilters>
        <div>
          <refresh-button
            :is-loading="isLoading"
            @reload="fetchData"
          />
        </div>
      </template>
      <template #actionModals>
        <start-transtions
          v-if="editModal"
          :is-visible-dialog="editModal"
          :toggle-edit="toggleEditModal"
          :customer-data="selectedRow"
          @change="fetchData"
        />
      </template>
      <!-- Action Dialog -->
      <!-- <v-dialog v-model="dialog" max-width="800px">
        <start-transtions :customer-data="selectedRow"/>
      </v-dialog> -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";

import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    StartTranstions: () => import("./utils/StartTranstions.vue"),
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    refreshButton: () =>
      import("../../../../components/base/RefreshButton.vue"),
    StatusBadge: () => import("@/components/base/Status-Badge.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      customerAdd: true,
      tableView: false,
      dialog: false,
      searchQuery: "",
      selectedCustomer: null,
      customerData: [],
      headers: [
        { text: "", value: "checkbox", align: "start" },
        { text: "Customer Name", value: "name" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Vehicle", value: "vehicle" },
        { text: "Booking Time", value: "bookingTime" },
      ],

      filters: {},
      subFilters: {},
      connector_state: "",
      location: "",
      productOptions: [
        { id: "oem", value: "OEM" },
        { id: "cso", value: "CSO" },
      ],
      stateList: [
        {
          id: "Available",
          name: "Available",
        },
        {
          id: "Unavailable",
          name: "Unavailable",
        },
        {
          id: "Charging",
          name: "Charging",
        },
        {
          id: "Preparing",
          name: "Preparing",
        },
        {
          id: "Finishing",
          name: "Finishing",
        },
        {
          id: "Accepted",
          name: "Accepted",
        },
        {
          id: "Faulted",
          name: "Faulted",
        },
        {
          id: "Reserved",
          name: "Reserved",
        },
        {
          id: "Suspended EV",
          name: "Suspended EV",
        },
        {
          id: "Suspended EVSE",
          name: "Suspended EVSE",
        },
      ],
      isMounted: false,
      itemData: {},
      interval: null,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsChargeStations/getLoading",
      meta: "csmsChargeStations/getConnectorMeta",
      list: "csmsChargeStations/getConnectorlist",
      locationsList: "csmsChargeStations/getLocation",
    }),
    _headers() {
      const headers = [
        {
          text: this.$t("connector_id"),
          align: "left",
          sortable: true,
          value: "id",
        },

        {
          text: this.$t("tenant_name"),
          align: "left",
          sortable: false,
          value: "tenant_name",
        },
        {
          text: this.$t("location"),
          align: "left",
          sortable: false,
          value: "location_name",
        },
        // {
        //   text: this.$t("charge_stations"),
        //   align: "left",
        //   sortable: false,
        //   value: "charge_station",
        // },
        {
          text: this.$t("connector_type"),
          align: "left",
          sortable: false,
          value: "connector_type",
        },
        {
          text: this.$t("connector_format"),
          align: "left",
          sortable: false,
          value: "connector_format",
        },
        {
          text: this.$t("power_type"),
          align: "left",
          sortable: false,
          value: "power_type",
        },
        // {
        //   text: this.$t("power"),
        //   align: "left",
        //   sortable: false,
        //   value: "power",
        // },
        {
          text: this.$t("connector_state"),
          align: "left",
          sortable: false,
          value: "connector_state",
        },
        // {
        //   text: this.$t("unavailability_reason"),
        //   align: "left",
        //   sortable: false,
        //   value: "unavailability_reason",
        // },
        {
          text: this.$t("last_updated_at"),
          align: "left",
          sortable: false,
          value: "updated_at",
        },
        {
          text: this.$t("remote_operations"),
          align: "left",
          sortable: false,
          value: "remote_operations",
        },
      ];
      return headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
    transactionHeader() {
      const headers = [
        { text: '', value: 'checkbox', align: 'start' },
        { text: 'Customer Name', value: 'name' },
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Vehicle', value: 'vehicle' },
        { text: 'Booking Time', value: 'bookingTime' },
      ];
      return headers;
    },
    filterNames() {
      return [""];
    },
  },
  watch: {
    "options.tenant_id": {
      handler: function (val) {
        if (this.isMounted) {
          // this.customFilters = {};
          this.location = "";
          this.connector_state = "";
        }
        this.isMounted = true;
        if (val) {
          const params = { tenant_id: val };
          this.$store.dispatch("csmsChargeStations/LocationsList", params);
        } else {
          this.$store.commit("csmsChargeStations/SET_LOCATION", []);
        }
      },
    },
    options() {
      this.fetchData();
    },
  },
  async mounted() {
    if (!this.authUser.isAdmin) {
      const params = { tenant_id: this.authUser.tenant_id };
      await this.$store.dispatch("csmsChargeStations/LocationsList", params);
    }
    this.$store.dispatch("csmsChargeStations/setLoadingValue", true);

    if (this.$route?.query?.connector_state) {
      this.connector_state = this.$route.query.connector_state;
    }
    if (this.$route?.query?.location_id) {
      this.location = parseInt(this.$route.query.location_id);
    }
  },
  beforeDestroy() {
    clearTimeout(this.interval);

    const paramsset = { ...this.options };
    paramsset.connector_state = this.connector_state;
    paramsset.location_id = this.location;
    const params = {
      name: "charge_connectors",
      filters: paramsset,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    openDialog(val) {
      this.dialog = true;
      this.customerAdd = false;
      this.tableView = false;
      this.searchQuery = "";
      this.customerData = []; // Reset data
      this.selectedCustomer = null; // Clear selection
      this.itemData = val; // Clear selection
    },

    async fetchData() {
      clearTimeout(this.interval);
      const params = { ...this.options };
      params.connector_state = this.connector_state;
      params.location_id = this.location;
      await this.$store
        .dispatch("csmsChargeStations/connectorlist", params)
        .then(() => {
          if (this.options.page > (this.meta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });

      this.interval = setTimeout(() => {
        this.fetchData();
      }, 30000);
    },
    fetchStatus(item) {
      if (["Available"].includes(item.connector_state)) {
        return "active";
      } else if (
        ["Preparing", "Charging", "Finishing"].includes(item.connector_state)
      ) {
        return "inactive";
      } else {
        return "offline";
      }
    },

    //
    startAction() {
      // Start Action
    },
    closeDialog() {
      this.dialog = false;
      this.customerAdd = false;
      this.tableView = false;
      this.searchQuery = "";
      this.customerData = [];
      this.selectedCustomer = null;
      this.itemData = null; // Clear selection

    },
  },
};
</script>