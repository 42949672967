import service from "@/store/services/csms-rates-service";

const state = {
  loading: false,
  meta: {},
  list: [],
};

const mutations = {
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },

  // ADD_LIST(state, mdMake) {
  //   state.list.push(mdMake);
  // },


  UPDATE_LIST(state, payload) {
    const item = state.list.find((item) => item.id === payload.id);
    Object.assign(item, payload);
  },

  DELETE_LIST(state, payload) {
    state.list.splice(state.list.map((o) => o.id).indexOf(payload.id), 1);
  },
  SET_LOADING_LIST(state, value) {
    state.loading = value;
  },
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
  list: (store, params) => {
    store.state.loading = true;
    service.list(params).then((data) => {
      store.commit("SET_META", data.body);
      store.commit("SET_LIST", data.body.data);
      store.state.loading = false;
    });
  },

  add: (store, { payload, filter }) => {
    return service.add(payload).then(() => {
      // store.commit("ADD_LIST", data.body);
      store.dispatch("list", filter);
    });
  },

  update: (store, params) => {
    return service.update(params).then((data) => {
      store.commit("UPDATE_LIST", data);
    });
  },

  destroy: (store, params) => {
    return service.destroy(params).then(() => {
      store.commit("DELETE_LIST", params);
    });
  },
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  getMeta(state) {
    return state.meta;
  },
  getList(state) {
    return state.list;
  },
};

const csmsRates = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default csmsRates;
