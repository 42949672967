<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="listOEM"
      :filter-names="['search', 'make', 'model']"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:chasis_no="{ item }">
        <td class="">
          <div
            v-if="$admin.hasAccessTo('single-vehicle-trips.list')"
            class="imei-column"
          >
            <!-- @click="goToChargeSession(item)" -->
            <router-link :to="goToChargeSession(item)">
              {{ item.chasis_no }}
            </router-link>
          </div>
          <div v-else>
            {{ item.chasis_no }}
          </div>
        </td>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <!-- <v-card>
          <v-text-field
            v-model="options.search"
            dense
            clearable
            :label="$t('select')"
            @change="fetchData"
          />
        </v-card> -->
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <!-- <v-toolbar
          flat
          class="action-toolbar"
        > -->

        <refresh-button
          v-if="list.length > 0"
          :is-loading="isLoading"
          @reload="fetchData"
        />

        <!-- </v-toolbar> -->
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    refreshButton: () => import("@/components/base/RefreshButton.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},

    };
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "customerVehicles/getIsLoadingCustomerVehicles",
      meta: "customerVehicles/getCustomerVehiclesMeta",
      list: "customerVehicles/getCustomerVehiclesList",
    }),
    _headers() {

      const headers = [
        {
          text: this.$t("chassis_no"),
          align: "left",
          sortable: false,
          value: "chasis_no",
          width: "10%",
        },
        (this.options.product) ? {
          text: ((this.options.product == "oem")) ? this.$t("customer_name") : this.$t("driver_name"),
          align: "left",
          sortable: false,
          con: true,
          value: (this.options.product == "oem") ? "customer_name" : "driver_name",
          width: "10%",
        } : {
          text: "Name",
          align: "left",
          sortable: false,
          con: true,
          value: "",
          width: "10%",
        },
        {
          text: this.$t("make_and_model"),
          align: "left",
          sortable: false,
          value: "make_and_model",
          width: "10%",
        },
        {
          text: this.$t("charge_session_count"),
          align: "left",
          sortable: false,
          value: "total_charge_sessions_count",
          width: "10%",
        },
      ]

      return headers;
    },
    // Remove  ICE vehicle
    listOEM() {
      const listOEM = this.list.filter((r) => r.vehicle_tech !== "ice");
      return listOEM;
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  mounted() {
    this.$store.dispatch('customerVehicles/setLoadingValue', true)
  },
  beforeDestroy() {
    const params = {
      name: "charge_session",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store
        .dispatch("customerVehicles/list", this.options)
        .then(() => {
          // if (this.options.page > (this.customerVehiclesMeta?.lastPage || 1)) {
          //   this.options.page = 1;
          // }
        });
    },
    goToChargeSession(row) {
      const params = {
        product: this.options.product,
        fleetId: this.options.fleetId ?? undefined,
        oem_b2b_ids: this.options.oem_b2b_ids ?? undefined,
        tenant_id: this.options.tenant_id,
        search: this.options.search ?? undefined,
        vehicle_id: row.vehicle_id,
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        md_make_id: this.options.md_make_id,
        md_model_id: this.options.md_model_id,
      };
      return {
        name: "charge-session-list",
        params: { imei: row.iot_imei },
        query: params,
      };
      // return this.$router.push({
      //   name: "charge-session-list",
      //   params: { imei: row.iot_imei },
      //   query: params,
      // });
    },
  },
};
</script>