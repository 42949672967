var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ListingLayout',{attrs:{"is-loading":_vm.isLoading,"meta":_vm.meta,"headers":_vm._headers,"items":_vm.list,"filter-names":_vm.filtersName,"search-label":"search","vertical-scroll":"","multi-select-rows":this.$admin.hasAccessTo('vehicles.action') && _vm.options.tenant_id
        ? true
        : false},on:{"filterOption":_vm.onFiltersChange},scopedSlots:_vm._u([{key:"actions",fn:function({ item }){return [(
          _vm.$admin.can('vehicles.edit') || _vm.$admin.can('vehicles.assign-vehicle')
        )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.$admin.can('vehicles.delete'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"assigned",fn:function({ item }){return [_c('div',{class:item?.test_customers?.length > 0 ? 'activeCustomer' : '',on:{"click":function($event){item?.test_customers?.length > 0 ? _vm.showTestCustomers(item) : ''}}},[_c('span',[_vm._v(" "+_vm._s(item?.assigned?.replace(/_/g, " "))+" ")])])]}},{key:"actionModals",fn:function(){return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"0.5rem"}},[(_vm.selectedRows.length > 0)?_c('v-menu',{attrs:{"left":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"min-width":"30px","max-width":"45px"},attrs:{"color":"primary","dark":"","title":"Assigned / Unassigned of Business"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-office-building-cog")])],1)]}}],null,false,2713111370),model:{value:(_vm.isAction),callback:function ($$v) {_vm.isAction=$$v},expression:"isAction"}},[(_vm.isAction)?_c('v-list',{staticStyle:{"max-width":"300px"}},[_c('v-radio-group',{model:{value:(_vm.actionFilter.value),callback:function ($$v) {_vm.$set(_vm.actionFilter, "value", $$v)},expression:"actionFilter.value"}},_vm._l((_vm.actions),function(act,i){return _c('v-list-item',{key:i,attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-radio',{attrs:{"value":act.key,"label":_vm.$t(act.label),"hide-details":"","ripple":false}})],1),(_vm.actionFilter.value == 'oem_b2b_id' && i == 0)?_c('v-list-item-subtitle',[_c('v-select',{attrs:{"dense":"","items":_vm.authUser.isAdmin
                          ? _vm.oemB2bList.filter(
                            (r) => r.tenant_id == _vm.options.tenant_id
                          )
                          : _vm.oemB2bList,"item-value":"id","item-text":"business_name","clearable":""},model:{value:(_vm.actionFilter.oem_b2b_id),callback:function ($$v) {_vm.$set(_vm.actionFilter, "oem_b2b_id", $$v)},expression:"actionFilter.oem_b2b_id"}})],1):_vm._e()],1)],1)}),1),_c('v-divider'),_c('v-list-item-action',{staticClass:"d-flex justify-end mr-2"},[_c('v-btn',{attrs:{"color":"primary","dark":"","small":"","loading":_vm.isActionLoading},on:{"click":_vm.onActionApply}},[_vm._v(" "+_vm._s(_vm.$t("apply"))+" ")])],1)],1):_vm._e()],1):_vm._e(),(_vm.$admin.can('vehicles.create'))?_c('create-vehicle',{attrs:{"filter-options":_vm.options}}):_vm._e()],1),(
          (_vm.$admin.can('vehicles.edit') ||
            _vm.$admin.can('vehicles.assign-vehicle')) &&
            _vm.editModal
        )?_c('edit-vehicle',{attrs:{"is-visible-dialog":_vm.editModal,"toggle-edit":_vm.toggleEditModal,"vehicle":_vm.selectedRow}}):_vm._e(),(_vm.$admin.can('vehicles.delete'))?_c('confirmation-modal',{attrs:{"is-visible":_vm.deleteModal,"toggle":_vm.toggleDeleteModal,"row":_vm.selectedRow}}):_vm._e(),(_vm.showTestCustomerModal)?_c('test-customer',{attrs:{"is-visible-dialog":_vm.showTestCustomerModal,"toggle-modal":_vm.toggleTestCustomerModal,"data":_vm.selectedCustomer}}):_vm._e()]},proxy:true}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }