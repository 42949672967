<template>
  <div class="overview">
    <section v-if="isBasic || isStandard">
      <v-card
        :loading="isLoading"
        elevation="4"
        class="mt-0 vue-map-card"
      >
        <v-card-text
          v-if="tripDetail.route"
          class="px-0 py-0"
        >
          <v-container fluid>
            <!-- Add the iframe for Flespi MapView -->
            <!-- :trips-data="tripDetail.vehicle_trips" -->
            <leaf-map
              v-if="isStandard"
              :trips-data="route"
              direction-arrow-color
              :popup-options="['latitude', 'longitude', 'speed', 'date_time']"
              :vehicle-status="tripDetail.vehicle_status"
              :vehicle-body-type="tripDetail.body_type"
              :trip-play-back="true"
            />

            <iframe
              v-else-if="isBasic"
              id="map"
              src="https://flespi.io/mapview/"
              frameborder="0"
            />
            <!-- <div v-if="true">
            <leaf-map :trips-data="tripDetail.vehicle_trips" />
          </div> -->
          </v-container>
        </v-card-text>
        <!-- <v-container
    id="regular-tables2"
    fluid
    tag="section"
    >
      </v-container> -->
      </v-card>
    </section>
    <v-card
      :loading="isLoading"
      elevation="4"
      class="my-0 info-container"
    >
      <v-container
        id="info-section"
        fluid
        tag="section"
      >
        <div>
          <v-icon>mdi-road-variant</v-icon>
          <span>
            <span>{{ $t("interval_id") }}</span>
            <span>{{ tripDetail.interval_id }}</span>
          </span>
        </div>
        <div>
          <v-icon>mdi-clock-start</v-icon>
          <span>
            <span>{{ $t("date") }}</span>
            <span>{{
              tripDetail.start_datetime | formatDate("DD-MM-YYYY")
            }}</span>
          </span>
        </div>
        <div v-if="tripDetail.vehicle_tech === 'ev'">
          <v-icon>mdi-battery-charging-10</v-icon>
          <span>
            <span>{{ $t("start_soc") }}</span>
            <span v-if="isSOC">
              <v-form
                ref="formStartSOC"
                lazy-validation
              >
                <v-text-field
                  v-model="tripDetail.start_soc"
                  type="number"
                  :rules="[rules.number, checkStartSOC(tripDetail.end_soc)]"
                  @change="SOCValidation"
                />
              </v-form>
            </span>
            <span v-else>{{ tripDetail.start_soc }}%</span>
            <v-icon
              v-if="review_req"
              class="editable"
              @click="review_req ? (isSOC = !isSOC) : ''"
            >
              mdi-pencil
            </v-icon>
          </span>
        </div>
        <div v-if="tripDetail.vehicle_tech === 'ice'">
          <v-icon>mdi-fuel</v-icon>
          <span>
            <span>{{ $t("start_fuel") }}</span>
            <span v-if="isSOC">
              <v-form
                ref="formStartSOC"
                lazy-validation
              >
                <v-text-field
                  v-model="tripDetail.start_fuel"
                  type="number"
                  :rules="[
                    // rules.number,
                    rules.float(2),
                    checkStartFuel(tripDetail.end_fuel),
                  ]"
                  @change="SOCValidation"
                />
              </v-form>
            </span>
            <span v-else>{{ tripDetail.start_fuel }}L</span>
            <v-icon
              v-if="review_req"
              class="editable"
              @click="review_req ? (isSOC = !isSOC) : ''"
            >
              mdi-pencil
            </v-icon>
          </span>
        </div>
        <div>
          <v-icon>mdi-map-marker-distance</v-icon>
          <span>
            <span>{{ $t("distance") }}</span>
            <span>{{
              tripDetail.distance
                ? parseFloat(tripDetail.distance).toFixed(2)
                : ""
            }}</span>
          </span>
        </div>
        <div>
          <v-icon>mdi-speedometer</v-icon>
          <span>
            <span>{{ $t("top_speed") }}</span>
            <span>{{ tripDetail.max_speed }}</span>
          </span>
        </div>
        <div>
          <v-icon>mdi-access-point</v-icon>
          <span>
            <span>{{ $t("imei") }}</span>
            <span>{{ tripDetail.imei }}</span>
          </span>
        </div>
        <div>
          <v-icon>mdi-clock-start</v-icon>
          <span>
            <span>{{ $t("start_datetime") }}</span>
            <span>{{
              tripDetail.start_datetime | formatDate("hh:mm:ss A")
            }}</span>
          </span>
        </div>
        <div v-if="tripDetail.vehicle_tech === 'ev'">
          <v-icon>mdi-battery-charging-80</v-icon>
          <span>
            <span>{{ $t("end_soc") }}</span>
            <span v-if="isSOC">
              <v-form
                ref="formEndSOC"
                lazy-validation
              >
                <v-text-field
                  v-model="tripDetail.end_soc"
                  type="number"
                  :rules="[rules.number, checkEndSOC(tripDetail.start_soc)]"
                  @change="SOCValidation"
                />
              </v-form>
            </span>
            <span v-else>{{ tripDetail.end_soc }}%</span>
            <v-icon
              v-if="review_req"
              class="editable"
              @click="review_req ? (isSOC = !isSOC) : ''"
            >
              mdi-pencil
            </v-icon>
          </span>
        </div>

        <div v-if="tripDetail.vehicle_tech === 'ice'">
          <v-icon>mdi-fuel</v-icon>
          <span>
            <span>{{ $t("end_fuel") }}</span>
            <span v-if="isSOC">
              <v-form
                ref="formEndSOC"
                lazy-validation
              >
                <v-text-field
                  v-model="tripDetail.end_fuel"
                  type="number"
                  :rules="[
                    // rules.number,
                    rules.float(2),
                    checkEndFuel(tripDetail.start_fuel),
                  ]"
                  @change="SOCValidation"
                />
              </v-form>
            </span>
            <span v-else>{{ tripDetail.end_fuel }}L</span>
            <v-icon
              v-if="review_req"
              class="editable"
              @click="review_req ? (isSOC = !isSOC) : ''"
            >
              mdi-pencil
            </v-icon>
          </span>
        </div>

        <div>
          <v-icon>mdi-access-point</v-icon>
          <span>
            <span>{{ $t("idle_time") }}</span>
            <span>{{ tripDetail.idle_time }}</span>
            <!-- <span>10m</span> -->
          </span>
        </div>
        <div>
          <v-icon>mdi-speedometer</v-icon>
          <span>
            <span>{{ $t("avg_speed") }}</span>
            <span>{{ tripDetail.avg_speed }}</span>
          </span>
        </div>
        <div>
          <v-icon>mdi-cloud</v-icon>
          <span>
            <span>{{ $t("weather") }}</span>
            <span class="text-capitalize d-flex align-center">
              {{ tripDetail.weather_temperature }}
              <span v-if="tripDetail.weather_temperature !== 'N/A'">
                <sup>O</sup>C
              </span>
              <span v-if="tripDetail.weather_description !== 'N/A'">
                ({{ tripDetail.weather_description }})
              </span>
            </span>
          </span>
        </div>
        <div>
          <v-icon>mdi-clock-end</v-icon>
          <span>
            <span>{{ $t("end_datetime") }}</span>
            <span>{{
              tripDetail.end_datetime | formatDate("hh:mm:ss A")
            }}</span>
          </span>
        </div>
        <div v-if="tripDetail.vehicle_tech == 'ice'">
          <v-icon>mdi-fuel</v-icon>
          <span>
            <span>{{ $t("fuel_consumption") }}</span>
            <span>{{ tripDetail.fuel_consumption }}L</span>
          </span>
        </div>
        <div v-if="tripDetail.vehicle_tech !== 'ice'">
          <v-icon>mdi-battery-60</v-icon>
          <span>
            <span>{{ $t("battery_consumed") }}</span>
            <span>{{ tripDetail.battery_consumed }}</span>
          </span>
        </div>
        <div>
          <v-icon>mdi-timer-sand-complete</v-icon>
          <span>
            <span>{{ $t("duration") }}</span>
            <span>{{ tripDetail.duration }}</span>
          </span>
        </div>
        <div v-if="tripDetail.vehicle_tech == 'ice'">
          <v-icon> mdi-speedometer</v-icon>
          <span>
            <span>{{ $t("total_avg_milage_ice") }} (KM/L)</span>
            <span>{{ tripDetail.avg_mileage }}</span>
          </span>
        </div>

        <div v-if="tripDetail.vehicle_tech !== 'ice'">
          <v-icon>mdi-speedometer</v-icon>
          <span>
            <span>{{ $t("total_avg_milage_ev") }} (KM/kWh)</span>
            <span>{{ tripDetail.avg_mileage }}</span>
          </span>
        </div>
        <div v-if="tripDetail.vehicle_tech !== 'ice'">
          <v-icon>mdi-cash</v-icon>
          <span>
            <span>{{ $t("monetary_cost_ev") | currencyFormat(authUser) }}</span>
            <span>{{ tripDetail.monetary_cost }}</span>
          </span>
        </div>
        <div v-if="tripDetail.vehicle_tech === 'ice'">
          <v-icon>mdi-cash</v-icon>
          <span>
            <span>{{ $t("monetary_cost_fv") | currencyFormat(authUser) }}</span>
            <span>{{
              tripDetail.comp_monetary_cost
                ? parseFloat(tripDetail.comp_monetary_cost).toFixed(2)
                : "N/A"
            }}</span>
          </span>
        </div>
        <div v-if="tripDetail.vehicle_tech !== 'ice'">
          <v-icon>mdi-hand-coin</v-icon>
          <span>
            <span>{{ $t("savings") | currencyFormat(authUser) }}</span>
            <span>{{
              tripDetail.cost_efficiency
                ? parseFloat(tripDetail.cost_efficiency).toFixed(2)
                : "N/A"
            }}</span>
          </span>
        </div>
        <div v-if="tripDetail.vehicle_tech !== 'ice'">
          <v-icon>mdi-lightning-bolt</v-icon>
          <span>
            <span>{{
              $t("electricity_per_unit_price") | currencyFormat(authUser)
            }}</span>
            <span>{{ tripDetail.electricity_per_unit_price }}</span>
          </span>
        </div>
        <!-- here -->
        <!--  -->

        <!-- <div>
          <v-icon>mdi-access-point</v-icon>
          <span>
            <span>{{ $t("chasis_no") }}</span>
            <span>{{ tripDetail.vehicle_chassis_number }}</span>
          </span>
        </div> -->

        <!--  -->
        <!--  -->
        <template v-if="tripDetail.vehicle_type == 'three_wheeler'">
          <div @click="!review_req ? (isEditable = !isEditable) : ''">
            <v-icon>mdi-weight</v-icon>
            <span>
              <span>{{ $t("weight") }} </span>
              <span v-if="isEditable">
                <v-form
                  ref="form"
                  lazy-validation
                >
                  <v-text-field
                    v-model="tripDetail.weight"
                    class="pt-0 mt-0"
                    type="number"
                    :rules="[rules.float(2)]"
                    @change="onWeightChange"
                  />
                </v-form>
              </span>
              <span v-else>{{ tripDetail.weight }}</span>
              <v-icon
                v-if="!review_req"
                class="editable"
              >
                mdi-pencil
              </v-icon>
            </span>
          </div>

          <!--  -->
          <div v-if="tripDetail.weight != 0">
            <v-icon>mdi-weight-kilogram</v-icon>
            <span>
              <span>{{ $t("cost_per_kg") | currencyFormat(authUser) }}</span>
              <span>{{
                tripDetail.cost_per_kg
                  ? parseFloat(tripDetail.cost_per_kg).toFixed(3)
                  : "N/A"
              }}</span>
            </span>
          </div>
          <!--  -->
          <div>
            <v-icon>mdi-cash</v-icon>
            <span>
              <span>{{ $t("cost_per_km") | currencyFormat(authUser) }}</span>
              <span>{{
                tripDetail.cost_per_km
                  ? parseFloat(tripDetail.cost_per_km).toFixed(2)
                  : "N/A"
              }}</span>
            </span>
          </div>
        </template>
        <div v-if="isSOC">
          <v-btn
            color="primary"
            small
            @click="onChangeSOC"
          >
            Apply
          </v-btn>
        </div>
        <!--  -->
        <!--  -->
        <!--  -->
      </v-container>
    </v-card>
    <!-- <section>
    </section> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  components: {
    LeafMap: () =>
      import("../../../../components/sections/VueLeafLetMap/LeafMap.vue"),
  },
  mixins: [validationRules],

  data() {
    return {
      isSOC: false,
      isEditable: false,
      position: {
        lat: 24.87847,
        lng: 67.0645625,
      },
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "vehicleTrips/getIsLoading",
      tripDetail: "vehicleTrips/getVehicleTripDetail",
      tripDashboard: "vehicleTrips/getTripDashboardChart",
    }),

    review_req() {
      return this.tripDetail.review_required == 1 ? true : false;
    },
    isBasic() {
      return this.$admin.hasAccessTo("map.basic");
    },
    isStandard() {
      return this.$admin.hasAccessTo("map.standard");
    },
    route() {
      const arr = [];
      this.tripDashboard.forEach((el) => {
        arr.push({
          latitude: el.latitude,
          longitude: el.longitude,
          speed: el.speed,
          over_speed: el.over_speed,
          state_of_charge: el.state_of_charge,
          date_time: el.date_time,
        });
      });

      return arr;
    },
  },

  beforeDestroy() {
    this.$store.commit("vehicleTrips/SET_VEHICEL_TRIPS_DETAIL", {});
    window.removeEventListener("message", this.handleMapViewMessage);
  },
  async mounted() {
    await this.fetchData();
    window.addEventListener("message", this.handleMapViewMessage);
  },
  methods: {
    async fetchData() {
      let params = {
        id: this.$route.params.id,
        imei: this.$route.query.imei,
        with_details: true,
      };
      await this.$store.dispatch("vehicleTrips/detail", params).then(() => {});

      if (!this.$admin.can("vehicle-trip-details.dashboard")) {
        const params = {};
        params.trip_interval_id = this.$route.params?.id;
        params.imei = this.$route?.query?.imei;
        await this.$store
          .dispatch("vehicleTrips/tripsDashboardChart", params)
          .then(() => {});
      }
    },
    // redirectBack() {
    //   this.$store.commit("vehicleTrips/SET_VEHICEL_TRIPS_DETAIL", {});
    //   const IMEI = this.$route.query.imei;
    //   return this.$router.push({
    //     name: "trip-listing",
    //     params: { imei: IMEI },
    //     query: this.$route.query,
    //   });
    // },
    handleMapViewMessage(event) {
      if (
        typeof event.data === "string" &&
        event.data.indexOf("MapView|") === 0
      ) {
        if (event.data === 'MapView|state:{"ready": true}') {
          const zoomControlCmd = 'MapView|cmd:{"zoomcontrol": true}';
          document
            .getElementById("map")
            .contentWindow.postMessage(zoomControlCmd, "*");
          const encodedPolyline = this.tripDetail.route;
          const escapedEncodedPolyline = encodedPolyline.replace(/\\/g, "\\\\");
          const appendgroutesCmd = `MapView|cmd:{"appendgroutes": ["${escapedEncodedPolyline}"]}`;
          document
            .getElementById("map")
            .contentWindow.postMessage(appendgroutesCmd, "*");
        }
      }
    },
    async onWeightChange() {
      if (this.$refs.form.validate()) {
        this.isEditable = false;
        const params = {
          id: this.tripDetail.id,
          weight: this.tripDetail.weight,
        };
        await this.$store
          .dispatch("vehicleTrips/update", params)
          .then(() => {
            this.fetchData();
          })
          .catch((err) => {
            this.$store.dispatch("alerts/error", err?.response?.data?.message);
          });
      }
    },
    async onChangeSOC() {
      const fSOC = this.$refs.formStartSOC.validate();
      const eSOC = this.$refs.formEndSOC.validate();
      if (fSOC && eSOC) {
        const params = {
          id: this.tripDetail.id,
        };
        if (this.tripDetail.vehicle_tech == "ev") {
          params.soc_start = parseFloat(this.tripDetail.start_soc);
          params.soc_end = parseFloat(this.tripDetail.end_soc);
        }
        if (this.tripDetail.vehicle_tech == "ice") {
          params.soc_start = parseFloat(this.tripDetail.start_fuel);
          params.soc_end = parseFloat(this.tripDetail.end_fuel);
        }
        await this.$store
          .dispatch("vehicleTrips/updateSOC", params)
          .then(() => {
            this.isSOC = false;
            this.fetchData();
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          })
          .catch((err) => {
            this.$store.dispatch("alerts/error", err?.response?.data?.message);
          });
      }
    },
    SOCValidation() {
      this.$refs.formStartSOC.validate();
      this.$refs.formEndSOC.validate();
    },

    checkStartSOC(val) {
      return (v) => {
        if (v < 0) return "Should be greater than 0";
        else if (v > 100) return "should be less than 100";
        else if (v >= 0 && v <= 100) {
          if (v === val) return true;
          else if (parseInt(v) < parseInt(val))
            return `Should be greater than end SOC`;
          else return true;
        }
        return false;
      };
    },
    checkEndSOC(val) {
      return (v) => {
        if (v < 0) return "Should be greater than 0";
        else if (v > 100) return "should be less than 100";
        else if (v >= 0 && v <= 100) {
          if (v === val) return true;
          else if (parseInt(v) > parseInt(val))
            return `Should be less than start SOC`;
          else return true;
        }
        return false;
      };
    },
    checkStartFuel(val) {
      return (v) => {
        if (v < 0) return "Should be greater than 0";
        else if (v > 100) return "should be less than 100";
        else if (v >= 0 && v <= 100) {
          if (v === val) return true;
          else if (parseInt(v) < parseInt(val))
            return `Should be greater than end fuel`;
          else return true;
        }
        return false;
      };
    },
    checkEndFuel(val) {
      return (v) => {
        if (v < 0) return "Should be greater than 0";
        else if (v > 100) return "should be less than 100";
        else if (v >= 0 && v <= 100) {
          if (v === val) return true;
          else if (parseInt(v) > parseInt(val))
            return `Should be less than start fuel`;
          else return true;
        }
        return false;
      };
    },
  },
};
</script>
<style lang="sass" scoped>

.overview
  margin-top: 1rem
  // height: inherit
  // height: 80vh
  // display: flex
  // flex-direction: column
  // justify-content: space-between
  gap: 1rem
  section
    flex: 1


.vue-map-card
  height: 50vh !important
  // height: 100% !important
// .info-container
//   height: 30vh
// :deep .v-card
//   height: 70vh !important
//   .v-card__text, .container
//     height: inherit !important
//     #map
//       height: -webkit-fill-available !important
//   .vue-map-container
//     width: 100%
//     height: 70vh

#info-section
  display: flex
  flex-wrap: wrap
  // font-size: 11px
  gap: 1.5rem
  // height: 30vh
  // line-height: 3
  // justify-content: space-between
  div
    width: 18%
    display: flex
    gap: 0.5rem
    align-items: center
    // flex-direction: column
    // padding-bottom: 0.5rem
    // flex-basis: 200px
    // span:first-child
    //   width: 150px
    i
      font-size: 1.5rem
      color: #fff
      background: #20a390
      padding: 10px
      border-radius: 30px
    >span
      display: flex
      flex-direction: column
      span:first-child
        color: gray
        font-size: 12px
      span:last-child
        font-weight: 600
        font-size: 18px


    // >span:first-child
    //   display: flex
    //   flex-direction: column
    //   // display: inline-block
    //   // width: 140px
    // span:last-child
    //   font-weight: 600
    //   font-size: 12px
      // padding-bottom: 0.5rem

#map
  position: absolute !important
  width: 100% !important
  height: 100% !important
  top: 0
  left: 0


:deep .v-input
  height: 20px
  font-size: 11px
  width: 70px !important
  .v-text-field__slot
    height: 20px

  .v-text-field__details
    text-wrap: nowrap
    overflow: visible
    .v-messages__message
      font-size: 10px

sup
  font-size: 10px !important

dd
  color: gray
  font-size: 14px !important
  margin-left: 2px

.editable
  cursor: pointer


@media only screen and (max-width: 960px)
  #info-section
    div
      width: 30%

@media only screen and (max-width: 660px)
  #info-section
    div
      width: 46%
</style>
