<template>
  <v-dialog
    v-model="isVisible"
    persistent
    :max-width="width"
  >
    <v-card>
      <v-card-title>
        <span class="text-h3">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <slot />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small v-if="!isView">{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!isView"
          color="primary"
          text
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    isView: Boolean,
    title: {
      type: String,
      default() {
        return "title";
      },
    },

    width: {
      type: String,
      default() {
        return "500px";
      },
    },
    toggle: {
      type: Function,
      default() {
        return void 0;
      },
    },
  },
  mounted() { },
  methods: {
    closeForm() {
      this.isVisibleDialog = false;
      this.toggle();
    },
  },
};
</script>