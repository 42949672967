<template>
  <div>
    <ListingLayout
      :sub-screen="1"
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :bread-crumbs="false"
      no-filters
      :data-table-key="0"
      show-date
      :date-custom-options="false"
      telematic-massage-rows
      vertical-scroll
      @filterOption="onFiltersChange"
      @closemodal="closeFilterMenu"
    >
      <template v-slot:id="{ item }">
        <div v-if="authUser.tenant_id">
          {{ item.id }}
        </div>
        <div
          v-else
          class="messageDetail"
          @click="showMsgDetail(item)"
        >
          {{ item.id }}
        </div>
      </template>
      <template v-slot:device_time="{ item }">
        <v-menu
          :id="item.key"
          :close-on-content-click="false"
          :nudge-width="150"
          offset-x
        >
          <!-- :nudge-width="200" -->
          <template v-slot:activator="{ on, attrs }">
            <div
              class="open-link"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.device_time | formatDate }}
            </div>
          </template>

          <div class="py-2 px-2">
            <div class="d-flex justify-space-between">
              <label for="">Flespi Server TS </label>
              <span>{{ (item.flespi_server_time) ? format(item.flespi_server_time) : "N/A" }}</span>
            </div>
            <div class="d-flex justify-space-between">
              <label for="">Server TS</label>
              <span>{{ (item.timestamp) ? format(item.timestamp) : "N/A" }}</span>
            </div>
            <div class="d-flex justify-space-between">
              <label for="">Pusher Sent At</label>
              <span>{{ (item.pusher_sent_at) ? format(item.pusher_sent_at) : "N/A" }} </span>
            </div>
          </div>
        </v-menu>
      </template>
      <template v-slot:timestamp="{ item }">
        <div>
          {{ item.timestamp | formatDate }}
        </div>
      </template>
      <template v-slot:mobile_ack="{ item }">
        <div>
          <span v-if="item.pusher_sent_at && !item.mobile_ack">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-bell-alert
                </v-icon>
              </template>
              <span>Acknowledgement not recieved</span>
            </v-tooltip>
          </span>
          <v-icon
            v-else-if="
              item.mobile_ack !== null && item.mobile_ack !== undefined
            "
            small
            @click="showMblAck(item.mobile_ack)"
          >
            mdi-eye
          </v-icon>
          <span v-else> N/A</span>
        </div>
      </template>
      <template v-slot:position="{ item }">
        <v-icon
          v-if="item.longitude && item.latitude"
          class="mr-2"
          small
          @click="showVehiclePosition(item)"
        >
          mdi-eye
        </v-icon>
        <span v-else> N/A</span>
      </template>
      <template v-slot:problems="{ item }">
        <span v-if="item.delay_sync">
          {{ $t("delay_sync_msg") }}
        </span>
        <span v-else>
          {{ item.problems?.join(", ") }}
        </span>
      </template>

      <!-- add Filters -->
      <template #moreFilters>
        <!-- v-if="showFilters" -->
        <div class="moreFiltersDropdown">
          <v-menu
            v-model="filterMenu"
            :close-on-content-click="false"
            :nudge-width="100"
            content-class="filterMenuDropDown"
            offset-x
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-card>
                <v-text-field
                  class="filterDropDown"
                  filled
                  dense
                  v-bind="attrs"
                  placeholder="Filters"
                  :value="''"
                  readonly
                  v-on="on"
                >
                  <!-- Prepend the settings icon -->
                  <template v-slot:prepend-inner>
                    <v-icon> mdi-cog </v-icon>
                  </template>
                </v-text-field>
              </v-card>
            </template>
            <v-card class="my-0 py-0">
              <v-list class="my-0">
                <v-list-item class="py-0 filtersTopWrap">
                  <!-- <v-list-item-action>
                              <v-btn
                                color="dark"
                                elevation="2"
                                small
                                >Close</v-btn
                              >
                            </v-list-item-action> -->

                  <v-list-item-content class="py-0">
                    <v-list-item-title>
                      <h4>Filters</h4>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="py-0 filterClearBtn">
                    <v-btn
                      color="primary"
                      text
                      small
                      @click="clearFilters"
                    >
                      Clear
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-divider />
              <v-list class="filterListing">
                <v-list-item
                  v-for="(item, i) in filterOptions"
                  :key="i"
                  class="filtersItemWrap"
                >
                  <div class="insideFiltersItemWrap">
                    <v-list-item-action class="py-0 my-0">
                      <v-checkbox
                        ref="myFilterCheck"
                        v-model="selectedFilter"
                        :label="item.name"
                        :value="item.id"
                        hide-details
                        :ripple="false"
                        @change="
                          onFilterChange(
                            item.id,
                            selectedFilter.includes(item.id)
                          )
                        "
                      />
                    </v-list-item-action>
                    <v-list-item-content
                      v-if="
                        item.id == 'interval' &&
                          selectedFilter.includes(item.id)
                      "
                      class="py-0"
                    >
                      <div class="top-filters">
                        <v-select
                          v-model="customFilter.interval"
                          :items="intervalOption"
                          item-text="label"
                          item-value="value"
                          filled
                          dense
                          @change="onTimeIntervalChange"
                        />
                      </div>
                    </v-list-item-content>
                    <v-list-item-content
                      v-if="
                        item.id == 'soc' && selectedFilter.includes(item.id)
                      "
                      class="py-0 my-0"
                    >
                      <div class="SOC-Options">
                        <div class="top-filters">
                          <v-select
                            v-model="isSOC.operator"
                            filled
                            dense
                            :items="[
                              { value: '=', title: '=' },
                              { value: '>', title: '>' },
                              { value: '<', title: '<' },
                            ]"
                            item-text="title"
                            item-value="value"
                            class="py-0 pt-2"
                            @change="onChangeSOC"
                          />
                        </div>
                        <div class="top-filters">
                          <v-text-field
                            v-model="isSOC.value"
                            :label="$t('state_of_charge')"
                            filled
                            dense
                            type="number"
                            min="0"
                            max="100"
                            suffix="%"
                            class="py-0 pt-2"
                            @change="onChangeSOC"
                          />
                        </div>
                      </div>
                    </v-list-item-content>
                    <v-list-item-content
                      v-if="
                        item.id == 'fuel' && selectedFilter.includes(item.id)
                      "
                      class="py-0 my-0"
                    >
                      <div class="SOC-Options">
                        <div class="top-filters">
                          <v-select
                            v-model="isFuel.operator"
                            filled
                            dense
                            :items="[
                              { value: '=', title: '=' },
                              { value: '>', title: '>' },
                              { value: '<', title: '<' },
                            ]"
                            item-text="title"
                            item-value="value"
                            class="py-0 pt-2"
                            @change="onChangeFuel"
                          />
                        </div>
                        <div class="top-filters">
                          <v-text-field
                            v-model="isFuel.value"
                            :label="$t('fuel_level')"
                            filled
                            dense
                            type="number"
                            min="0"
                            max="100"
                            class="py-0 pt-2"
                            @change="onChangeFuel"
                          />
                        </div>
                      </div>
                    </v-list-item-content>
                    <v-list-item-content
                      v-if="
                        item.id == 'ignition ' &&
                          selectedFilter.includes(item.id)
                      "
                      class="py-0 my-0"
                    >
                      <div class="top-filters">
                        <v-select
                          v-model="customFilter.ignition"
                          filled
                          dense
                          :items="[
                            { value: 0, title: 'OFF' },
                            { value: 1, title: 'ON' },
                          ]"
                          item-text="title"
                          item-value="value"
                          @change="onChangeIgnition"
                        />
                      </div>
                    </v-list-item-content>
                    <v-list-item-content
                      v-if="
                        item.id == 'latency' && selectedFilter.includes(item.id)
                      "
                      class="py-0 my-0"
                    >
                      <div class="Latency-Options">
                        <div class="top-filters">
                          <v-select
                            v-model="isLatency.operator"
                            filled
                            dense
                            :items="[
                              { value: '=', title: '=' },
                              { value: '>', title: '>' },
                              { value: '<', title: '<' },
                            ]"
                            item-text="title"
                            item-value="value"
                            class="py-0 pt-2"
                            @change="onChangeLatency"
                          />
                        </div>
                        <div class="top-filters">
                          <v-text-field
                            v-model="isLatency.value"
                            filled
                            dense
                            :label="$t('latency')"
                            type="number"
                            min="0"
                            max="100"
                            class="py-0 pt-2"
                            @change="onChangeLatency"
                          />
                        </div>
                        <div class="top-filters">
                          <v-select
                            v-model="isLatency.unit"
                            filled
                            dense
                            :items="[
                              { value: 'h', title: 'Hrs' },
                              { value: 'm', title: 'Min' },
                              { value: 's', title: 'Sec' },
                            ]"
                            item-text="title"
                            item-value="value"
                            class="py-0 pt-2"
                            @change="onChangeLatency"
                          />
                        </div>
                      </div>
                    </v-list-item-content>
                  </div>
                </v-list-item>
              </v-list>
              <v-divider />
              <!--<v-card-actions class="d-flex justify-end">
                <v-btn
                  text
                  small
                  @click="filterMenu = false"
                >
                  Cancel
                </v-btn>
                 <v-btn
                            color="primary"
                            small
                            @click="applyFilters"
                          >
                            Apply
                          </v-btn>
              </v-card-actions>-->
            </v-card>
          </v-menu>
        </div>
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div class="d-flex">
          <div v-if="selectedHeaders.columns !== null">
            <v-menu
              v-model="isShowHeaderFilter"
              left
              :close-on-content-click="false"
              :offset-x="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  small
                  v-bind="attrs"
                  class="table-show-btn"
                  v-on="on"
                >
                  <v-icon>mdi-tune-vertical</v-icon>
                </v-btn>
                <!-- <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="icon-gr"> mdi-tune-vertical </v-icon>
                </div> -->
              </template>
              <div v-if="isShowHeaderFilter">
                <table-head-filters
                  v-if="selectedHeaders.columns !== null"
                  :items="headersList"
                  @addColumns="columnOptions"
                />
              </div>
            </v-menu>
          </div>
          <div v-if="list.length > 0">
            <refresh-button
              :is-loading="isLoading"
              @reload="fetchData"
            />
          </div>
          <message-detail-modal
            v-if="isModalOpen"
            :is-visible-dialog="isModalOpen"
            :data="telematicJSON"
            @close="closeModal"
          />
          <vehicle-position-modal
            v-if="positionModal"
            :id="0"
            :is-visible-dialog="positionModal"
            :toggle-modal="togglePositionModal"
            :position="selectedPosition"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    tableHeadFilters: () => import("@/components/base/TableHeadFilters.vue"),
    refreshButton: () => import("@/components/base/RefreshButton.vue"),
    MessageDetailModal: () => import("../utils/MessageDetailModal.vue"),
    vehiclePositionModal: () => import("../utils/VehiclePositionModal.vue"),
  },
  mixins: [ListingLayoutMixins],
  props: {
    name: {
      type: String,
      default() {
        return "";
      },
    },
    selectedTab: {
      type: String,
      default() {
        return "";
      },
    },
    optionFilters: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      filters: {},
      subFilters: {},
      customFilter: {},
      //
      lastVehicleID: null,
      //
      intData: null,
      timeInterval: 30000,
      isModalOpen: false,
      positionModal: false,
      selectedPosition: {},

      //
      isShowHeaderFilter: false,
      filterMenu: false,
      selectedFilter: [],
      intervalOption: [
        { value: 60, label: "1 min" },
        { value: 180, label: "3 mins" },
        { value: 300, label: "5 mins" },
        { value: 1800, label: "30 mins" },
      ],
      isSOC: {},
      isFuel: {},
      isLatency: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "telematicDetails/getIsLoadingTelematicDetails",
      meta: "telematicDetails/getTelematicDetailsMeta",
      list: "telematicDetails/getTelematicDetailsList",
      //
      headersList: "telematicDetails/getHeadersList",
      selectedHeaders: "tableHeaders/getDataHeaders",
      //
      telematicJSON: "telematicDetails/getTelematicJSON",
      //
      tenantList: "tenantsManagement/getTenantOptions",
    }),
    _headers() {
      let headerArr = [];

      if (this.selectedHeaders.columns.length > 0) {
        this.headersList.forEach((header) => {
          if (this.selectedHeaders.columns.includes(header.key)) {
            headerArr.push({
              text: header.heading,
              align: "center",
              sortable: false,
              value: header.key,
              width: "10%",
            });

            header.selected = true;
          } else {
            header.selected = false;
          }
        });
      } else {
        this.headersList.forEach((header) => {
          headerArr.push({
            text: header.heading,
            align: "center",
            sortable: false,
            value: header.key,
            isShow: this.tableHeaderFilters,
            width: "10%",
          });

          header.selected = true;
        });
      }
      return headerArr;
    },
    filterOptions() {
      let vt = ""; //vehicle Tech
      if (this.authUser.isAdmin) {
        const tId = this.$route.query.fleetId || this.$route.query.tenant_id;
        const tRow = this.tenantList.find((r) => r.id == tId);
        vt = tRow?.vehicle_tech;
      } else {
        vt = this.authUser.user_tech;
      }

      const arr = [
        {
          id: "interval",
          name: "Interval",
        },
        ...(this.authUser.isAdmin
          ? [
            {
              id: "sent_to_pusher",
              name: "Sent To Pusher",
            },
            {
              id: "mob_ack",
              name: "Mobile Acknowledgement",
            },
          ]
          : []),
        // for SOC and Fuel Level
        ...(vt == "ev"
          ? [
            {
              id: "soc",
              name: "SOC",
            },
          ]
          : []),
        ...(vt == "ice"
          ? [
            {
              id: "fuel",
              name: "Fuel Level",
            },
          ]
          : []),
        {
          id: "ignition ",
          name: "Ignition ",
        },
        ...(this.authUser.isAdmin
          ? [
            {
              id: "latency",
              name: "Latency",
            },
          ]
          : []),
      ];

      return arr;
    },
    showSelectedFilters() {
      let arr = this.selectedFilter.map((r) =>
        r == "mob_ack" ? "mobile acknowlegment" : r.replace(/_/g, " ")
      );
      return arr.join(", ");
    },
  },
  watch: {
    "optionFilters.vehicle_id": {
      handler: async function (v) {
        if (this.name === this.selectedTab) {
          if (v) {
            this.fetchData();
          }
        }
      },
    },
    selectedTab: {
      handler: async function (v) {
        if (v === this.name) {
          const vd = this.optionFilters.vehicle_id;
          if (vd != this.lastVehicleID) this.fetchData();
        }
      },
    },

    options() {
      this.fetchData();
    },
    // "options.dateType": {
    //   handler: function () {
    //     this.selectedFilter = [];
    //     this.subFilters = {};
    //   },
    // },
  },
  async mounted() {
    this.$store.dispatch('telematicDetails/setLoadingValue', true)

    // let params = {
    //   // id: this.$route.query.tenant_id ?? this.authUser.tenant_id,
    //   id: 77,
    //   name: this.$route.name,
    // };
    // if (this.authUser.isAdmin && this.$route.query.is_independent_fleet) {
    //   params.id = this.$route.query.fleetId;
    // }

    await this.columnOptions();
    // let id = this.$route.query.tenant_id ?? this.authUser.tenant_id;
    // if (this.authUser.isAdmin && this.$route.query.is_independent_fleet) {
    //   id = this.$route.query.fleetId;
    // }
    await this.fetchHeaders();
    // await this.$store.dispatch("telematicDetails/headersList", {
    //   tenant_id: 77,
    // });
    // Set Interval  API will call from options Watcher
    // this.fetchDataWithInterval();
  },
  beforeDestroy() {
    clearInterval(this.intData);
  },

  methods: {
    format(value) {
      if (!value) return "N/A";
      return moment(value).format("DD-MM-YYYY hh:mm:ss A")
    },
    async columnOptions() {
      let params = {};

      if (this.authUser.isAdmin) {
        params = {
          id: this.$route.query.fleetId || this.$route.query.tenant_id,
        };
      } else {
        params.id = this.authUser.tenant_id;
      }
      params.name = "telematic_details";

      await this.$store.dispatch("tableHeaders/selectedlist", params);
    },
    async fetchHeaders() {
      let params = {};
      if (this.authUser.isAdmin) {
        params.tenant_id =
          this.$route.query.fleetId || this.$route.query.tenant_id;
      } else {
        params.tenant_id = this.authUser.tenant_id;
      }
      await this.$store.dispatch("telematicDetails/headersList", {
        tenant_id: params.tenant_id,
      });
    },

    async fetchData(pg) {
      const URL = this.authUser.tenant_id
        ? "telematicDetails/OEMlist"
        : "telematicDetails/list";
      if (pg) {
        this.options.page = pg;
      }
      // this.options.imei = this.$route.params.imei;
      const params = {
        ...this.options,
        ...this.customFilter,
        ...this.optionFilters,
      };

      this.lastVehicleID = this.optionFilters.vehicle_id;
      await this.$store.dispatch(URL, params).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    //
    closeModal() {
      this.isModalOpen = false;
      this.isSelectedRow = {};
    },
    showVehiclePosition(row) {
      this.positionModal = true;
      // this.selectedPosition = [row.longitude, row.latitude];
      this.selectedPosition = { lat: row.latitude, lng: row.longitude };
    },
    togglePositionModal() {
      return (this.positionModal = !this.positionModal);
    },
    //
    showMsgDetail(row) {
      this.$store.dispatch("telematicDetails/show", row.id);
      this.isModalOpen = true;
      // this.isSelectedRow = row;
    },
    showMblAck(json) {
      this.$store.commit("telematicDetails/SET_TELEMATICJSON", json);
      this.isModalOpen = true;
    },
    //
    onFilterChange(i, snd) {
      if (this.selectedFilter.length > 0) {
        clearInterval(this.intData);
      } else {
        // this.fetchDataWithInterval();
      }
      let v = this.selectedFilter;
      if (!v.includes("interval")) this.customFilter.interval = null;
      this.customFilter.pusher_sent_at = v.includes("sent_to_pusher") ? 1 : 0;
      this.customFilter.mobile_ack = v.includes("mob_ack") ? 1 : 0;
      if (!v.includes("soc")) {
        this.isSOC = {};
        this.customFilter.soc_operator = null;
        this.customFilter.soc_value = null;
      }
      if (!v.includes("fuel")) {
        this.isFuel = {};
        this.customFilter.fuel_operator = null;
        this.customFilter.fuel_value = null;
      }
      if (!v.includes("ignition ")) this.customFilter.ignition = null;
      if (!v.includes("latency")) {
        this.isLatency = {};
        this.customFilter.latency_operator = null;
        this.customFilter.latency_value = null;
      }
      if (i == "sent_to_pusher" || i == "mob_ack" || snd == false) {
        this.fetchData(1);
      }
    },
    onTimeIntervalChange(v) {
      this.customFilter.interval = v;
      // clearInterval(this.intData);
      // this.fetchDataWithInterval();
      this.fetchData(1);
    },
    onChangeSOC() {
      if (this.isSOC.operator && this.isSOC.value) {
        this.customFilter.soc_operator = this.isSOC.operator;
        this.customFilter.soc_value = this.isSOC.value;
        this.fetchData(1);
      }
    },
    onChangeFuel() {
      if (this.isFuel.operator && this.isFuel.value) {
        this.customFilter.fuel_operator = this.isFuel.operator;
        this.customFilter.fuel_value = this.isFuel.value;
        this.fetchData(1);
      }
    },
    onChangeIgnition(v) {
      this.customFilter.ignition = v;
      this.fetchData(1);
    },
    onChangeLatency() {
      if (
        this.isLatency.operator &&
        this.isLatency.unit &&
        this.isLatency.value
      ) {
        this.customFilter.latency_operator = this.isLatency.operator;
        this.customFilter.latency_value = this.convertIntoSecond(
          this.isLatency.unit,
          this.isLatency.value
        );
        this.fetchData(1);
      }
    },
    convertIntoSecond(u, v) {
      if (u == "s") return v;
      else if (u == "m") return v * 60;
      else if (u == "h") return v * 60 * 60;
      // let a = v.split(":");
      // let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      // return seconds;
    },
    closeFilterMenu() {
      this.filterMenu = false;
    },
    clearFilters() {
      this.selectedFilter = [];
      // this.timeInterval = 30000;
      this.customFilter.interval = null;
      this.customFilter.pusher_sent_at = 0;
      this.customFilter.mobile_ack = 0;
      this.isSOC = {};
      this.customFilter.soc_operator = null;
      this.customFilter.soc_value = null;
      this.isFuel = {};
      this.customFilter.fuel_operator = null;
      this.customFilter.fuel_value = null;
      this.isLatency = {};
      this.customFilter.latency_operator = null;
      this.customFilter.latency_value = null;
      this.customFilter.ignition = null;
      this.filterMenu = false;
      // this.onTimeIntervalChange();
      // this.fetchDataWithInterval();
      this.fetchData();
    },
  },
};
</script>
<style lang="sass" scoped>
.messageDetail
  color: #1a9986 !important
.filterMenDropDown
  min-width: fit-content !important

.filterDropDown
  :deep input
    text-transform:  capitalize !important
.SOC-Options
  display: flex
  gap: 1rem
  div:first-child
    flex-basis: 30%
  div:last-child
    flex-basis: 70%
.Latency-Options
  width : 100%
  display: flex
  gap: 1rem
  div:first-child, div:last-child
    flex-basis: 30%
  div:nth-child(2)
    flex-basis: 70%
:deep .v-toolbar__content
  align-items: baseline

.v-menu
  background: red
</style>

<style scoped>
.table-show-btn {
  height: 41px !important;
  padding: 0 0.7rem !important;
  min-width: unset !important;
  background: #fff;
  border: 1px solid #dadada !important;
  border-radius: 6px !important;
}

.table-show-btn i {
  font-size: 16px !important;
}

.table-show-btn:hover i,
.table-show-btn:active i,
.table-show-btn:focus i,
.table-show-btn:focus-visible i,
.table-show-btn:focus-within i {
  color: #000 !important;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  padding: 0 0.7rem !important;
  min-width: unset !important;
}

.active button,
.active i {
  animation: spin 0.9s infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>