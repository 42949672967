<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="filtersName"
      search-label="search"
      no-filters
      @filterOption="onFiltersChange"
    >
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('iot-devices.edit')"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('iot-devices.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-col
          v-if="authUser.isAdmin"
          cols="3"
          sm="3"
        >
          <v-select
            v-model="subFilters.make_type"
            dense
            clearable
            :items="makeTypeList"
            item-text="name"
            item-value="id"
            :label="$t('select_mdmake_type')"
          />
        </v-col>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <create-iot-device
          v-if="$admin.can('iot-devices.create')"
          :filter-options="options"
        />
        <edit-iot-device
          v-if="$admin.can('iot-devices.edit') && editModal"
          :filter-options="options"
          :is-visible-dialog="editModal"
          :toggle-edit="toggleEditModal"
          :iot-device="selectedRow"
        />
        <confirmation-modal
          v-if="$admin.can('iot-devices.delete')"
          :is-visible="deleteModal"
          :toggle="toggleDeleteModal"
          :row="selectedRow"
        />
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateIotDevice: () => import("./dialogs/CreateIotDevice.vue"),
    EditIotDevice: () => import("./dialogs/EditIotDevice.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      makeTypeList: [
        {
          id: "default",
          name: "Default",
        },
        {
          id: "tenant",
          name: "Tenant",
        },
      ],
      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },

        {
          text: this.$t("manufacturer"),
          align: "left",
          sortable: false,
          value: "iot_device_make_name",
          // width: "10%",
        },
        {
          text: this.$t("model_name"),
          align: "left",
          sortable: false,
          value: "iot_device_model_name",
          // width: "15%",
        },
        {
          text: this.$t("data_sim"),
          align: "left",
          sortable: false,
          value: "data_sim_number",
          // width: "10%",
        },
        {
          text: this.$t("imei"),
          align: "left",
          sortable: false,
          value: "imei",
          // width: "10%",
        },
        {
          text: this.$t("assigned"),
          align: "left",
          sortable: false,
          value: "assigned",
          // width: "10%",
          showAdmin: true,
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "status",
          width: "15%",
        },
        ...(this.$admin.hasAccessTo("iot-devices.delete") ||
          this.$admin.hasAccessTo("iot-devices.edit")
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "iotDevices/getIsLoadingIotDevices",
      meta: "iotDevices/getIotDevicesMeta",
      list: "iotDevices/getIotDevicesList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
    filtersName() {
      if (this.authUser.isAdmin) {
        return [
          "search",
          "iot_device_make",
          "streams",
          "plugins",
          "calculators",
          "is-assigned",
        ];
      } else {
        return ["search", "iot_device_make"];
      }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  mounted() {
    this.$store.dispatch('iotDevices/setLoadingValue', true)
    // await this.$store.dispatch("iotDeviceMakes/list");
  },
  beforeDestroy() {
    const filter = { ...this.options };
    const params = {
      name: "iot_devices",
      filters: filter,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("iotDevices/list", this.options).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(mdMake) {
      try {
        await this.$store.dispatch("iotDevices/destroy", mdMake);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        if (this.list.length === 0 && this.meta.lastPage == this.options.page) {
          this.options.page -= 1; // Move to the previous page
        }
        this.toggleDeleteModal("close");
        this.fetchData();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .v-select
  .v-chip
    width: 6rem
    .v-chip__content
      span
        white-space: nowrap
        // width: 50px
        overflow: hidden
        text-overflow: ellipsis
</style>