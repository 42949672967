<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="filterNames"
      show-date
      :date-validation="false"
      date-option-type="datetime"
      :is-downloading="isListExport"
      :export-options="['excel', 'csv', 'pdf']"
      :data-table-key="1"
      class-name="d-flex align-center justify-space-between"
      table-heading="Details"
      :show-heading="true"
      @isExport="exportList"
      @filterOption="onFiltersChange"
    >
      <!-- add Filters -->
      <!-- <template #addFilters> </template> -->
      <!-- add Filters -->

      <!-- Journey Tags -->
      <template #description>
        <summary-detail
          :is-loading="isLoading"
          :items="summary"
          :headers="summaryHeader"
          @exportSummary="exportSummary"
        />
      </template>
      <!-- journey Tags -->

      <!-- Action Modals -->
      <!-- <template #actionModals> </template> -->
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    summaryDetail: () => import("../utils/summaryDetail.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "dailyMileageReports/getLoading",
      meta: "dailyMileageReports/meta",
      list: "dailyMileageReports/getJourneyList",
      //
      summary: "dailyMileageReports/getSummary",
      //
      isVehicleList: "vehicles/getIsLoadingVehicles",
      vehicleList: "vehicles/getVehiclesList",
      //
      isListExport: "dailyMileageReports/getIsListExport",

      //
      // for tenant_tech
      tenantsList: "tenantsManagement/getTenantOptions",
    }),
    getVehilceTech() {
      const row = this.tenantsList.find((r) =>
        this.options.is_independent_fleet ? r.id == this.options.fleetId : r.id == this.options.tenant_id
      );
      return row?.vehicle_tech || null;
    },
    _headers() {
      let vTech = this.getVehilceTech;
      // Condition only for OEM
      if (!this.authUser.isAdmin && this.authUser.user_nature !== "fleet") {
        vTech = this.authUser.user_tech;
      }
      const heads = [
        {
          text: this.$t("registration_no"),
          align: "left",
          sortable: false,
          value: "vehicle_variant_name",
        },
        {
          text: this.$t("imei"),
          align: "left",
          sortable: false,
          value: "imei",
        },
        {
          text: this.$t("date"),
          align: "left",
          sortable: false,
          value: "date",
        },
        {
          text: this.$t("journey_time"),
          align: "left",
          sortable: false,
          value: "journey_time",
        },
        {
          text: this.$t("distance"),
          align: "left",
          sortable: false,
          value: "total_distance",
        },
        {
          text: this.$t("avg_speed"),
          align: "left",
          sortable: false,
          value: "average_daily_speed",
        },

        {
          text: this.$t("max_speed"),
          align: "left",
          sortable: false,
          value: "max_speed",
        },
        ...(vTech == "ev"
          ? [
              {
                text: this.$t("battery_consumed"),
                align: "left",
                sortable: false,
                value: "battery_consumed",
              },
            ]
          : []),
        ...(vTech == "ice"
          ? [
              {
                text: this.$t("fuel_consumption"),
                align: "left",
                sortable: false,
                value: "fuel_consumption",
              },
            ]
          : []),
      ];

      // computed headers for showing columns to role based
      return heads.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
    summaryHeader() {
      let vTech = this.getVehilceTech;
      // Condition only for OEM
      if (!this.authUser.isAdmin && this.authUser.user_nature !== "fleet") {
        vTech = this.authUser.user_tech;
      }
      const heads = [
        {
          text: this.$t("registration_no"),
          align: "left",
          sortable: false,
          value: "vehicle_variant_name",
        },
        {
          text: this.$t("imei"),
          align: "left",
          sortable: false,
          value: "imei",
        },
        {
          text: this.$t("total_journey_time"),
          align: "left",
          sortable: false,
          value: "total_journey_time",
          showAdmin: true,
        },
        {
          text: this.$t("total_distance"),
          align: "left",
          sortable: false,
          value: "total_distance",
        },
        {
          text: this.$t("avg_speed"),
          align: "left",
          sortable: false,
          value: "total_avg_speed",
        },
        {
          text: this.$t("max_speed"),
          align: "left",
          sortable: false,
          value: "max_speed",
        },
        ...(vTech == "ev"
          ? [
              {
                text: this.$t("battery_consumed"),
                align: "left",
                sortable: false,
                value: "battery_consumed",
              },
            ]
          : []),
        ...(vTech == "ice"
          ? [
              {
                text: this.$t("fuel_consumption"),
                align: "left",
                sortable: false,
                value: "fuel_consumption",
              },
            ]
          : []),
      ];

      // computed headers for showing columns to role based
      return heads.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
    filterNames() {
      if (this.authUser.isAdmin) {
        return ["vehicle"];
      } else {
        return ["vehicle"];
      }
    },
    //
    summaryData() {
      let vTech = this.getVehilceTech;
      // Condition only for OEM
      if (!this.authUser.isAdmin && this.authUser.user_nature !== "fleet") {
        vTech = this.authUser.user_tech;
      }

      const arr = [];

      this.summary.forEach((el) => {
        const param = {
          name: el?.vehicle_variant_name,
          imei: el?.imei,
          total_journey_time: el?.total_journey_time,
          distance: el?.total_distance,
          max_speed: el?.max_speed,
          total_avg_speed: el?.total_avg_speed,
        };
        if (vTech == "ev") {
          param.battery_consumed = el?.battery_consumed;
        }
        if (vTech == "ice") {
          param.fuel_consumption = el?.fuel_consumption;
        }
        arr.push(param);
      });

      return arr;
    },
  },
  watch: {
    "options.vehicle_ids": {
      handler: function (v) {
        if (v.length < 0 || v.length == 0) {
          this.$store.commit("dailyMileageReports/SET_LIST", []);
          this.$store.commit("dailyMileageReports/SET_SUMMARY", []);
        }
      },
    },
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "daily_mileage_reports",
      filters: { ...this.options },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },

  methods: {
    async fetchData() {
      if (this.options?.vehicle_ids?.length > 0) {
        const payload = {
          dateType: this.options.dateType,
          date: this.options.date,
          vehicle_ids: this.options.vehicle_ids,
          // is_independent_fleet product fleetId oem_b2b_ids[] compare_route currency
        };
        await this.$store.dispatch("dailyMileageReports/summary", payload);
        const params = {
          ...this.options,
        };
        await this.$store
          .dispatch("dailyMileageReports/list", params)
          .then(() => {
            if (this.options.page > (this.meta?.lastPage || 1)) {
              this.options.page = 1;
            }
          });
      }
    },
    //
    exportList(val) {
      const module_name = "dailyMileageReports/exportList";
      this.downloadFile(module_name, val);
    },
    exportSummary(val) {
      const module_name = "dailyMileageReports/exportSummary";
      this.downloadFile(module_name, val);
    },

    //
    downloadFile(mod, val) {
      const params = {
        type: val,
        dateType: this.options.dateType,
        date: this.options.date,
        vehicle_ids: this.options.vehicle_ids,
      };
      this.$store
        .dispatch(mod, params)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("download_successfully")
          );
        })
        .catch(() => {
          this.$store.dispatch("alerts/error", this.$t("file_corrupted"));
        });
    },
  },
};
</script>
