<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      no-filters
      :is-filters-show="false"
      vertical-scroll
      @filterOption="onFiltersChange"
    >
      <template v-slot:stops="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <!-- v-if="item.stops" -->
              <div
                v-bind="attrs"
                class="col-stops"
                v-on="on"
              >
                {{ item.stops.join(", ") }}
              </div>
            </span>
          </template>
          <span>{{ item.stops.join(", ") }}</span>
        </v-tooltip>
        <div>
          <!-- {{ item.stops.join(", ") }} -->
        </div>
      </template>

      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('routes.edit')"
          class="mr-2"
          small
          @click="goToEdit(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('routes.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-col
          v-if="authUser.isAdmin"
          cols="3"
          sm="3"
        >
          <v-select
            v-model="subFilters.make_type"
            dense
            clearable
            :items="makeTypeList"
            item-text="name"
            item-value="id"
            :label="$t('select_mdmake_type')"
          />
        </v-col>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <v-btn
            v-if="$admin.can('routes.create')"
            color="primary"
            dark
            @click="goToCreate()"
          >
            {{ $t("add_new") }}
          </v-btn>

          <!-- <edit-md-make
            v-if="$admin.can('routes.edit') && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :md-make="selectedRow"
          /> -->
          <confirmation-modal
            v-if="$admin.can('routes.delete')"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    // EditMdMake: () => import("./dialogs/EditRouting.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},

      headers: [
        {
          text: this.$t("tenant_name"),
          align: "left",
          sortable: true,
          value: "tenant_name",
          showAdmin: true,
        },
        {
          text: this.$t("route_name"),
          align: "left",
          sortable: true,
          value: "route_name",
        },
        {
          text: this.$t("stops"),
          align: "left",
          // sortable: false,
          value: "stops",
        },
        {
          text: this.$t("start_location"),
          align: "left",
          sortable: false,
          value: "start_location_name",
          // showAdmin: true,
        },
        {
          text: this.$t("distance"),
          align: "left",
          sortable: false,
          value: "distance",
          // showAdmin: true,
        },
        {
          text: this.$t("created_by"),
          align: "left",
          sortable: false,
          value: "route_created_by",
        },
        {
          text: this.$t("created_at"),
          align: "left",
          sortable: false,
          value: "routed_created_at",
        },

        ...(this.$admin.hasAccessTo("routes.delete") ||
          this.$admin.hasAccessTo("routes.edit")
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "routeListing/getIsLoading",
      meta: "routeListing/getMeta",
      list: "routeListing/getList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  mounted() {
    this.$store.dispatch('routeListing/setLoadingValue', true)
  },
  beforeDestroy() {
    if (this.authUser.user_nature == "fleet") {
      this.options.tenant_id = this.authUser.tenant_id;
    }
    const params = {
      name: "route_listing",
      // filters: this.options,
      filters: {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
      },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      if (this.authUser.user_nature == "fleet") {
        this.options.tenant_id = this.authUser.tenant_id;
      }
      await this.$store.dispatch("routeListing/list", this.options).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    // Action Screens
    goToCreate() {
      this.$router.push({
        name: "route-create",
      });
    },
    goToEdit(row) {
      this.$router.push({
        name: "route-edit",
        params: { id: row.id },
      });
    },
    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("routeListing/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        if (this.list.length === 0 && this.meta.lastPage == this.options.page) {
          this.options.page -= 1; // Move to the previous page
        }
        this.toggleDeleteModal("close");
        this.fetchData();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.col-stops
  cursor: pointer
  white-space: nowrap
  width: 200px
  overflow: hidden
  text-overflow: ellipsis
</style>