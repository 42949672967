<template>
  <div>
    <l-map
      v-if="routeCoordinates.length > 0"
      id="map"
      ref="map"
      class="VueLeafMap"
      :zoom="zoom"
      :center="MapCenter"
      :options="mapOptions"
      :style="{ height: height }"
    >
      <!-- :bounds="bounds" -->
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <!-- <l-control-fullscreen
        position="topright"
        :options="{
          title: { false: 'Full Screen', true: 'Dashboard View' },
        }"
      /> -->

      <template v-for="(point, i) in mapMarkers">
        <l-moving-marker
          :key="`${i}-markers`"
          ref="driverMarker"
          :lat-lng="point"
          :icon="icon"
          :duration="10000"
        >
          <l-icon v-if="i != 0">
            <VehicleMapMarkers
              :name="vehicleModelType"
              :status="status"
              :body-type="vehicleBodyType"
            />
          </l-icon>
        </l-moving-marker>
        <!-- <l-marker
          v-if="i == 0"
          :key="`${point[0]}-${point[1]}-${i}`"
          ref="MapMarker"
          :lat-lng="point"
        /> -->
      </template>

      <!--  -->
      <l-polyline
        :lat-lngs="routeCoordinates"
        :color="'green'"
      />
    </l-map>
  </div>
</template>
<script>
import L from "leaflet";
import "leaflet.fullscreen"; // Import the fullscreen plugin
import "leaflet.fullscreen/Control.FullScreen.css";
import { latLngBounds } from "leaflet";
import { LMap, LTileLayer, LIcon, LPolyline } from "vue2-leaflet";
import LMovingMarker from "vue2-leaflet-movingmarker";

import { mapGetters } from "vuex";

import Vue from "vue";
import VueMqtt from "vue-mqtt";

Vue.use(VueMqtt, "wss://mqtt.flespi.io:443/", {
  clientId: "WebClient-" + parseInt(Math.random() * 100000),
  username: "H0i9Oy5cefXRGGdfNLZLpBt3s7h2h1aPRgOuDIuHLhniLonnXbWaCf3SbaJiZJVE",
});

const icon = L.icon({
  iconUrl:
    "https://s3-eu-west-1.amazonaws.com/ct-documents/emails/A-static.png",
  iconSize: [21, 31],
  iconAnchor: [10.5, 31],
  popupAnchor: [4, -25],
});

export default {
  components: {
    LMap,
    LTileLayer,
    // LMarker,
    LMovingMarker,
    LIcon,
    LPolyline,
    VehicleMapMarkers: () => import("@/components/base/VehicleMapMarkers.vue"),
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    optionFilters: {
      type: Object,
      default() {
        return {};
      },
    },
    vehicleModelType: {
      type: String,
      default() {
        return "";
      },
    },
    vehicleBodyType: {
      type: String,
      default() {
        return "";
      },
    },
    status: {
      type: String,
      default() {
        return "";
      },
    },
    height: {
      type: String,
      default() {
        return "365px";
      },
    },
  },
  data() {
    return {
      icon,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 13,
      mapOptions: {
        zoomSnap: 0.5,
        fullscreenControl: true, // Enable fullscreen control
        fullscreenControlOptions: {
          position: "bottomright", // Customize position (optional)
        },
      },
      topic: "",
      receviedData: [],
    };
  },
  computed: {
    ...mapGetters({
      vehicleList: "vehicles/getVehiclesList",
    }),
    routeCoordinates() {
      const arr = this.items.map((r) => [
        parseFloat(r.latitude),
        parseFloat(r.longitude),
      ]);
      return arr.concat(this.receviedData);
    },
    mapMarkers() {
      const len = this.routeCoordinates.length - 1;
      const arr = [];
      arr.push(this.routeCoordinates[0]);
      arr.push(this.routeCoordinates[len]);
      return arr;
    },
    MapCenter() {
      const len = this.routeCoordinates.length - 1;
      return this.routeCoordinates[len] ?? [24.8785, 67.0641];
    },
    bounds() {
      const len = this.routeCoordinates.length - 1;
      const r = this.routeCoordinates[len];

      return latLngBounds([r]);
    },
  },
  watch: {
    "optionFilters.vehicle_id": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          const oTopic = this.fetchDeviceId(oldVal);
          this.unsubscribeFromTopic(oTopic);
        }
        if (newVal) {
          // this.topic = this.fetchDeviceId(newVal);
        }
      },
      // immediate: true,
    },
  },
  mounted() {
    this.topic = this.fetchDeviceId(this.optionFilters.vehicle_id);
    this.subscribeToTopic(this.topic);
  },
  beforeDestroy() {
    this.unsubscribeFromTopic(this.topic);
  },
  methods: {
    subscribeToTopic(val) {
      this.$mqtt.subscribe(val);
    },
    unsubscribeFromTopic(val) {
      this.$mqtt.unsubscribe(val);
    },
    fetchDeviceId(vd) {
      const vr = this.vehicleList.find((r) => r.id == vd);
      return `flespi/message/gw/devices/${vr.device_id}`;
    },
  },
  mqtt: {
    // Handle messages from any topic
    "flespi/message/gw/devices/#"(data) {
      // Wildcard to capture all topics
      const msg = JSON.parse(new TextDecoder().decode(new Uint8Array(data)));
      const { position_latitude, position_longitude } = msg;

      this.receviedData.push([position_latitude, position_longitude]);
    },
  },
};
</script>
<style scoped></style>
