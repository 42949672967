import serviceADX from "@/helpers/service";

const state = {
  loading: false,
  list: [],
};

const mutations = {
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_LOADING_LIST(state, value) {
    state.loading = value;
  },
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
  list: (store, params) => {
    store.state.loading = true;
    return serviceADX
      .get("charging-station/csms-dashboard", params)
      .then((data) => {
        store.commit("SET_LIST", data.body);
        return data.body;
      })
      .finally(() => (store.state.loading = false));
  },
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  getList(state) {
    return state.list;
  },
};

const csmsDashboard = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default csmsDashboard;
