<template>
  <div>
    <v-btn
      v-if="$admin.can('vehicles.create')"
      color="primary"
      dark
      @click="openModal"
    >
      {{ $t("add_new") }}
    </v-btn>
    <v-dialog
      v-if="isVisibleDialog"
      v-model="isVisibleDialog"
      persistent
      max-width="600px"
    >
      <!-- <template v-slot:activator="{ on, attrs }"> </template> -->
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">{{ $t("add_new") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  v-if="authUser.isAdmin"
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="vehicle.product"
                    dense
                    :items="['oem', 'fleet']"
                    :label="$t('select_product_*')"
                    :rules="[rules.required]"
                    @change="() => {
                      delete vehicle.tenant_id;
                      // delete vehicle.user_type;
                    }
                    "
                  />
                </v-col>
                <v-col
                  v-if="authUser.isAdmin"
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="vehicle.tenant_id"
                    dense
                    :loading="tenantLoading"
                    :items="tenantsByProduct"
                    item-text="name"
                    item-value="id"
                    :label="$t('select_tenant_*')"
                    :rules="[rules.required]"
                    @change="onTenantChange"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="vehicle.md_make_id"
                    dense
                    :items="authUser.isAdmin
                      ? vehicle.tenant_id
                        ? mdMakesList
                        : []
                      : mdMakesList
                    "
                    :loading="isLoadingMdMakes"
                    item-value="id"
                    item-text="name"
                    :label="$t('ev_make_*')"
                    :rules="[rules.required]"
                    @change="onMakeChange"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="vehicle.md_model_id"
                    dense
                    :items="vehicle.md_make_id ? mdModelsList : []"
                    :loading="isLoadingMdModels"
                    item-value="id"
                    item-text="name"
                    :label="$t('ev_model_*')"
                    :rules="[rules.required]"
                    @change="onModelChange"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="vehicle.iot_device_id"
                    dense
                    :items="authUser.isAdmin
                      ? vehicle.tenant_id
                        ? iotDevicesList
                        : []
                      : iotDevicesList
                    "
                    :loading="isLoadingIotDevices"
                    item-value="id"
                    item-text="imei"
                    clearable
                    :label="$t('iot_device')"
                  />
                  <!-- :rules="[rules.required]" -->
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="vehicle.year"
                    dense
                    type="number"
                    :min="getStartingYear()"
                    :max="getCurrentYear()"
                    :label="$t('year_*')"
                    :rules="[
                      rules.required,
                      rules.min_year(),
                      rules.max_year(),
                    ]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="vehicle.registration_no"
                    dense
                    :label="$t('registration_no')"
                    :rules="authUser.isAdmin ? (vehicle.product === 'fleet' ? [rules.required] : [])
                      : (authUser.user_nature === 'fleet' ? [rules.required] : [])"
                  />
                  <!-- :rules="[rules.reg_num]" -->
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="vehicle.chassis_no"
                    dense
                    :label="$t('chassis_no')"
                    :rules="authUser.isAdmin ? (vehicle.product === 'oem' ? [rules.required] : [])
                      : (authUser.user_nature === 'oem' ? [rules.required] : [])"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="vehicle.motor_no"
                    dense
                    :label="$t('motor_no')"
                  />
                  <!-- :rules="[rules.required]" -->
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="vehicle.vehicle_tech"
                    dense
                    :items="['ev', 'ice']"
                    item-value="id"
                    item-text="name"
                    :label="$t('vehicle_tech_*')"
                    :rules="[rules.required]"
                    @change="changeVehicleTech"
                  />
                </v-col>
                <v-col
                  v-if="vehicle.vehicle_tech == 'ice'"
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="vehicle.fuel_sensor_installed"
                    dense
                    :items="['yes', 'no']"
                    item-value="id"
                    item-text="name"
                    clearable
                    :label="$t('fuel_sensor_installed_*')"
                    :rules="[rules.required]"
                    @change="changeFuelInstalled"
                  />
                </v-col>
                <template v-if="vehicle.fuel_sensor_installed == 'yes'">
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="vehicle.fuel_sensor_name"
                      dense
                      :items="['technoton', 'escort']"
                      item-value="id"
                      item-text="name"
                      :label="$t('fuel_sensor_name_*')"
                      :rules="[rules.required]"
                      @change="changeFuelSenorName"
                    />
                  </v-col>

                  <template v-if="vehicle.fuel_sensor_name == 'escort'">
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="vehicle.fuel1a0"
                        dense
                        type="number"
                        :label="`${$t('fuel')}1a0 (L) *`"
                        :rules="[rules.required, rules.float(7)]"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="vehicle.fuel1a1"
                        dense
                        type="number"
                        :label="`${$t('fuel')}1a1 (L) *`"
                        :rules="[rules.required, rules.float(7)]"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="vehicle.fuel1a2"
                        dense
                        type="number"
                        :label="`${$t('fuel')}1a2 (L) *`"
                        :rules="[rules.required, rules.float(7)]"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="vehicle.fuel2a0"
                        dense
                        type="number"
                        :label="`${$t('fuel')}2a0 (L) *`"
                        :rules="[rules.required, rules.float(7)]"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="vehicle.fuel2a1"
                        dense
                        type="number"
                        :label="`${$t('fuel')}2a1 (L) *`"
                        :rules="[rules.required, rules.float(7)]"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="vehicle.fuel2a2"
                        dense
                        type="number"
                        :label="`${$t('fuel')}2a2 (L) *`"
                        :rules="[rules.required, rules.float(7)]"
                      />
                    </v-col>
                  </template>

                  <!--  -->

                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="vehicle.tank_capacity"
                      dense
                      type="number"
                      :label="$t('tank_capacity_*')"
                      :rules="[rules.required, rules.float(7)]"
                    />
                  </v-col>
                </template>
                <!--  -->
                <template v-if="vehicle.vehicle_tech !== 'ice'">
                  <v-col
                    v-for="index in numberOfBatteries"
                    :key="index"
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="vehicle.serial_no[index - 1]"
                      dense
                      :label="`Battery ${index} - Serial No*`"
                      :rules="[
                        rules.required,
                        (value) =>
                          isUniqueSerialNumber(value, index - 1) ||
                          'Serial numbers must be unique',
                      ]"
                    />
                  </v-col>
                </template>

                <!--  -->
                <v-col cols="6">
                  <v-checkbox
                    v-model="vehicle.send_data_to_third_party"
                    hide-details
                    :ripple="false"
                    :label="$t('send_data_to_third_party')"
                    :value="1"
                  />
                </v-col>
                <template v-if="vehicle.send_data_to_third_party">
                  <v-col cols="6">
                    <v-select
                      v-model="vehicle.third_party_ids"
                      dense
                      :loading="isThirdParty"
                      :items="thirdPartList"
                      item-value="id"
                      item-text="title"
                      :label="$t('select_third_party_*')"
                      multiple
                      :rules="[rules.multi_select_required]"
                    />
                  </v-col>
                </template>
              </v-row>
              <div
                v-if="
                  !authUser.isAdmin || (vehicle.product && authUser.isAdmin)
                "
              >
                <v-row>
                  <v-col cols="12">
                    <span class="text-h3">{{ $t("assign-customers") }}</span>
                    <v-row>
                      <v-col cols="6">
                        <v-checkbox
                          v-model="vehicle.customerType"
                          hide-details
                          :ripple="false"
                          :label="$t('existing-customer')"
                          :value="'assignCustomer'"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox
                          v-model="vehicle.customerType"
                          hide-details
                          :ripple="false"
                          :label="$t('new-customer')"
                          :value="'newCustomer'"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- {{ vehicle.customerID }} -->
                <v-expand-transition>
                  <template v-if="vehicle.customerType == 'newCustomer'">
                    <customer-form @customerDetail="customerDetailChange" />
                  </template>
                  <v-row
                    v-else-if="vehicle.customerType == 'assignCustomer'"
                    transition="scroll-y-transition"
                  >
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="oemCustomer.user_type"
                        dense
                        :items="userTypeOption"
                        item-value="value"
                        item-text="name"
                        :label="$t('user_type')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col
                      v-if="oemCustomer.user_type == 'business'"
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="oemCustomer.oem_b2b_id"
                        dense
                        :loading="isLoadingoemB2bList"
                        :items="oemB2bList"
                        item-value="id"
                        item-text="business_name"
                        clearable
                        :label="$t('businesses')"
                      />
                    </v-col>
                    <v-col
                      v-if="oemCustomer.user_type || oemCustomer.oem_b2b_id"
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="oemCustomer.customerID"
                        dense
                        :items="oemCustomersList"
                        :loading="isLoadingOemCustomers"
                        item-value="id"
                        item-text="name"
                        :label="$t('existing-customer')"
                        :rules="[rules.required]"
                      />
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="isLoadingSave"
              @click="saveItem(vehicle, oemCustomer)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  components: {
    customerForm: () => import("./CreateCustomerForm.vue"),
  },
  mixins: [validationRules],
  props: {
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isVisibleDialog: false,
      isLoadingSave: false,
      // customerType: null,
      oemCustomer: {},
      vehicle: {
        serial_no: [], // Initialize an empty array for serial_no
        send_data_to_third_party: 0,
      },
      userTypeOption: [
        { name: "Individual", value: "individual" },
        // { name: "Business", value: "business" },
        { value: "internal", name: "Internal" },
      ],
    };
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
      iotDevicesList: "iotDevices/getIotDevicesList",
      isLoadingIotDevices: "iotDevices/getIsLoadingIotDevices",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsFLEET: "tenantsManagement/getTenantsFLEET",
      isLoadingOemCustomers: "oemCustomers/getIsLoadingOemCustomers",
      oemCustomersList: "oemCustomers/customerLists",
      mdMakesList: "mdMakes/activelistMdMakes",
      isLoadingMdMakes: "mdMakes/isLoadingMdMakes",
      mdModelsList: "mdModels/activelistMdModels",
      isLoadingMdModels: "mdModels/isLoadingMdModels",
      oemB2bList: "oemBusiness/getOEMB2BList",
      isLoadingoemB2bList: "oemBusiness/getIsLoading",
      //
      isThirdParty: "vehicles/isThirdParty",
      thirdPartList: "vehicles/getThirdPartyList",
    }),
    tenantsByProduct() {
      if (this.vehicle?.product) {
        if (this.vehicle.product == "oem") {
          return this.tenantsOEM;
        } else {
          return this.tenantsFLEET;
        }
      } else {
        return [];
      }
    },
    numberOfBatteries() {
      var mdModel = this.mdModelsList.find(
        (item) => item.id === this.vehicle.md_model_id
      );
      if (mdModel) {
        return mdModel.no_of_batteries;
      }
      return false;
    },
  },

  watch: {
    isVisibleDialog(v) {
      if (this.authUser.isAdmin && v) {
        // this.vehicle.products = "oem";
      }
    },

    "vehicle.product": {
      handler: async function (value) {
        if (value && this.authUser.isAdmin) {
          if (value == "oem") {
            this.$store.commit("mdMakes/SET_MAKE", []);
          } else {
            this.$store.dispatch("mdMakes/list");
          }
        }
      },
    },

    // "vehicle.tenant_id": {
    //   handler: async function (value) {
    //     delete this.vehicle.md_make_id;
    //     delete this.vehicle.md_model_id;
    //     if (value && this.authUser.isAdmin) {
    //       if (this.vehicle.products == "OEM") {
    //         this.$store.dispatch("mdMakes/list", { tenant_id: value ?? false });
    //       }
    //       this.$store.dispatch("iotDevices/list", {
    //         tenant_id: value ?? false,
    //         vehicle_assigned: false,
    //       });
    //       this.$store.dispatch("oemBusiness/list", {
    //         tenant_id: value ?? false,
    //       });
    //       this.oemCustomer.user_type = null;
    //     }
    //   },
    // },
    // "vehicle.md_make_id": {
    //   handler: async function (value) {
    //     if (value) {
    //       await this.$store.dispatch("mdModels/list", {
    //         md_make_id: value ?? false,
    //       });
    //     }
    //   },
    // },
    // "vehicle.md_model_id": {
    //   handler: async function (value) {
    //     this.vehicle.serial_no = [];
    //     var mdModel = this.mdModelsList.find((item) => item.id === value);
    //     if (mdModel) {
    //       this.vehicle.battery_slot_type = mdModel.battery_slot_type;
    //     }
    //   },
    // },
    "vehicle.customerType": {
      handler: function () {
        this.oemCustomer = {};
      },
    },
    "oemCustomer.user_type": {
      handler: function (value) {
        // if (value == "individual") {
        if (value) {
          delete this.oemCustomer.oem_b2b_id;
          if (this.authUser.isAdmin) {
            this.$store.dispatch("oemCustomers/list", {
              tenant_id: this.vehicle.tenant_id ?? false,
              user_type: value,
              vehicle_assigned: false,
            });
          } else {
            this.$store.dispatch("oemCustomers/list", {
              vehicle_assigned: false,
              user_type: value,
            });
          }
        }
      },
    },
    "oemCustomer.oem_b2b_id": {
      handler: function (value) {
        this.$store.dispatch("oemCustomers/list", {
          oem_b2b_id: value,
        });
      },
    },
    "vehicle.send_data_to_third_party": {
      handler: async function (val) {
        if (val) {
          await this.$store.dispatch("vehicles/thirdPartiesList");
        } else {
          this.vehicle.send_data_to_third_party = 0;
          delete this.vehicle.third_party_ids;
        }
      },
    },
  },
  async mounted() {
    if (!this.authUser.isAdmin) {
      await this.$store.dispatch("mdMakes/list", {
        tenant_id: this.authUser.tenant_id,
      });
    }
  },
  methods: {
    openModal() {
      // this.$store.dispatch("oemCustomers/list", { vehicle_assigned: false });
      if (!this.authUser.isAdmin) {
        this.$store.dispatch("iotDevices/list", { vehicle_assigned: false });
      }
      this.isVisibleDialog = true;
    },

    closeForm() {
      this.$refs.form.reset();
      this.isVisibleDialog = false;
    },
    async saveItem(vehicle, customer) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        vehicle = { ...vehicle, customer };
        // const params = {
        //   vehicle,
        //   filter: { ...this.filterOptions, isAdmin: this.authUser.isAdmin },
        // };
        await this.$store
          .dispatch("vehicles/add", vehicle)
          .then(() => {
            this.$store.dispatch("vehicles/list", this.filterOptions);
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.isLoadingSave = false;
            this.closeForm();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
        await this.$store.dispatch("iotDevices/list", {
          vehicle_assigned: false,
        });
        await this.$store.dispatch("oemCustomers/list", {
          vehicle_assigned: false,
        });
      }
    },
    isUniqueSerialNumber(serialNumber, index) {
      const duplicates = this.vehicle.serial_no.filter(
        (sn, i) => sn === serialNumber && i !== index
      );
      return duplicates.length === 0;
    },
    customerDetailChange(row) {
      this.oemCustomer = row;
    },
    //
    async onTenantChange(v) {
      delete this.vehicle.md_make_id;
      delete this.vehicle.md_model_id;
      if (v && this.authUser.isAdmin) {
        // if (this.vehicle.product == "oem") {
        await this.$store.dispatch("mdMakes/list", { tenant_id: v ?? false });
        // }
        await this.$store.dispatch("iotDevices/list", {
          tenant_id: v ?? false,
          vehicle_assigned: false,
        });
        await this.$store.dispatch("oemBusiness/list", {
          tenant_id: v ?? false,
        });
        this.oemCustomer.user_type = null;
      }
    },
    async onMakeChange(v) {
      delete this.vehicle.md_model_id;
      if (v) {
        await this.$store.dispatch("mdModels/list", {
          md_make_id: v ?? false,
        });
      }
    },
    onModelChange(v) {
      this.vehicle.serial_no = [];
      const mdModel = this.mdModelsList.find((item) => item.id === v);
      if (mdModel) {
        this.vehicle.battery_slot_type = mdModel.battery_slot_type;
      }
    },

    //
    changeVehicleTech() {
      delete this.vehicle.fuel_sensor_installed;
      this.changeFuelInstalled();
    },
    changeFuelInstalled() {
      delete this.vehicle.fuel_sensor_name;
      this.changeFuelSenorName();
    },
    changeFuelSenorName(v) {
      if (v == "escort") {
        this.vehicle.fuel1a0 = "0";
        this.vehicle.fuel1a1 = "0";
        this.vehicle.fuel1a2 = "0";
        //
        this.vehicle.fuel2a0 = "0";
        this.vehicle.fuel2a1 = "0";
        this.vehicle.fuel2a2 = "0";
      } else {
        delete this.vehicle.fuel1a0;
        delete this.vehicle.fuel1a1;
        delete this.vehicle.fuel1a2;
        //
        delete this.vehicle.fuel2a0;
        delete this.vehicle.fuel2a1;
        delete this.vehicle.fuel2a2;
      }
    },
    checkMM(val) {
      return (v) => {
        if (val) {
          if (parseInt(v) > parseInt(val)) {
            return true;
          } else {
            return `Should be greater than ${val}`;
          }
        } else {
          return "should be less than Maintenance Mileage";
        }
      };
    },
  },
};
</script>