<template>
  <v-container fluid>
    <div>
      <header-filters
        class-name="dashboard"
        :filter-names="['search', 'category', 'customer']"
        search-label="search"
        @onChange="onFiltersChange"
      />
    </div>
    <!-- 
    

      <v-col
        cols="3"
        md="3"
        sm="12"
      >
        <v-card class="filter-card">
          <v-select
            v-model="options.category"
            dense
            value="asdas"
            clearable
            :items="ticketCategoryLists"
            :label="$t('category')"
            @change="fetchData"
          >
            <template
              slot="selection"
              slot-scope="data"
            >
              {{ data.item.replace(/_/g, " ") }}
            </template>
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
              >
                {{ item.replace(/_/g, " ") }}
              </v-list-item>
            </template>
          </v-select>
        </v-card>
      </v-col>

      
   -->

    <!-- Kanban Board -->
    <section>
      <v-card
        elevation="4"
        class="py-3 mt-7"
      >
        <kanban-board
          v-if="!isKanbanReload"
          :stages="stages"
          :config="config"
          :blocks="listSupportTickets"
          class="help-kanban"
          @update-block="updateTicket"
        >
          <div
            v-for="stage in stages"
            :slot="stage"
            :key="stage"
          >
            <h2>{{ stage.replace(/_/g, " ") }}</h2>
          </div>
          <div
            v-for="block in listSupportTickets"
            :slot="block.id"
            :key="block.id"
            class="ticket-card"
          >
            <div class="header-wrapper">
              <div class="title">
                <strong>
                  {{ block.reference_code }}
                  <!-- v-if="block.ticket_attachments_count" -->
                  <v-btn
                    small
                    class="action_btn mr-10 cat-btn"
                  >
                    {{ block.category }}
                  </v-btn>
                </strong>
              </div>
              <div class="author">
                {{ block.customer }} - {{ block.ticket_created_at }} ago
              </div>
            </div>

            <div class="description-area">
              <div class="description-wrapper">
                {{ block.description }}
              </div>
              <div class="btn-groups">
                <v-btn
                  v-if="block.status == 'pending' || block.status == 'resolved'"
                  class="mr-2 action_btn"
                  small
                  @click="viewItem(block)"
                >
                  <v-icon> mdi-eye </v-icon>
                </v-btn>
                <v-btn
                  v-if="block.status == 'in_progress'"
                  class="mr-2 action_btn"
                  small
                  @click="editItem(block)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>

                <v-btn
                  v-if="block.is_appointment_created"
                  class="attach-action mr-10 calendar-btn"
                  small
                >
                  <v-icon> mdi-calendar </v-icon>
                </v-btn>
                <v-btn
                  v-if="block.ticket_attachments.length > 0"
                  small
                  class="attach-action"
                >
                  <v-icon> mdi-attachment </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </kanban-board>
      </v-card>
      <edit-ticket
        v-if="isModalOpen"
        :is-visible-dialog="isModalOpen"
        :ticket="isSelectedTicket"
        :is-view-mode="isModalViewMode"
        @closeModal="closeModal"
      />
    </section>
  </v-container>
</template>
<style scoped>
@import "./css/support.css";
</style>
<script>
import { mapGetters } from "vuex";
// import { debounce } from "lodash";

export default {
  components: {
    HeaderFilters: () => import("@/layouts/section/MainFilters.vue"),
    EditTicket: () => import("./dialogs/EditTicket.vue"),
    // CreateDevice: () => import("./dialogs/CreateOemCustomer.vue"),
    // EditTicket: () => import("./dialogs/EditOemCustomer.vue"),
    // DeleteDevice: () => import("./dialogs/DeleteOemCustomer.vue"),
  },

  data() {
    return {
      isModalOpen: false,
      isSelectedTicket: {},
      isModalViewMode: false,
      deleteTicketModal: false,
      isKanbanReload: false,
      options: {},
      stages: ["pending", "in_progress", "resolved"],
      userTypeOptions: ["individual", "business", "internal"],

      config: {
        accepts(block, target, source) {
          if (
            source.dataset.status === "resolved" ||
            (source.dataset.status === "pending" &&
              target.dataset.status === "resolved")
          ) {
            return false;
          }

          return true;
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      // tenantLoading: "tenantsManagement/getIsLoading",
      // tenantsList: "tenantsManagement/getTenantOptions",
      //
      // oemB2bList: "oemBusiness/getOEMB2BList",
      //
      listSupportTickets: "supportTickets/getSupportTicketsList",
      supportTicketsMeta: "supportTickets/getSupportTicketsMeta",
      isLoadingSupportTickets: "supportTickets/getIsLoadingSupportTickets",
      ticketStatusLists: "supportTickets/ticketStatusLists",
      // ticketCategoryLists: "supportTickets/ticketCategoryLists",
    }),
  },

  async mounted() {
    // if (this.authUser.user_nature == "fleet") {
    // }
    // // if Query params
    // // for Search
    // if (this.$route?.query?.search) {
    //   this.options.search = this.$route.query.search;
    // }
    // if (this.$route?.query?.category) {
    //   this.options.category = this.$route.query.category;
    // }
    // //
    // await this.$store.dispatch("supportTickets/list", this.options);
  },
  beforeDestroy() {
    const params = {
      name: "support_tickets",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store
        .dispatch("supportTickets/list", this.options)
        .then(() => {
          if (this.options.page > (this.supportTicketsMeta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
    },
    //
    onFiltersChange(v) {
      if (v.tenant_id !== this.options.tenant_id) {
        delete this.options.md_model_id;
      }
      delete this.options.product;
      delete this.options.fleetId;
      delete this.options.oem_b2b_ids;
      delete this.options.tenant_id;
      this.options = { ...this.options, ...v };
      // this.options = { ...v };
      // if (v.tenant_id) {
      //   this.onTenantChange();
      // }
      this.fetchData();
    },
    //
    viewItem(item) {
      this.isSelectedTicket = { ...item };
      this.isModalViewMode = true;
      this.isModalOpen = true;
    },
    editItem(item) {
      this.isSelectedTicket = { ...item };
      this.isModalOpen = true;
    },
    closeModal() {
      this.isSelectedTicket = {};
      this.isModalViewMode = false;
      this.isModalOpen = false;
    },
    // toggleDeleteModal() {
    //   return (this.deleteTicketModal = !this.deleteTicketModal);
    // },
    // deleteItem(item) {
    //   this.isSelectedTicket = { ...item };
    //   this.toggleDeleteModal();
    // },
    //
    async updateTicket(id, status) {
      var supportTicket = this.listSupportTickets.find(
        (b) => b.id === Number(id)
      );

      var updateTicket = {
        id: supportTicket.id,
        statusFrom: supportTicket.status,
        statusTo: status,
      };

      await this.$store
        .dispatch("supportTickets/update", updateTicket)
        .then(() => {
          this.listSupportTickets.find((b) => b.id === Number(id)).status =
            status;

          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
        })
        .catch(async (error) => {
          // When Api Faild Its move back ticket to the Previous Column
          this.isKanbanReload = true;
          setTimeout(() => {
            this.isKanbanReload = false;
          }, 0);
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          await this.$store.dispatch("supportTickets/list", this.options);
        });
    },

    //
    onCustomerChange(v) {
      delete this.options.oem_b2b_id;
      if (v == "business") {
        const params = {};
        if (!this.authUser.isAdmin) {
          params.id = this.authUser.tenant_id;
        }

        this.getOemB2B(params?.id);
      }
      this.fetchData();
    },
    getOemB2B(id) {
      this.$store.dispatch("oemBusiness/list", {
        tenant_id: id,
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.filter-card
  padding-left: 1rem
  padding-right: 1rem
  padding-top: 2rem
  height: 5rem
</style>

<style>
.v-list-item {
  text-transform: capitalize !important;
}

.v-select__selections {
  text-transform: capitalize !important;
}
</style>
