<template>
  <div>
    <v-dialog
      v-model="isVisibleDialog"
      scrollable
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('users.create')"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="!isVisibleDialog"
        >
          {{ $t("add_new") }}
        </v-btn>
      </template>

      <v-form
        v-if="isVisibleDialog"
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">{{ $t("add_new") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  v-if="authUser.isAdmin"
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="oem.tenant_type"
                    dense
                    :items="productOptions"
                    item-value="slug"
                    item-text="name"
                    :label="$t('tenant_type_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  v-if="oem.tenant_type == 'fleet'"
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="oem.is_independent"
                    dense
                    :items="fleetTypeOptions"
                    item-value="id"
                    item-text="name"
                    :label="$t('fleet_type_*')"
                    :rules="[rules.required]"
                    @change="() => delete oem.oem_b2b_ids"
                  />
                </v-col>
                <v-col
                  v-if="oem.tenant_type == 'fleet' && oem.is_independent == '0'"
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="oem.oem_b2b_ids"
                    dense
                    :items="oemBusinessList"
                    item-value="id"
                    item-text="tenant_name"
                    :label="$t('business_name_*')"
                    :rules="[rules.required]"
                    multiple
                  />
                </v-col>
                <v-col
                  v-if="oem.tenant_type != 'cso'"
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="oem.vehicle_tech"
                    dense
                    :items="['ev', 'ice']"
                    :label="$t('select_tenant_tech_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.name"
                    dense
                    :label="$t('name_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.email"
                    dense
                    :label="$t('email_*')"
                    :rules="[rules.required, rules.email]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="oem.country_code"
                    dense
                    :items="countryCodeLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('country_code_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.contact_no"
                    dense
                    type="number"
                    :label="$t('contact_*')"
                    :rules="[
                      rules.required,
                      rules.startFrom(3),
                      rules.min_length(10),
                      rules.max_length(10),
                    ]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.address"
                    dense
                    :label="$t('address_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.no_of_allowed_users"
                    dense
                    type="number"
                    :min="3"
                    :label="$t('no_of_users_*')"
                    :rules="[rules.min_quantity(3)]"
                  />
                </v-col>
                <!-- v-if="oem.tenant_type == 'fleet'" -->
                <v-col
                  v-if="oem.tenant_type != 'cso'"
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.over_speed_limit"
                    dense
                    type="number"
                    :label="$t('over_speed_limit_*')"
                    :rules="[
                      rules.required,
                      rules.min_quantity(40),
                      rules.max_quantity(120),
                    ]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="oem.is_active"
                    dense
                    :items="statusLists"
                    item-value="id"
                    item-text="name"
                    :label="$t('status_*')"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-file-input
                    v-model="oem.logo"
                    dense
                    accept="image/png, image/jpeg, image/bmp"
                    prepend-icon="mdi-camera"
                    :label="$t('business_logo_*')"
                    :rules="[rules.required, rules.file_size]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                >
                  <span class="text-h3">Admin</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.user_name"
                    dense
                    :label="$t('poc_name_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="oem.user_email"
                    dense
                    :label="$t('poc_email_*')"
                    :rules="[rules.required, rules.email]"
                  />
                </v-col>
              </v-row>
              <product-with-tiers
                :item-list="oem.productTiers"
                @change="onChangeProduct"
              />
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="isLoadingSave"
              @click="saveItem(oem)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  components: {
    ProductWithTiers: () =>
      import("../../../../components/sections/ProductWithTiers.vue"),
  },
  mixins: [validationRules],
  props: {
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isVisibleDialog: false,
      isLoadingSave: false,
      productOptions: [
        { slug: "oem", name: "OEM" },
        { slug: "fleet", name: "Fleet" },
        { slug: "cso", name: "CSO" },
      ],
      oem: {
        no_of_allowed_users: "3",
        oem_b2b_ids: undefined,
        productTiers: [
          {
            product_id: null,
          },
        ],
      },
      fleetTypeOptions: [
        { id: "0", name: "Dependent" },
        { id: "1", name: "Independent" },
      ],
      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
      countryCodeLists: [
        {
          id: "+92",
          name: "+92",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      oemBusinessList: "oemBusiness/getOEMB2BList",
      productList: "products/getProductList",
    }),
  },
  watch: {
    "oem.tenant_type": {
      handler: async function (newVal, oldVal) {
        // delete this.oem.over_speed_limit;
        if (newVal != oldVal) {
          delete this.oem.is_independent;
          this.oem.oem_b2b_ids = undefined;
          const selectedPro = this.productList.find((r) => r.slug == newVal);
          this.oem.productTiers[0].product_id = selectedPro?.id;
        }
        // if (newVal == "fleet") {
        this.oem.over_speed_limit = 60;
        // }
      },
      deep: true,
    },
  },
  async beforeCreate() {
    await this.$store.dispatch("products/list");
  },
  methods: {
    closeForm() {
      const usersAccount = this.oem.no_of_allowed_users;
      this.$refs.form.reset();
      this.oem.no_of_allowed_users = usersAccount;
      this.isVisibleDialog = false;
    },
    async saveItem() {
      if (this.oem.tenant_type == "oem") {
        delete this.oem.oem_b2b_ids;
      }
      if (this.oem.tenant_type == "cso") {
        delete this.oem.oem_b2b_ids;
        this.oem.over_speed_limit = 0;
        this.oem.vehicle_tech = 'ev';
      }
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        await this.$store
          .dispatch("tenantsManagement/add", this.oem)
          .then(() => {
            //
            this.$store.dispatch("tenantsManagement/list", this.filterOptions);
            //
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.isLoadingSave = false;
            this.closeForm();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
    onChangeProduct(product_tier_ids) {
      this.oem = { ...this.oem, product_tier_ids };
    },
  },
};
</script>