<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="800px"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t("edit") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="mdModel.name"
                  dense
                  :label="$t('name_*')"
                  :rules="[rules.required, rules.max_length(50)]"
                />
              </v-col>

              <!-- <v-col
                v-if="authUser.isAdmin"
                cols="12"
                sm="4"
              >
                <v-autocomplete
                  v-model="mdModel.tenant_id"
                  dense
                  disabled
                  :loading="isLoadingClient"
                  :items="clientsList"
                  item-text="name"
                  item-value="id"
                  :label="$t('select_tenant_*')"
                  :rules="[rules.required]"
                />
              </v-col> -->

              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  v-model="mdModel.md_make_id"
                  dense
                  :items="mdMakesList"
                  disabled
                  item-value="id"
                  item-text="name"
                  :label="$t('ev_make_*')"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  v-model="mdModel.battery_make_id"
                  dense
                  :items="batteryMakesList"
                  :loading="isLoadingBatteryMakesList"
                  disabled
                  item-value="id"
                  item-text="name"
                  :label="$t('battery_make_*')"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  v-model="mdModel.battery_model_id"
                  dense
                  :items="batteryModelsList"
                  :loading="isLoadingBatteryModels"
                  disabled
                  item-value="id"
                  item-text="name"
                  :label="$t('battery_model_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  v-model="mdModel.is_active"
                  dense
                  :items="statusLists"
                  item-value="id"
                  item-text="name"
                  :label="$t('status_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <!-- <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  v-model="mdModel.vehicle_type_id"
                  dense
                  :items="vehicleTypesList"
                  item-text="name"
                  item-value="id"
                  :label="$t('vehicle_type_*')"
                  :rules="[rules.required]"
                />
              </v-col> -->
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  v-model="mdModel.motor_type_id"
                  dense
                  :items="motorTypesList"
                  item-text="name"
                  item-value="id"
                  :label="$t('motor_type_*')"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  v-model="mdModel.battery_slot_type"
                  dense
                  disabled
                  :items="batterySlotTypesList"
                  item-text="name"
                  item-value="id"
                  :label="$t('battery_slot_type_*')"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  v-model="mdModel.body_type"
                  dense
                  :items="bodyTypeList"
                  item-text="name"
                  item-value="id"
                  :label="$t('body_type_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="mdModel.start_year"
                  dense
                  type="number"
                  :min="getStartingYear()"
                  :max="getCurrentYear()"
                  :label="$t('start_year_*')"
                  :rules="[rules.required, rules.min_year(), rules.max_year()]"
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="mdModel.end_year"
                  dense
                  type="number"
                  :min="getStartingYear()"
                  :max="getCurrentYear()"
                  :label="$t('end_year')"
                  :rules="[endYearRule(mdModel.start_year, mdModel.end_year)]"
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="mdModel.no_of_batteries"
                  dense
                  disabled
                  type="number"
                  :label="$t('no_of_batteries_*')"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="mdModel.motor_power"
                  dense
                  type="number"
                  :label="$t('motor_power') + ' *'"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="mdModel.battery_capacity"
                  dense
                  type="number"
                  :label="$t('battery_capacity') + ' *'"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="mdModel.fuel_economy"
                  dense
                  type="number"
                  :label="$t('per_km_cost') + ' *'"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="mdModel.comp_fuel_avg_kmpl"
                  dense
                  type="number"
                  :label="$t('fuel_economy') + ' *'"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  v-model="mdModel.mileage"
                  dense
                  type="number"
                  :label="$t('mileage_*')"
                  :rules="[
                    rules.required,
                    rules.max_quantity(100),
                    rules.min_quantity(0),
                  ]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-file-input
                  v-model="mdModel.image"
                  dense
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  :label="$t('image_*')"
                  :rules="[rules.file_size]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-img
                  lazy-src="https://picsum.photos/id/11/10/6"
                  max-height="100"
                  max-width="100"
                  :src="mdModel.image_url"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <small>{{ $t("indicates_required_field") }}</small>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="toggleEdit()"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="isLoadingSave"
            @click="updateItem(mdModel)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    isVisibleDialog: Boolean,
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    mdModel: {
      type: Object,
      default() {
        return {};
      },
    },
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isLoadingSave: false,
      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
      bodyTypeList: [
        {
          id: "bike",
          name: "Bike",
        },
        {
          id: "loader",
          name: "Loader",
        },
        {
          id: "sedan",
          name: "Sedan",
        },
        {
          id: "hatchback",
          name: "Hatchback",
        },
        {
          id: "suv",
          name: "SUV",
        },
        {
          id: "truck",
          name: "Truck",
        },
        {
          id: "bus",
          name: "Bus",
        },
      ],
      batterySlotTypesList: [
        {
          id: "fixed",
          name: "Fixed",
        },
        {
          id: "swappable",
          name: "Swappable",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoadingClient: "meta/isLoadingClient",
      clientsList: "meta/clientsList",
      vehicleTypesList: "vehicleTypes/vehicleTypesList",
      motorTypesList: "motorTypes/motorTypesList",
      mdMakesList: "mdMakes/activelistMdMakes",
      isLoadingBatteryMakesList: "batteryMakes/isLoadingBatteryMakesList",
      batteryMakesList: "batteryMakes/batteryMakesList",
      batteryModelsList: "batteryModels/batteryModelsList",
      isLoadingBatteryModels: "batteryModels/isLoadingBatteryModels",
    }),
  },

  watch: {
    "mdModel.tenant_id": {
      handler: async function (value) {
        this.$store.dispatch("batteryMakes/list", { tenant_id: value });
        this.$store.dispatch("mdMakes/list", { tenant_id: value });
      },
      immediate: true,
    },
    "mdModel.battery_make_id": {
      handler: async function (value) {
        await this.$store.dispatch("batteryModels/list", {
          battery_make_id: value,
        });
      },
    },
    isVisibleDialog: {
      async handler(newValue) {
        if (newValue) {
          await this.$store.dispatch("batteryModels/list", {
            battery_make_id: this.mdModel.battery_make_id,
          });
        }
      },
      immediate: true,
    },
  },
  mounted() { },
  methods: {
    async updateItem(mdModel) {
      if (this.$refs.form.validate()) {
        const params = { mdModel, filter: { ...this.filterOptions } };

        this.isLoadingSave = true;
        await this.$store
          .dispatch("mdModels/update", params)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
            this.isLoadingSave = false;
            this.toggleEdit();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
    // endYearRule(value) {
    //   const startYear = parseInt(this.mdModel.start_year);
    //   const endYear = parseInt(value);
    //   if (value && value.length > 4) {
    //     return "Max 4 characters";
    //   } else if (!isNaN(startYear) && !isNaN(endYear) && endYear < startYear) {
    //     return "End year should not be less than start year.";
    //   }
    //   return true;
    // },
    // preSetData() {
    //   this.$store.dispatch("batteryMakes/list", {
    //     client_id: this.authUser.id,
    //   });
    // },
  },
};
</script>