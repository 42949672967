<template>
  <div>
    <div
      v-if="!hasData"
      class="noSemiDataDonut"
    >
      <v-img
        src="@/assets/semi-circle.png"
        width="300"
        contain
        class="semiNoData"
      />
    </div>

    <apexchart
      v-else
      ref="apexchart"
      type="donut"
      :options="chartOptions"
      :series="series"
      :height="height"
      :width="width"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "SemiPieChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    height: {
      type: [String, Number],
      default() {
        return 300;
      },
    },
    width: {
      type: [String, Number],
      default() {
        return "100%";
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    percentage: {
      type: Array,
      default() {
        return [];
      },
    },
    titles: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      // series: [130, 40, 90], // Example data for Parking, Idling, Driving in minutes
      cOptions: {
        chart: {
          type: "donut",
        },
        labels: [], // Custom labels for each section
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
            offsetY: 10, // Offset to create the semi-pie effect
          },
        },
        colors: ["#5D87FF", "#13DEB9", "#FFAE20"],
        dataLabels: {
          enabled: true,
          formatter: (val, opts) =>
            `${opts.w.config.series[opts.seriesIndex]} min`,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value) => {
              const hours = Math.floor(value / 60);
              const minutes = value % 60;
              return `${hours > 0 ? `${hours} hr ` : ""}${minutes} min`;
            },
          },
        },
        legend: {
          position: "bottom",
          labels: {
            colors: ["#5D87FF", "#13DEB9", "#FFAE20"], // Colors for legend labels
          },
          onItemHover: {
            highlightDataSeries: false, // Disable hover effect on series when hovering over legend
          },
          formatter: (seriesName, opts) => {
            const total = this.data.reduce((sum, value) => sum + value, 0);
            const percentage = (
              (opts.w.config.series[opts.seriesIndex] / total) *
              100
            ).toFixed(2);
            return `${seriesName}: ${percentage}%`;
          },
        },
        grid: {
          padding: {
            bottom: -80,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        states: {
          hover: {
            filter: {
              type: "darken",
              value: 0.7, // Darken the non-hovered parts
            },
            color: "#0000FF", // Change the hovered segment to blue
          },
        },
      },
    };
  },
  computed: {
    hasData() {
      return this.data.some((value) => value > 0);
    },
    series() {
      return this.data;
    },
    chartOptions() {
      const obj = this.cOptions;
      obj.labels = this.titles;
      return obj;
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .noDataDonut
  height: 300px
  display: flex
  justify-content: center
</style>
<style>
/* Optional styling */
#chart {
  width: 100%;
}
</style>
