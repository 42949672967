import axios from "@/helpers/axios";
const base_url = process.env.VUE_APP_API_BASEURL;


const state = {
    isLoading: false,
    orderListing: [],
    meta: {},

};

const mutations = {
    SET_LOADING_LIST(state, value) {
        state.isLoading = value;
    },
    SET_ORDER_LISTING(state, payload) {
        state.orderListing = payload;
    },
    SET_META(state, data) {
        if (data.meta) {
            state.meta.page = data.meta.current_page;
            state.meta.itemsPerPage = data.meta.per_page;
            state.meta.lastPage = data.meta.last_page;
            state.meta.totalItems = data.meta.total;
        }
    },
};

const actions = {
    setLoadingValue: (store, value) => {
        store.commit("SET_LOADING_LIST", value);
    },
    list: (store, params) => {
        // store.state.isLoading = true;
        return axios.get(`${base_url}get-orders-list`, { params }).then((data) => {
            store.commit("SET_ORDER_LISTING", data.data.body.data);
            store.commit("SET_META", data.data.body);
            return data.data.body.data;
        })
            .finally(() => (store.state.isLoading = false));
    },
};

const getters = {
    getOrderList(state) {
        return state.orderListing;
    },
    getIsLoading(state) {
        return state.isLoading;
    },
    meta(state) {
        return state.meta;
    },

};

const orderManagementData = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default orderManagementData;
