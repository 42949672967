import service from "../services/customer-vehicles-service";
const state = {
  isLoadingCustomerVehicles: false,
  listCustomerVehicles: [],
  customerVehicle: {},
  meta: {},
  editedDevice: {},
  isVisibleEditDialog: false,
  headersList: [],
};

const mutations = {
  SET_CUSTOMERVEHICLES(state, listCustomerVehicles) {
    state.listCustomerVehicles = listCustomerVehicles;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  ADD_CUSTOMERVEHICLE(state, customerVehicle) {
    state.listCustomerVehicles.push(customerVehicle);
  },
  IS_VISIBLE_EDIT_DIALOG(state, value) {
    state.isVisibleEditDialog = value;
  },
  EDITED_CUSTOMERVEHICLE(state, customerVehicle) {
    state.editedCustomerVehicles = customerVehicle;
  },
  UPDATE_CUSTOMERVEHICLE(state, customerVehicle) {
    const item = state.listCustomerVehicles.find(
      (item) => item.id === customerVehicle.id
    );
    Object.assign(item, customerVehicle);
  },
  DELETE_CUSTOMERVEHICLE(state, customerVehicle) {
    state.listCustomerVehicles.splice(
      state.listCustomerVehicles.map((o) => o.id).indexOf(customerVehicle.id),
      1
    );
  },

  SET_HEADERS(state, headers) {
    state.headersList = headers;
  },
  SET_LOADING(state, value) {
    state.isLoadingCustomerVehicles = value;
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingCustomerVehicles = true;
    service.list(params).then((data) => {
      store.commit("SET_CUSTOMERVEHICLES", data.body.data);
      store.commit("SET_META", data.body);
      store.state.isLoadingCustomerVehicles = false;
    });
  },

  add: (store, customerVehicle) => {
    return service.add(customerVehicle).then((data) => {
      store.commit("ADD_CUSTOMERVEHICLE", data.body);
    });
  },

  update: (store, customerVehicle) => {
    return service.update(customerVehicle).then((customerVehicle) => {
      store.commit("UPDATE_CUSTOMERVEHICLE", customerVehicle.body);
    });
  },

  destroy: (store, customerVehicle) => {
    return service.destroy(customerVehicle).then(() => {
      store.commit("DELETE_CUSTOMERVEHICLE", customerVehicle);
    });
  },

  headersList: (store, params) => {
    return service.getHeadersList(params).then((data) => {
      store.commit("SET_HEADERS", data.body.headers);
    });
  },
  downloadReport: (store, params) => {
    return service.downloadTelematicReports(params);
  },
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING", value);
  }
};

const getters = {
  getCustomerVehiclesList(state) {
    return state.listCustomerVehicles;
  },
  getCustomerVehiclesMeta(state) {
    return state.meta;
  },
  getIsLoadingCustomerVehicles(state) {
    return state.isLoadingCustomerVehicles;
  },

  getHeadersList(state) {
    return state.headersList;
  },
};

const customerVehicles = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default customerVehicles;
