<template>
  <div id="map" />
</template>

<script>
import Vue from "vue";
import L from "leaflet";
import VueMqtt from "vue-mqtt";
import "leaflet.fullscreen"; // Import the fullscreen plugin
import "leaflet.fullscreen/Control.FullScreen.css";
import "leaflet.markercluster"; // Import the marker cluster plugin
import "leaflet.markercluster/dist/MarkerCluster.Default.css"; // Import cluster styles
import "leaflet.markercluster/dist/MarkerCluster.css";

// For Dynamic Functions
import { LeafLetMixins } from "./LeafLetMixins.js";

Vue.use(VueMqtt, "wss://mqtt.flespi.io:443/", {
  clientId: "WebClient-" + parseInt(Math.random() * 100000),
  username: "H0i9Oy5cefXRGGdfNLZLpBt3s7h2h1aPRgOuDIuHLhniLonnXbWaCf3SbaJiZJVE",
});

//
// import { vehicles } from "./data.js";
import moment from "moment";

export default {
  name: "VehicleLiveTracking",
  mixins: [LeafLetMixins],
  props: {
    vehicles: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      // vehicles,
      map: null,
      markerClusterGroup: null,
      markerRef: [],
    };
  },
  computed: {
    allMarkers() {
      return this.vehicles.map((r) => [r.lt, r.ln]);
    },
    topics() {
      return this.vehicles.map(
        (r) => `flespi/message/gw/devices/${r.device_id}`
      );
    },
  },
  created() {
    this.subscribeToTopics();
    // this.publish();
  },
  beforeDestroy() {
    // Optionally, you can unsubscribe from topics if needed
    // this.$mqtt.unsubscribe(this.topic);
    this.unsubscribeFromTopics();
  },
  methods: {
    initializeMap() {
      this.map = L.map("map", {
        fullscreenControl: true, // Add fullscreen control during map initialization
        fullscreenControlOptions: {
          position: "bottomright",
        },
      }).setView(this.center, 18);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
      }).addTo(this.map);

      this.markerClusterGroup = L.markerClusterGroup();
      this.map.addLayer(this.markerClusterGroup);
      if (this.vehicles.length > 0) {
        this.fetchNextSnappedPoint(0); // Start snapping points progressively
        this.mapSetBound(); // Adjust map bounds
      }
    },
    async fetchNextSnappedPoint(index) {
      if (index < this.allMarkers.length) {
        const snappedPoint = await this.snapPointToRoad(this.allMarkers[index]);
        // const snappedPoint = this.allMarkers[index];
        this.vehicles[index].snappedPoint = snappedPoint;

        this.addMarkers(index); // Once all points are snapped, add markers

        this.fetchNextSnappedPoint(index + 1); // Continue to the next point
      } else {
        // this.mapSetBound(); // Adjust map bounds
      }
    },
    addMarkers(index) {
      const v = this.vehicles[index];
      const balloonIcon = L.divIcon({
        className: "",
        html: `
          <div class="pin-icon-container">
          <div class="pin-title" title="${v.dn}"> ${v.dn} </div>
            <div class="pin-circle  ${v.st}">
            ${this.getMarkerIcon(v.bt)}
              </div>
            <div class="pin-arrow"></div>
          </div>
        `,
        iconAnchor: [20, 60],
      });
      if (v.snappedPoint) {
        this.markerRef[index] = L.marker(v.snappedPoint, { icon: balloonIcon });
        this.markerRef[index].bindPopup(this.popupDetail(v));

        this.markerClusterGroup.addLayer(this.markerRef[index]); // Add marker to the cluster group
      }
    },

    //
    getMarkerIcon(i) {
      if (i == "bike") return this.bike;
      else if (i == "loader") return this.loader;
      else if (i == "sedan") return this.sedan;
      else if (i == "hatchback") return this.hatchback;
      else if (i == "bus") return this.bus;
      else if (i == "truck") return this.truck;
      else return `🚗`;
    },

    mapSetBound() {
      const bounds = L.latLngBounds(this.allMarkers);
      // this.vehicles
      //   .filter((v) => v.snappedPoint) // Filter out vehicles without snapped points
      //   .map((v) => v.snappedPoint)
      this.map.fitBounds(bounds);
    },
    popupDetail(row) {
      return `<div> 
          <div>Latitude: ${row.lt} </div>
          <div>Longitude: ${row.ln} </div>
          <div>Customer Name: ${row.dn} </div>
          <div>Speed (KM/Hr): ${row.spd} </div>
       ${row.vt == "ev" ? "<div> SOC:" + row.soc + "</div>" : ""}
        
          <div>Ignition: ${row.ign_s} </div>
          <div>Status: ${row.st} </div>
          <div>Date Time: ${row.sync_at} </div>
        </div>`;
    },
    //
    animateMarker(v) {
      // Find the index of the vehicle to animate
      const index = this.vehicles.findIndex(
        (r) => r.iot_device_imei == v.ident
      );

      // If the vehicle is not found, return early
      if (index === -1) return;

      // Get the current and target positions
      const from = this.vehicles[index].snappedPoint;
      const to = v.snappedPoint;

      // Ensure the vehicle data has valid coordinates
      if (!from || !to) return;

      // const ident = v.ident;
      // const row = this.vehicles.find((r) => r.iot_device_imei == ident);
      // const from = row.snappedPoint;
      // const to = v.snappedPoint;
      // Store the start time for the animation
      const start = performance.now();

      // Animate function to smoothly move the marker
      const step = (timestamp) => {
        const progress = Math.min((timestamp - start) / 1000, 1); // Calculate progress
        const lat = from[0] + (to[0] - from[0]) * progress; // Interpolate latitude
        const lng = from[1] + (to[1] - from[1]) * progress; // Interpolate longitude

        // Update the marker position
        if (this.markerRef[index]) {
          this.markerRef[index].setLatLng([lat, lng]);
        }

        // Continue animation if progress is not complete
        if (progress < 1) {
          requestAnimationFrame(step);
        } else {
          // Once the animation is complete, update the position of the vehicle data
          this.vehicles[index].snappedPoint = [lat, lng];
        }
      };

      // Start the animation
      requestAnimationFrame(step);
    },
    //

    subscribeToTopics() {
      this.topics.forEach((topic) => {
        this.$mqtt.subscribe(topic);
      });
    },
    unsubscribeFromTopics() {
      this.topics.forEach((topic) => {
        this.$mqtt.unsubscribe(topic);
      });
    },
  },
  mqtt: {
    // Subscribe to messages on VueMqtt/publish1
    // "VueMqtt/publish1"(data, topic) {
    //   this.receivedMessage = new TextDecoder().decode(new Uint8Array(data));
    // },
    // // Subscribe to messages on the defined topic
    // "flespi/message/gw/devices/5726057"(data) {
    //   this.receivedMessage = new TextDecoder().decode(new Uint8Array(data));
    // },
    // Handle messages from any topic
    async "flespi/message/gw/devices/#"(data, topic) {
      // Wildcard to capture all topics
      const msg = JSON.parse(new TextDecoder().decode(new Uint8Array(data)));
      const {
        ident,
        customer_name,
        position_latitude,
        position_longitude,
        position_speed,
        server_timestamp,
      } = msg;
      const params = {
        ident: ident,
        topic: topic,
        customer_name,
        lat: position_latitude,
        lng: position_longitude,
        speed: position_speed,
        // time: server_timestamp,
      };
      params.snappedPoint = await this.snapPointToRoad([
        params.lat,
        params.lng,
      ]);
      // params.snappedPoint = [params.lat, params.lng];
      // format time
      const d = parseFloat(server_timestamp);
      const dd = moment(d * 1000);
      const formattedDate = dd.format("YYYY-MM-DD HH:mm:ss"); //2024-09-13 20:21:30
      params.time = formattedDate;
      this.animateMarker(params);
    },
  },
};
</script>

<style scoped>
#map {
  height: 100vh;
  width: 100%;
}

:deep .pin-icon-container {
  position: relative;
  width: 40px;
  height: 60px;
}
:deep .pin-title {
  position: absolute;
  top: -25px;
  left: -10px;
  background: #fff;
  border-radius: 2px;
  width: 65px;
  font-size: x-small;
  font-weight: 800;
  padding: 2px;

  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  /* justify-content: center; */
}

:deep .pin-circle {
  padding: 5px;
  width: 40px;
  height: 40px;
  /* background-color: #20a390; */
  background-color: #ffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
:deep .pin-arrow {
  position: absolute;
  top: 40px;
  left: 15px;
  width: 10px;
  height: 20px;
  background-color: #ffffff;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

:deep .active svg path {
  fill: #23bdaa !important;
}

:deep .in_active svg path,
:deep .inactive svg path {
  fill: #ffae20 !important;
}

:deep .offline svg path {
  fill: #fa896b;
}
</style>
