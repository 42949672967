import service from "../services/iot-device-models-service";
const state = {
  isLoadingIotDeviceModels: false,
  listIotDeviceModels: [],
  iotDeviceModel: {},
  listModelByType: [],
  meta: {},
  editedDevice: {},
  isVisibleEditDialog: false,
};

const mutations = {
  SET_LOADING_LIST(state, value) {
    state.isLoadingIotDeviceModels = value;
  },
  SET_IOTDEVICEMODELS(state, listIotDeviceModels) {
    state.listIotDeviceModels = listIotDeviceModels;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  ADD_IOTDEVICEMODEL(state, iotDeviceModel) {
    state.listIotDeviceModels.push(iotDeviceModel);
  },
  IS_VISIBLE_EDIT_DIALOG(state, value) {
    state.isVisibleEditDialog = value;
  },
  EDITED_IOTDEVICEMODEL(state, iotDeviceModel) {
    state.editedIotDeviceModels = iotDeviceModel;
  },
  UPDATE_IOTDEVICEMODEL(state, iotDeviceModel) {
    const item = state.listIotDeviceModels.find((item) => item.id === iotDeviceModel.id);
    Object.assign(item, iotDeviceModel);
  },
  SET_MODELS_BY_TYPE(state, type) {
    state.listModelByType = state.listIotDeviceModels.filter((iotDeviceModel) => iotDeviceModel.iot_device_make_id == type);
  },
  DELETE_IOTDEVICEMODEL(state, iotDeviceModel) {
    state.listIotDeviceModels.splice(
      state.listIotDeviceModels.map((o) => o.id).indexOf(iotDeviceModel.id),
      1
    );
  },
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
  list: (store, params) => {
    store.state.isLoadingIotDeviceModels = true;
    service.list(params).then((data) => {
      store.commit("SET_IOTDEVICEMODELS", data.body.data);
      store.commit("SET_META", data.body);
      store.state.isLoadingIotDeviceModels = false;
    });
  },

  add: (store, iotDeviceModel) => {
    return service.add(iotDeviceModel).then((data) => {
      store.commit("ADD_IOTDEVICEMODEL", data.body);
      store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  update: (store, iotDeviceModel) => {
    return service.update(iotDeviceModel).then((iotDeviceModel) => {
      store.commit("UPDATE_IOTDEVICEMODEL", iotDeviceModel.body);
    });
  },

  destroy: (store, iotDeviceModel) => {
    return service.destroy(iotDeviceModel).then(() => {
      store.commit("DELETE_IOTDEVICEMODEL", iotDeviceModel);
    });
  },
  getModelsByType: (store, type) => {
    store.commit("SET_MODELS_BY_TYPE", type);
  },
};

const getters = {
  getIotDeviceModelsList(state) {
    return state.listIotDeviceModels;
  },
  getIotDeviceModelsMeta(state) {
    return state.meta;
  },
  listModelsByType(state) {
    return state.listModelByType;
  },
  getIsLoadingIotDeviceModels(state) {
    return state.isLoadingIotDeviceModels;
  }
};

const iotDeviceModels = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default iotDeviceModels;
