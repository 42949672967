<template>
  <div>
    <section>
      <header-filters
        class-name="dashboard"
        :filter-names="['calendar_type']"
        :product-options="productOptions"
        @onChange="onFiltersChange"
      />
    </section>
    <section>
      <div class="d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <div>
            <v-btn
              text
              icon
              small
              class="ma-2"
              @click="$refs.calendar.prev()"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div>
          <div>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
          </div>
        </div>
        <div>
          <v-btn
            text
            icon
            small
            class="ma-2"
            @click="$refs.calendar.next()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
    </section>
    <section>
      <v-sheet class="bookingCalendar">
        <v-calendar
          v-if="options.calendar_type"
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="options.calendar_type"
          :events="events"
          :event-overlap-mode="options.mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          @click:date="viewDay"
          @click:event="showEvent"
          @change="fetchData"
        />
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            class="mb-0 vcardCustom"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <!-- For Edit -->
              <!-- <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn> -->
              <v-toolbar-title>
                <div class="d-flex align-center justify-space-between">
                  <div class="name">
                    {{ selectedEvent?.name }}
                  </div>
                  <div class="number">
                    {{ selectedEvent?.phone_number }}
                  </div>
                </div>
                <div class="email">
                  {{ selectedEvent?.email }}
                </div>
              </v-toolbar-title>
              <!-- <v-spacer /> -->
              <!-- <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn> -->
            </v-toolbar>
            <v-card-text>
              <div>
                <label>{{ $t("location_address") }}</label>
                <span>{{ selectedEvent?.location_address }}</span>
              </div>
              <div>
                <label>{{ $t("status") }}</label>
                <span>{{ selectedEvent?.status }}</span>
              </div>
              <span class="d-flex justify-space-between">
                <div>
                  <label>{{ $t("connector_type") }}</label>
                  <span>{{ selectedEvent?.connector?.connector_type }}</span>
                </div>
                <div>
                  <label>{{ $t("connector_format") }}</label>
                  <span>{{ selectedEvent?.connector?.connector_format }}</span>
                </div>
              </span>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="red darken-1"
                text
                @click="selectedOpen = false"
              >
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </section>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";
export default {
  components: {
    HeaderFilters: () => import("@/layouts/section/MainFilters.vue"),
  },
  data() {
    return {
      options: {
        type: "week",
        mode: "stack",
      },
      productOptions: [
        { id: "oem", value: "OEM" },
        { id: "cso", value: "CSO" },
      ],
      types: ["week", "day"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      // weekdays: [
      //   { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      //   { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      //   { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      //   { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      // ],
      value: "",
      events: [],
      //   colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange"],

      // show event
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsBookings/getLoading",
      list: "csmsBookings/getList",
    }),
  },
  //   watch: {
  //     "options.calendar_type": {
  //       handler: function () {
  //       },
  //     },
  //   },
  beforeDestroy() {
    const params = {
      name: "bookings",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    onFiltersChange(v) {
      this.options = v;
      this.fetchData();
    },
    fetchData: debounce(async function (val) {
      await this.$store.dispatch("csmsBookings/list", this.options);
      await this.getEvents(val);
      ("");
    }, 100),
    // async fetchData(val) {
    //   await this.$store.dispatch("csmsBookings/list", this.options);

    //   //   set Calendar data
    //   await this.getEvents(val);
    // },

    // getEvents({ start, end }) {
    getEvents() {
      const events = [];
      this.list.forEach((el) => {
        const params = {
          name: el?.oem_customer?.name,
          phone_number: el?.oem_customer?.phone_number,
          email: el?.oem_customer?.email,
          start: new Date(`${el.booking_date} ${el.start_time}`),
          end: new Date(`${el.booking_date} ${el.end_time}`),
          status: el?.booking_status,
          location_address: el?.location?.address,
          //   this.colors[this.rnd(0, this.colors.length - 1)]
          color: el.booking_status == "approved" ? "green" : "grey darken-1",
          timed: true,
          connector: el?.charger_connector,
        };

        events.push(params);
      });

      this.events = events;
    },

    //
    viewDay({ date }) {
      this.value = date;
      this.options.calendar_type = "day";
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    //
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .v-present
    .v-calendar-daily_head-day-label
        .v-btn
            background-color  : #20a390 !important

:deep .v-past, :deep .v-future
    .v-calendar-daily_head-day-label
        .v-btn
            color : gray !important


:deep .v-toolbar
    width: 100% !important
    .v-toolbar__content, .v-toolbar__title
        width: inherit !important

    .v-toolbar__title
        .number
            font-size: 16px !important
        .email
            font-size: 14px !important
/*
:deep  .v-card__text
    div
        display: flex
        flex-direction: column
        margin-top: 0.5rem
        label
            font-size: 13px !important
            font-weight: 600 !important
        span
            font-size: 16px !important
            */

.bookingCalendar
  height: calc(100vh - 175px)
</style>